import React, { useEffect } from "react";

import { Banner } from "../components/banner/Home3_banner";

import { useState } from "react";
import Slider from "react-slick";

import AdminHeader from "../components/layout/Admin/AdminHeader";
import AdminFooter from "../components/layout/Admin/AdminFooter";
import { mainURL, subURL } from "../utils/URL's";
import axios from "axios";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useHistory } from "react-router-dom";
import country1 from "../newimages/country-01.jpg";
import country2 from "../newimages/country-03.jpg";
import country3 from "../newimages/country-04.jpg";

const slick_slider = {
  dots: false,
  arrow: false,
  autoplay: true,
  infinite: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  rows: 1,
  responsive: [
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function Home3() {
  const [fetchJobs, setFetchJobs] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);

  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [showViewMoreBtn, setShowViewMore] = useState(false);

  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sendSelectedCountry, setSendSelectedCountry] = useState(null);

  const [companyNameOrJobTitle, setCompanyNameOrJobTitle] = useState("");

  const [jobType, setJobType] = useState("");
  const [jobRole, setJobRole] = useState([]);

  useEffect(() => {
    axios
      .get(`${subURL}/post_job/`)
      .then((response) => {
        setFetchJobs(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    const fetchJobRole = async () => {
      const response = await axios.get(`${subURL}/getjobtitle/`);

      setJobRole(response.data.slice(0, 9));
    };

    fetchJobRole();
  }, []);

  useEffect(() => {
    const fetchJobCategories = async () => {
      const response = await axios.get(`${subURL}/jobcategory/`);

      setJobCategories(response.data.slice(0, 8));
    };

    fetchJobCategories();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v2/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const options = data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
        }));

        setCountries(options);
      } catch (error) {
        console.error("Error fetching countries:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
    fetchCountries();
  }, []);

  return (
    <div className="site-main">
      <AdminHeader />

      <Banner />

      <div className="form-section clearfix">
        <div className="container">
          <div className="row"></div>
        </div>
      </div>

      <section className="ttm-row features-section clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              {/* section title */}
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    Get{" "}
                    <span className="text-theme-SkinColor">Appropriate</span>
                  </h3>
                  <h2 className="title">Search Job By Industry</h2>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* row */}
          <div className="row row-equal-height mb_10">
            {jobCategories.map((category) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                {/* featured-icon-box */}
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    {/* finance-hover */}
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage:
                            "url(https://themetechmount.com/react/hireco/images/services/finance-hover.png)",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                    {/* finance-hover end */}
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <img
                        className="img-fluid"
                        src="images/cat-icon1.png"
                        alt="category"
                      />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>{category.job_category}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        85 Jobs<br></br>Financial Branch Manager
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={`/filtercategorywise/${category.job_category}`}
                    >
                      View Jobs!
                    </a>
                  </div>
                </div>
                {/* featured-icon-box end */}
              </div>
            ))}
          </div>
          {/* row end */}
        </div>
      </section>

      {/* services-section */}
      <section
        className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
        style={{ backgroundImage: "url(images/bg-image/row-bgimage-1.png)" }}
      >
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-11">
              {/* section title */}
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h3>
                    We Serve{" "}
                    <span className="text-theme-SkinColor">Overseas</span>
                  </h3>
                  <h2 className="title">We Are Worldwide</h2>
                </div>
                <div className="title-desc">
                  <p>
                    A staffing agency recruits{" "}
                    <span className="text-theme-SkinColor">new employees </span>
                    for the client employers to fulfill their companies' needs.
                    We've head quarters worldwide
                  </p>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* Slider */}
          <Slider
            className="row slick_slider slick-arrows-style1"
            {...slick_slider}
            slidesToShow={3}
            arrows={true}
            responsive={[
              {
                breakpoint: 1024,
                settings: { slidesToShow: 2, slidesToScroll: 2 },
              },
              {
                breakpoint: 650,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
              },
            ]}
          >
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img src={country1} />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>United States</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      A millions of decision about who has a right to settle
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={country2} alt="image" />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Australia</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      Our head quarters is in Canberra, help throughout process
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={country3} alt="image" />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Canada</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>We will help in every step of the application process</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={country1} alt="image" />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>United Kingdom</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We will help you with every step process of recruitment{" "}
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={country2} alt="image" />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>United States</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      A millions of decision about who has a right to settle
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-12">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-country">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" src={country3} alt="image" />
                </div>
                {/* featured-thumbnail end */}
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Canada</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>
                      We will help in every step of the application process.
                    </p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
          </Slider>
          {/* Slider end */}
        </div>
      </section>
      {/* services-section end */}

      {/* portfolio-section */}
      <section className="ttm-row portfolio-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    Find us <span className="text-theme-SkinColor">here!</span>
                  </h3>
                  <h2 className="title">Our Head Quarters</h2>
                </div>
              </div>
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="	https://themetechmount.com/react/hireco/images/portfolio/01.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Gujarat</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/portfolio/02.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Delhi</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/portfolio/03.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Maharshtra</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="	https://themetechmount.com/react/hireco/images/portfolio/01.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Rajasthan</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/portfolio/02.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Karnataka</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay ttm-portfolio-box-view-overlay">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/portfolio/03.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                </div>
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href={process.env.PUBLIC_URL + "/"}>Jammu Kashmir</a>
                    </h3>
                  </div>
                  <div className="featured-desc">
                    <p>10 Open Positions</p>
                  </div>
                </div>
              </div>
              {/* featured-imagebox-portfolio end */}
            </div>
            <div className="col-lg-12">
              <div className="pt-30 m-auto text-center">
                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                  href="/viewpostedjobs"
                >
                  More Jobs By Region
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* portfolio-section end */}

      {/* about-section */}
      <section
        className="ttm-row about-section bg-img5 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix"
        style={{
          backgroundImage:
            'url("	https://themetechmount.com/react/hireco/images/bg-image/row-bgimage-5.jpg")',
        }}
      >
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title">
                <div className="title-header">
                  <h2 className="title">Download & Enjoy</h2>
                </div>
                <div className="title-desc">
                  <p>
                    Search, find and apply for jobs directly on your mobile
                    device or desktop Manage all of the jobs you have applied to
                    from a convenience secure dashboard.
                  </p>
                </div>
              </div>
              {/* section title end */}
              <div className="d-sm-flex social-btns">
                <a className="app-btn" href="http:apple.com">
                  <i className="fab fa-apple"></i>
                  <p>
                    Available on the <br />{" "}
                    <span className="big-txt">App Store</span>
                  </p>
                </a>

                <a className="app-btn" href="http:google.com">
                  <i className="fab fa-google-play"></i>
                  <p>
                    Get it on <br />{" "}
                    <span className="big-txt">Google Play</span>
                  </p>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 col-10 mx-auto">
              <div className="ttm_single_image-wrapper mt_20 p-15 position-absolute mb_lg-200 d-lg-block d-none">
                <img
                  className="img-fluid"
                  src="https://themetechmount.com/react/hireco/images/single-img-04.png"
                  alt="single_04"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* about-section end */}

      {/* job-list-section */}
      <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg z-index-1 clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-11">
              {/* section title */}
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h3>
                    Find The{" "}
                    <span className="text-theme-SkinColor">Best One!</span>
                  </h3>
                  <h2 className="title">Jobs By Positions</h2>
                </div>
                <div className="title-desc">
                  <p>
                    We have over 30 years experience of Financial ,Marketing and
                    Busness Solutions expertise, our goal is to maxnci
                    reputation and financial.
                  </p>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* row */}
          <Slider
            className="row slick_slider slick-arrows-style2 pt-20 mb_10"
            {...slick_slider}
         //   rows={2}
            slidesToShow={3}
            arrows={true}
          >
            {jobRole.map((role) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a
                  href={`/filterrolewise/${role.job_title}`}
                  class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
                >
                  <div class="featured-icon">
                    <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i class="flaticon flaticon-sketch"></i>
                    </div>
                  </div>
                  <div class="featured-content">
                    <div class="featured-title">
                      <h3>{role.job_title}</h3>
                    </div>
                    <div class="featured-desc">
                      <p>25 Open Positions</p>
                    </div>
                    <style>{``}</style>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
          {/* row end */}
        </div>
        <style>
          {`.featured-desc{
              color:gray
            }
            `}
        </style>
      </section>
      {/* job-list end */}

      {/* services-section */}
      <section className="ttm-row services-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    Welcome <span className="text-theme-SkinColor">Admin!</span>
                  </h3>
                  <h2 className="title"> Dashboard</h2>
                </div>
              </div>
            </div>
          </div>
          {/* row */}
          <Slider
            className="row slick_slider slick-arrows-style1"
            {...slick_slider}
            slidesToShow={3}
            arrows={true}
          >
            <div className="col-md-4 col-sm-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style2">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="/admin">Statistics</a>
                    </h3>
                  </div>
                </div>
                <div className="ttm-box-view-overlay border-rad_6">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="	https://themetechmount.com/react/hireco/images/services/services-02-1200x800.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-hover-content">
                    <div className="featured-desc">
                      <p>Get an overview of key statistics and analytics.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-4 col-sm-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style2">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="/admin">Messages</a>
                    </h3>
                  </div>
                </div>
                <div className="ttm-box-view-overlay border-rad_6">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/services/services-04-1200x800.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-hover-content">
                    <div className="featured-desc">
                      <p>Manage and respond to user messages and inquiries.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-4 col-sm-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style2">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="/admin">Settings</a>
                    </h3>
                  </div>
                </div>
                <div className="ttm-box-view-overlay border-rad_6">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/services/services-01-1200x800.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-hover-content">
                    <div className="featured-desc">
                      <p>
                        Customize and configure platform settings and
                        preferences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
            <div className="col-md-4 col-sm-6">
              {/* featured-imagebox */}
              <div className="featured-imagebox featured-imagebox-services style2">
                <div className="featured-content">
                  <div className="featured-title">
                    <h3>
                      <a href="/admin">Support & Help</a>
                    </h3>
                  </div>
                </div>
                <div className="ttm-box-view-overlay border-rad_6">
                  {/* featured-thumbnail */}
                  <div className="featured-thumbnail">
                    <img
                      className="img-fluid"
                      src="https://themetechmount.com/react/hireco/images/services/services-01-1200x800.jpg"
                      alt="image"
                    />
                  </div>
                  {/* featured-thumbnail end */}
                  <div className="featured-hover-content">
                    <div className="featured-desc">
                      <p>
                        Access support resources and get help with the platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* featured-imagebox end */}
            </div>
          </Slider>
        </div>
      </section>
      {/* services-section end */}

      {/* job-list-section */}
      <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-11">
              {/* section title */}
              <div className="section-title style2">
                <div className="title-header">
                  <h3>
                    Find The{" "}
                    <span className="text-theme-SkinColor">Best One!</span>
                  </h3>
                  <h2 className="title">Recent Job Listing</h2>
                </div>
                <div className="title-desc">
                  <p>
                    We have several jobs in active right now. Check out{" "}
                    <span className="text-theme-SkinColor">recent jobs </span>
                    according to your excelllence. Our experts will guide you
                    accordingly.{" "}
                  </p>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* Slider */}
          <Slider
            className="row slick_slider mb_10 pt-20 slick-arrows-style2"
            {...slick_slider}
            slidesToShow={2}
            rows={2}
            arrows={true}
            responsive={[
              {
                breakpoint: 992,
                settings: { slidesToShow: 1, slidesToScroll: 1 },
              },
            ]}
          >
            {fetchJobs.map((job) => (
              <div className="col-lg-6 col-md-12">
                {/* featured-imagebox */}
                <div className="featured-imagebox featured-imagebox-job">
                  <div className="featured-thumbnail">
                    <img
                      src={
                        !showViewMoreBtn
                          ? `${job?.company_id?.company_image}`
                          : `${mainURL}${job?.company_id?.company_image}`
                      }
                      alt="img"
                    />
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href={`/viewjob/${job.id}`}>{job.job_title}</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>Posted {job?.createdDate?.substring(0, 10)}</p>
                    </div>
                    <div className="featured-bottom">
                      <div className="job-meta">
                        <span>
                          <i className="fa fa-map-marker-alt"></i>
                          {job.job_location_id.country}
                        </span>
                      </div>
                      <div className="job-time">
                        <span
                          className={`${
                            job?.job_type_id?.job_type === "full time"
                              ? "light green"
                              : job?.job_type_id?.job_type === "part time"
                              ? "orange"
                              : "pink"
                          }`}
                        >
                          {job.job_type_id.job_type.charAt(0).toUpperCase() +
                            job.job_type_id.job_type.slice(1)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* featured-imagebox end */}
              </div>
            ))}
          </Slider>
          {/* row end */}
        </div>
      </section>
      {/* job-list end */}

      {/* form-section */}
      {/* <section className="ttm-row form-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    fill the{" "}
                    <span className="text-theme-SkinColor"> details</span>
                  </h3>
                  <h2 className="title">Apply For Your Dream Jobs</h2>
                </div>
              </div>
            </div>
          </div>
         
          <div className="row">
            <div className="col-lg-12">
              <form className="submit_Form wrap-form row row-equal-height">
                <div className="col-lg-6">
                  <div className="basic-details p-30 mt-15 box-shadow">
                    <div className="mb-20 text-center">
                      <h4>Personal Details</h4>
                      <p>Please Fill Out Your Contact Person Details Here.</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label>
                          <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <input
                            type="email"
                            name="email_id"
                            placeholder="Email"
                          />
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <input
                            type="tel"
                            name="phone_number"
                            placeholder="Phone"
                          />
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <label>
                          <input
                            type="text"
                            name="address"
                            placeholder="Address"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="additional-details p-30 mt-15 box-shadow">
                    <div className="mb-20 text-center">
                      <h4>Qualifications</h4>
                      <p>Please Fill Out Your Contact Person Details Here.</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label>
                          <select
                            name="education"
                            aria-hidden="true"
                            placeholder="Education"
                          >
                            <option value="">Education</option>
                            <option value="Ed1">Education1</option>
                            <option value="Ed2">Education2</option>
                            <option value="Ed3">Education3</option>
                            <option value="Ed4">Education4</option>
                            <option value="Ed5">Education5</option>
                            <option value="Ed6">Education6</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <select
                            name="locations"
                            aria-hidden="true"
                            placeholder="Locations"
                          >
                            <option value="">Locations</option>
                            <option value="Loc1">Locations1</option>
                            <option value="Loc2">Locations2</option>
                            <option value="Loc3">Locations3</option>
                            <option value="Loc4">Locations4</option>
                            <option value="Loc5">Locations5</option>
                            <option value="Loc6">Locations6</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <select
                            name="experiance"
                            aria-hidden="true"
                            placeholder="Total Experiance"
                          >
                            <option value="">Total Experiance</option>
                            <option value="Ex1">Experiance1</option>
                            <option value="Ex2">Experiance2</option>
                            <option value="Ex3">Experiance3</option>
                            <option value="Ex4">Experiance4</option>
                            <option value="Ex5">Experiance5</option>
                            <option value="Ex6">Experiance6</option>
                          </select>
                        </label>
                      </div>
                      <div className="col-lg-6">
                        <label>
                          <input
                            type="text"
                            name="note"
                            placeholder="Personal Qualities"
                          />
                        </label>
                      </div>
                      <div className="col-lg-12">
                        <label>
                          <input
                            type="file"
                            name="chooseFile"
                            id="chooseFile"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>
                    <textarea
                      name="message"
                      rows="4"
                      placeholder="Additional Informayions..."
                      required="required"
                    ></textarea>
                  </label>
                </div>
                <div className="col-lg-12">
                  <label className="mb-0">
                    <button
                      className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                        ttm-btn-color-skincolor h-auto"
                      type="submit"
                    >
                      Apply For This Jobs
                    </button>
                  </label>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section> */}
      {/* form-section end */}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <AdminFooter />
    </div>
  );
}

export default Home3;
