import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

const Menu = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
  }, []);
  
  const isActive = (route) => {
    return window.location.pathname === route;
  };
  return (
    <Router>
      <nav className="main-menu">
        <ul className="menu">
          <li className={`mega-menu-item ${isActive("/") ? "active" : ""}`}>
            <a href="/" className="mega-menu-link">
              Home
            </a>
          </li>

          {isLoggedIn && (
            <li
              className={`mega-menu-item ${
                isActive("/contactus") ? "active" : ""
              }`}
            >
              <a href="/contactus" className="mega-menu-link">
                Contact us
              </a>
            </li>
          )}
          {isLoggedIn && (
            <li
              className={`mega-menu-item ${
                isActive("/job_list") ? "active" : ""
              }`}
            >
              <a href="/job_list" className="mega-menu-link">
                Job List
              </a>
            </li>
          )}

          {!isLoggedIn && (
            <li
              className={`mega-menu-item ${
                isActive("/alljobs") ? "active" : ""
              }`}
            >
              <a href="/alljobs" className="mega-menu-link">
                Job List
              </a>
            </li>
          )}
        </ul>
        <style>
          {`
        .main-menu .mega-menu-item > .mega-menu-link::after {
          display: none;
        }
        
        .main-menu .mega-menu-item > .mega-menu-link + .mega-submenu {
          display: none;
        }
        
        .main-menu .mega-menu-item.with-submenu > .mega-menu-link::after {
          display: inline-block;
          content: "▼";
          content: "\u2304";/* Dropdown arrow Unicode character */
        }
        
        .main-menu .mega-menu-item.with-submenu > .mega-menu-link + .mega-submenu {
          display: block;
        }

        // .main-menu .mega-menu-item.active {
        //   background-color: #f0f0f0;
        //   font-weight: bold;
        // }
        
        .main-menu .mega-menu-item.active a {
          color: orange;
        }
        `}
        </style>
      </nav>
    </Router>
  );
};

export default Menu;
