import React, { Component, useEffect } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

function AdminMobile_menu() {
  const [collapse1, setCollapse1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isAdminLogout, setIsAdminLogout] = useState(false);

  const toggleSingleCollapse = () => {
    setCollapse1(!collapse1);
  };
  const logoutHandler = () => {
    (async () => {
      try {
        await localStorage.removeItem("userid");
        await localStorage.removeItem("isAdmin");
        await localStorage.removeItem("username");
        await localStorage.removeItem("usertypeid");

        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        setIsAdminLogout(true);
        // history.push("/");
      } catch (error) {
        console.log(error);
      }
    })();
  };
  useEffect(() => {
    if (isAdminLogout) {
      window.location.href = "/"; // Redirect to login page
    }
  }, [isAdminLogout]);
  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler id="hamburger1" onClick={toggleSingleCollapse} />
        <MDBCollapse isOpen={collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/admin" nav caret>
                  Home
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Jobs
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href="/postjob">Post Job</MDBDropdownItem>
                  <MDBDropdownItem href="/viewpostedjobs">
                    Job List
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Company
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href="/addcompany">
                    Add Company
                  </MDBDropdownItem>
                  <MDBDropdownItem href="/companydetails">
                    Company Details
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/candidate_list" nav caret>
                  Candidate List
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/adminprofile" nav caret>
                  My Profile
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/addadmin" nav caret>
                  Add Admin
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem onClick={() => setShowModal(true)}>
                  Logout
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            {/* <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Blog
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href="/blog_classic">
                    Blog Classic
                  </MDBDropdownItem>
                  <MDBDropdownItem href="/blog_grid">Blog Grid</MDBDropdownItem>
                  <MDBDropdownItem href="/blog_details">
                    Blog Details
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem> */}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Logout !</ModalHeader>
        <ModalBody>
          <h6>Are you sure you want to logout ?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="grey" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button color="primary" onClick={logoutHandler}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Router>
  );
}

export default AdminMobile_menu;
