import axios from "axios";
import React, { useEffect, useState } from "react";
import { imageURL, mainURL, subURL } from "../utils/URL's";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Footer from "../components/layout/Footer";

import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";

const CompanyDetailsPage = () => {
  const { id } = useParams();

  const [compDetails, setCompDetails] = useState(null);

  useEffect(() => {
    const fetchAppliedStatus = async () => {
      const res = await axios.get(`${subURL}/company_save_details/${id}/`);

      setCompDetails(res.data);
    };

    fetchAppliedStatus();
  }, [id]);

  return (
    <div className="site-main">
      <Header />

      <PageHeader title="Company Details" breadcrumb="job" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget job-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>Company Information
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">Establishment date:</b>{" "}
                      {compDetails?.establishment_date}
                    </li>

                    <li className="">
                      <b className="mr-5">Website:</b>
                      <a
                        href={compDetails?.company_website_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {compDetails?.company_website_url}
                      </a>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5"> Type:</b>
                      {compDetails?.company_type}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5"> Size:</b>
                      {compDetails?.company_size}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5"> HeadQuarters:</b>
                      {compDetails?.headquarters}
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                    <div className="featured-thumbnail">
                      <img
                        src={`${imageURL}${compDetails?.company_image}`}
                        alt="Job1"
                      />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>{compDetails?.company_name}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="overview-box">
                    <div className="title">
                      <h5>Profile Description :</h5>
                    </div>
                    <div className="desc">
                      <p style={{ wordWrap: "break-word", maxWidth: "700px" }}>
                        {compDetails?.profile_description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CompanyDetailsPage;
