import React from "react";

import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import Footer from "../components/layout/Footer";
import { useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { imageURL, mainURL, subURL } from "../utils/URL's";
import { useEffect } from "react";
import axios from "axios";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
var USERID;
function Job_details() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  const { id } = useParams();
  const history = useHistory();
  const [jobDetails, setJobDetails] = useState(null);
  const [compImage, setCompanyImage] = useState("");
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [jobId, setJobId] = useState("");

  const [userId, setUserId] = useState();

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [jobs, setJobs] = useState([]);
  const [fetchSkills, setFetchSkills] = useState([]);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
  }, []);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  useEffect(() => {
    const fetchAppliedStatus = async () => {
      const res = await axios.get(`${subURL}/post_job/${id}/`);

      setJobDetails(res.data);

      setCompanyImage(res.data?.company_id?.company_image);
    };

    fetchAppliedStatus();
  }, [id]);

  useEffect(() => {
    const fetchSkills = async () => {
      const res = await axios.get(`${subURL}/fetchJobSkills/${id}/`);

      setFetchSkills(res.data);
    };

    fetchSkills();
  }, [id]);

  function passJobID(id) {
    setModal(true);

    setJobId(id);
  }
  async function applyforJob() {
    setModal(false);

    const formData = {
      user_account_id: userId,
      job_post_id: jobId,
      status: "pending",
      userstatus: true,
    };

    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.post(`${subURL}/applyjob/`, formData, {
        headers: headers,
      });

      if (res.status === 201) {
        setModalTitle("Success");
        setSuccessMessage("Applied for job Successfully !");
        setShowModal(true);
        async function fetchData() {
          const reqmodel = {
            user_account_id: userId,
          };
          let headers = {
            "Content-Type": "application/json; charset=utf-8",
          };

          try {
            const res = await axios.post(
              `${subURL}/notAplliedJob/True/`,
              reqmodel,
              {
                headers: headers,
              }
            );

            setJobs(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      if (e.response?.status === 404) {
        setModalTitle("Failed !");
        setSuccessMessage("Please add your profile & employement details !");
        setShowModal(true);
        setTimeout(() => {
          history.push("/myprofile");
        }, 2000);
      } else if (e.response?.status === 422) {
        setModalTitle("Failed !");
        setSuccessMessage("Please add your profile details !");
        setShowModal(true);
        setTimeout(() => {
          history.push("/myprofile");
        }, 2000);
        
      } else if (e.response?.status === 500) {
        setModalTitle("Success !");
        setSuccessMessage("Applied for job Successfully  !");
        setShowModal(true);
        setTimeout(() => {
          history.push("/myprofile");
        }, 2000);
      }
        else {
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
      }
    }
  }
  const navigateHandler = () => {
    history.push(`/myprofile`);
  };

  function shareOnFacebook(jobTitle, jobUrl) {
    const encodedTitle = encodeURIComponent(jobTitle);
    const encodedUrl = encodeURIComponent(jobUrl);

    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}&quote=${encodedTitle}`;

    window.open(url, "_blank");
  }

  function shareOnTwitter(jobTitle, jobUrl) {
    const encodedTitle = encodeURIComponent(jobTitle);
    const encodedUrl = encodeURIComponent(jobUrl);

    const url = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;

    window.open(url, "_blank");
  }

  function emailAFriend(jobTitle, jobUrl) {
    const encodedTitle = encodeURIComponent(jobTitle);
    const encodedUrl = encodeURIComponent(jobUrl);

    const mailtoLink = `mailto:?subject=${encodedTitle}&body=Check out this job: ${encodedUrl}`;

    window.location.href = mailtoLink;
  }
  return (
    <div className="site-main">
      <Header />

      <PageHeader title="job details" breadcrumb="job" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget job-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>Job Informations
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">Job Type:</b>{" "}
                      {jobDetails?.job_type_id?.job_type
                        .charAt(0)
                        .toUpperCase() +
                        jobDetails?.job_type_id?.job_type.slice(1)}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Location:</b>{" "}
                      {jobDetails?.job_location_id?.country}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Offered Salary:</b>Rs.
                      {jobDetails?.salary}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Posted:</b>{" "}
                      {jobDetails?.createdDate?.substring(0, 10)}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Experience:</b>{" "}
                      {jobDetails?.experince_type_id?.experince_type}
                    </li>
                  </ul>
                </aside>
                <aside className="widget form-widget " >
                  {isLoggedIn ? (
                    <button
                      className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100 mt-3"
                      type="submit"
                      onClick={() => passJobID(jobDetails.id)}
                    >
                      Apply Now
                    </button>
                  ) : (
                    <div className="header_btn " >
                      <a
                        href="#"
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill
                                        ttm-icon-btn-left ttm-btn-color-skincolor  mt-3"
                    >
                        <i className="far fa-user fa-sm"></i>
                        <a className="alert-heading " href="/register">
                          Sign up{" "}
                        </a>
                        <span className="ml-10 mr-10">/</span>
                        <i className="ti ti-lock fa-sm"></i>
                        <a className="alert-heading" href="/login">
                          Login to apply{" "}
                        </a>
                      </a>
                    </div>
                  )}

                  <div
                    className="modal fade"
                    id="applyNow"
                    tabIndex="-1"
                    aria-labelledby="applyNow"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <Modal isOpen={modal} toggle={openModal} centered>
                        <ModalBody className="modal-body p-25">
                          <div className="text-center mb-4">
                            <h5
                              className="modal-title"
                              id="staticBackdropLabel"
                            >
                              Do you want to update your profile ?
                            </h5>
                          </div>
                          <div className="position-absolute end-0 top-0 p-3">
                            <button
                              type="button"
                              onClick={openModal}
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>

                          <div className="d-flex justify-content-between ml-3 ">
                            <button
                              onClick={applyforJob}
                              className="btn btn-danger w-50"
                            >
                              No, Apply for job
                            </button>
                            <div className="mx-2"></div>{" "}
                            <button
                              onClick={() => navigateHandler(jobDetails.id)}
                              className="btn btn-primary w-50"
                            >
                              Yes, Update
                            </button>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                    <div className="featured-thumbnail">
                      <img src={`${imageURL}${compImage}`} alt="Job1" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>{jobDetails?.job_title}</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Posted on :{" "}
                          {jobDetails?.createdDate?.substring(0, 10)}
                        </p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          {jobDetails?.job_location_id?.country && (
                            <span>
                              <i className="fa fa-map-marker-alt"></i>{" "}
                              {jobDetails?.job_location_id?.country}
                            </span>
                          )}
                          <span>
                            Company Name: {jobDetails?.company_id?.company_name}
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="green">
                            {" "}
                            {jobDetails?.job_type_id?.job_type}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="overview-box">
                    <div className="title">
                      <h5>Job Description :</h5>
                    </div>
                    <div className="desc">
                      <p style={{ wordWrap: "break-word", maxWidth: "700px" }}>
                        {jobDetails?.job_description}
                      </p>
                    </div>
                  </div>

                  <div className="overview-box">
                    <div className="title">
                      <h5>Skills Required</h5>
                    </div>

                    {fetchSkills.length > 0 ? (
                      fetchSkills.map((skill) => (
                        <div className="" style={{ margin: "1em" }}>
                          <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                            <li>
                              <i className="ti ti-check-box"></i>
                              <div className="ttm-list-li-content">
                                {skill.skill_set_id.skill_set_name}
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))
                    ) : (
                      <p className="text-center fs-16 mt-3 mb-3">
                        No skills found.
                      </p>
                    )}
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="overview-box">
                    <div className="title">
                      <div className="d-sm-flex justify-content-between mb_10">
                        <h5 className="mb-10">Share Job :</h5>
                        <div className="social-icons circle mb-10">
                          <ul className="list-inline">
                            <li>
                              <a
                                href="#facebook"
                                rel="noopener"
                                aria-label="facebook"
                                onClick={() =>
                                  shareOnFacebook(
                                    jobDetails?.job_title,
                                    `http://localhost:3000/jobdetails/${id}`
                                  )
                                }
                              >
                                <i className="ti ti-facebook"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#twitter"
                                rel="noopener"
                                aria-label="twitter"
                                onClick={() =>
                                  shareOnTwitter(
                                    jobDetails?.job_title,
                                    `http://localhost:3000/jobdetails/${id}`
                                  )
                                }
                              >
                                <i className="ti ti-twitter-alt"></i>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#mail"
                                rel="noopener"
                                aria-label="mail"
                                onClick={() =>
                                  emailAFriend(
                                    jobDetails?.job_title,
                                    `http://localhost:3000/jobdetails/${id}`
                                  )
                                }
                              >
                                <i className="ti ti-email"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Job_details;
