import React from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";

import Footer from "../components/layout/Footer";
import servicesdetail1 from "../images/services-detail1.jpg";
import servicesdetail2 from "../images/services-02-1200x800.jpg";
function Services_details() {
  return (
    <div className="site-main">
      <Header />

      <PageHeader title="services details" breadcrumb="services" />

      <div className="ttm-row sidebar ttm-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 content-area order-lg-2">
              <div className="ttm-service-single-content-area">
                <div className="ttm_single_image-wrapper">
                  <img
                    className="img-fluid"
                    src={servicesdetail1}
                    alt="services-img"
                  />
                </div>
                <div className="ttm-service-description mt-40">
                  <h3 className="mb-20">Description For Our Service</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida. Risus
                    commodo viverra maecenas accumsan lacus vel facilisis. m
                    suspendisse ultrices gravida. Risus commodo vivm suspendisse
                    ultrices gravida. ipsum suspendisse ultrices gravida.
                  </p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-research"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Water & Soil Quality</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Lorem Ipsum simply dummy text the printing and
                              typeing industry. Kum has been the Hjuyus
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-healthcare"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Water & Soil Quality</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Lorem Ipsum simply dummy text the printing and
                              typeing industry. Kum has been the Hjuyus
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-conveyor"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Water & Soil Quality</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Lorem Ipsum simply dummy text the printing and
                              typeing industry. Kum has been the Hjuyus
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="featured-icon-box icon-align-before-content icon-ver_align-top mr-50 mr-md-0 mt-20">
                        <div className="featured-icon">
                          <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                            <i className="flaticon-human-resources-3"></i>
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>Water & Soil Quality</h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              Lorem Ipsum simply dummy text the printing and
                              typeing industry. Kum has been the Hjuyus
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ttm-horizontal_sep width-100 mt-25 mb-35"></div>
                  <div className="row">
                    <div className="col-md-6">
                      <h4>The Best Aquarist You Can Make is In Your Self</h4>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipis ing elit,
                        sed do eiusmod tempor incidi.
                      </p>
                      <ul className="ttm-list ttm-list-style-square ttm-list-icon-color-skincolor mb-30">
                        <li>
                          <div className="ttm-list-li-content">
                            Lorem ipsum dolor sit amet, consectetur adipi
                          </div>
                        </li>
                        <li>
                          <div className="ttm-list-li-content">
                            There are many variations of passages of Lorem
                          </div>
                        </li>
                        <li>
                          <div className="ttm-list-li-content">
                            The standard chunk of Lorem Ipsum used since
                          </div>
                        </li>
                        <li>
                          <div className="ttm-list-li-content">
                            Contrary to popular belief, Lorem Ipsum
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <div className="ttm_single_image-wrapper mb-30">
                        <img
                          className="img-fluid"
                          src={servicesdetail2}
                          alt="services-img"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt utlabore et dolore
                        magna aliqua. Quis ipsum suspendisse ultrices gravida.
                        Risus com{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 widget-area">
              <div className="sidebar-right">
                <aside className="widget widget-nav-menu">
                  <ul>
                    <li>
                      <a className="active" href="/services_details">
                        Manpower Supply
                      </a>
                    </li>
                    <li>
                      <a href="/services_details">Technical Services</a>
                    </li>
                    <li>
                      <a href="/services_details">Executive Search</a>
                    </li>
                    <li>
                      <a href="/services_details">Forte Acquisition</a>
                    </li>
                    <li>
                      <a href="/services_details">Human Resource</a>
                    </li>
                    <li>
                      <a href="/services_details">Retained search</a>
                    </li>
                  </ul>
                </aside>
                <aside className="widget widget-download">
                  <ul className="download">
                    <li>
                      <span className="download-img">
                        <img src="images/pdf-img.png" alt="pdf-img" />
                      </span>
                      <span className="download-caption">
                        <span>Case Studies</span>
                        <h6>Audit & Assuarance</h6>
                        <a
                          className="ttm-btn btn-inline ttm-btn-size-md ttm-icon-btn-left ttm-btn-color-grey"
                          href="/"
                        >
                          <i className="ti ti-arrow-right"></i>Download (3.5 MB)
                        </a>
                      </span>
                    </li>
                  </ul>
                </aside>
                <aside className="widget widget-banner">
                  <div className="ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg p-30 pb-50 pt-45">
                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                      <div className="ttm-col-wrapper-bg-layer-inner"></div>
                    </div>
                    <div className="layer-content text-center text-theme-WhiteColor">
                      <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-style-round ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                        <i className="far fa-comments"></i>
                      </div>
                      <h3>Do You Need Any Help?</h3>
                      <div className="ttm-horizontal_sep width-100 mt-25 mb-25"></div>
                      <ul>
                        <li>+1 800 556 77 894</li>
                        <li>info@yourmailexample.com</li>
                      </ul>
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                                ttm-btn-color-skincolor"
                        href="/contact_01"
                      >
                        appoinments!
                      </a>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Services_details;
