import React, { useEffect, useState } from "react";

import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Button,
  Input,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Form,
  ModalHeader,
  ModalFooter,
  Table,
} from "reactstrap";
import CustomModal from "./CustomModal";
import axios from "axios";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
var profileID,
  certID,
  workSampleID,
  patentID,
  presentationID,
  publicationID,
  USERID;
const months = [
  { value: "01", label: "Jan" },
  { value: "02", label: "Feb" },
  { value: "03", label: "Mar" },
  { value: "04", label: "Apr" },
  { value: "05", label: "May" },
  { value: "06", label: "Jun" },
  { value: "07", label: "Jul" },
  { value: "08", label: "Aug" },
  { value: "09", label: "Sep" },
  { value: "10", label: "Oct" },
  { value: "11", label: "Nov" },
  { value: "12", label: "Dec" },
];
const maxLengthWorkSample = 500;
const currentYear = new Date().getFullYear();
const yearOptions = [];

for (let year = 1950; year <= currentYear; year++) {
  yearOptions.push(year.toString());
}

const Accomplishments = () => {
  const [onlineProfilesModal, setOnlineProfileModal] = useState(false);
  const [workSampleModal, setWorkSampleModal] = useState(false);
  const [certificationModal, setCertificationModal] = useState(false);
  const [publicationModal, setPublicationModal] = useState(false);
  const [presentationModal, setPresentationModal] = useState(false);
  const [patentModal, setPatentModal] = useState(false);

  const [profile, setProfile] = useState({
    socialProfile: "",
    url: "",
    description: "",
  });
  const [profileData, setProfileData] = useState([]);
  const [errors, setErrors] = useState({});

  const [presentation, setPresentation] = useState({
    title: "",
    url: "",
    description: "",
  });

  const [workTitle, setWorkTitle] = useState("");
  const [url, setUrl] = useState("");
  const [durationFromMonth, setDurationFromMonth] = useState("");
  const [durationFromYear, setDurationFromYear] = useState("");
  const [durationToMonth, setDurationToMonth] = useState("");
  const [durationToYear, setDurationToYear] = useState("");
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [description, setDescription] = useState("");
  const [characterCountWorkSample, setCharacterCountWorkSample] =
    useState(maxLengthWorkSample);

  const [certificationName, setCertificationName] = useState("");
  const [certificationURL, setCertificationURL] = useState("");
  const [certificationID, setCertificationID] = useState("");
  const [certificationValidityFromYear, setCertificationValidityFromYear] =
    useState("");
  const [certificationValidityFromMonth, setCertificationValidityFromMonth] =
    useState("");
  const [certificationValidityToYear, setCertificationValidityToYear] =
    useState("");
  const [certificationValidityToMonth, setCertificationValidityToMonth] =
    useState("");
  const [certificationExpiry, setCertificationExpiry] = useState(false);

  const [certificationDataData, setcertificationDataData] = useState([]);

  const [title, setTitle] = useState("");
  const [publicationURL, setPublicationURL] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [publicationdescription, setPublicationDescription] = useState("");

  const [patentTitle, setPatentTitle] = useState("");
  const [patentURL, setPatentURL] = useState("");
  const [patentOffice, setPatentOffice] = useState("");
  const [status, setStatus] = useState("patent issued");
  const [applicationNumber, setApplicationNumber] = useState("");
  const [issueYear, setIssueYear] = useState("2023");
  const [issueMonth, setIssueMonth] = useState("04");
  const [patentDescp, setPatentDescp] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [editProfileModal, setEditProfileModal] = useState(false);
  const openEditProfileModal = () => setEditProfileModal(!editProfileModal);

  const [delProfileId, setDelProfileId] = useState("");
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const openDeleteProfileModal = () =>
    setDeleteProfileModal(!deleteProfileModal);
  const [isEditProfile, setIsEditProfile] = useState(false);

  const [editCertificationModal, setEditCertificationModal] = useState(false);
  const openEditCertificationModal = () =>
    setEditCertificationModal(!editCertificationModal);

  const [delCertificationId, setDelCertificationd] = useState("");
  const [deleteCertificationModal, setDeleteCertificationModal] =
    useState(false);
  const openDeleteCertificationModal = () =>
    setDeleteCertificationModal(!deleteCertificationModal);
  const [isEditCertification, setIsEditCertification] = useState(false);

  const [delWorkSampleId, setDelWorkSampleId] = useState("");
  const [editWorkSampleModal, setEditWorkSampleModal] = useState(false);
  const openEditWorkSampleModal = () =>
    setEditWorkSampleModal(!editWorkSampleModal);
  const [deleteWorkSampleModal, setDeleteWorkSampleModal] = useState(false);
  const openDeleteWorkSampleModal = () =>
    setDeleteWorkSampleModal(!deleteWorkSampleModal);
  const [isEditWorkSample, setIsEditWorkSample] = useState(false);
  const [workSmapleData, setWorkSampleData] = useState([]);

  const [delPatentId, setDelPatentId] = useState("");
  const [editPatentModal, setEditPatentModal] = useState(false);
  const openEditPatentModal = () => setEditPatentModal(!editPatentModal);
  const [deletePatentModal, setDeletePatentModal] = useState(false);
  const openDeletePatentModal = () => setDeletePatentModal(!deletePatentModal);
  const [isEditPatent, setIsEditPatent] = useState(false);
  const [patentData, setPatentData] = useState([]);

  const [delPresentationId, setDelPresentationId] = useState("");
  const [editPresentationModal, setEditPresentationModal] = useState(false);
  const openEditPresentationModal = () =>
    setEditPresentationModal(!editPresentationModal);
  const [deletePresentationModal, setDeletePresentationModal] = useState(false);
  const openDeletePresentationModal = () =>
    setDeletePresentationModal(!deletePresentationModal);
  const [isEditPresentation, setIsEditPresentation] = useState(false);
  const [presentationData, setPresentationData] = useState([]);

  const [delPublicationId, setDelPublicationId] = useState("");
  const [editPublicationModal, setEditPublicationModal] = useState(false);
  const openEditPublicationModal = () =>
    setEditPublicationModal(!editPublicationModal);
  const [deletePublicationModal, setDeletePublicationModal] = useState(false);
  const openDeletePublicationModal = () =>
    setDeletePublicationModal(!deletePublicationModal);
  const [isEditPublication, setIsEditPublication] = useState(false);
  const [publicationData, setPublicationData] = useState([]);

  const [userId, setUserId] = useState("");

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);
  useEffect(() => {
    axios
      .get(`${subURL}/usersocialprofile/${userId}/`)
      .then((res) => {
        setProfileData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${subURL}/usercertification/${userId}/`)
      .then((res) => {
        setcertificationDataData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${subURL}/userworkstatus/${userId}/`)
      .then((res) => {
        setWorkSampleData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${subURL}/patent/${userId}/`)
      .then((res) => {
        setPatentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${subURL}/presentation/${userId}/`)
      .then((res) => {
        setPresentationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    axios
      .get(`${subURL}/publications/${userId}/`)
      .then((res) => {
        setPublicationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const toggleOnlineProfileModal = () => {
    setCharacterCountWorkSample(maxLengthWorkSample);
    setOnlineProfileModal(!onlineProfilesModal);
    setProfile("");
  };
  const toggleWorkSampleModal = () => {
    setIsEditWorkSample(false);
    setEditWorkSampleModal(false);
    setCharacterCountWorkSample(maxLengthWorkSample);
    setWorkSampleModal(!workSampleModal);
    setWorkTitle("");
    setUrl("");
    setErrors("");
    setDurationFromYear("");
    setDurationFromMonth("");
    setDurationToYear("");
    setDurationToMonth("");
    setCurrentlyWorking("");
    setDescription("");
  };
  const toggleCertificationModal = () => {
    setCertificationModal(!certificationModal);
    setCertificationName("");
    setCertificationID("");
    setCertificationURL("");
    setCertificationValidityFromYear("");
    setCertificationValidityFromMonth("");
    setCertificationValidityToYear("");
    setCertificationValidityToMonth("");
    setErrors("");
  };
  const togglePublicationModal = () => {
    setCharacterCountWorkSample(maxLengthWorkSample);
    setPublicationModal(!publicationModal);
    setTitle("");
    setPublicationURL("");
    setYear("");
    setMonth("");
    setPublicationDescription("");
  };

  const togglePresenttaionModal = () => {
    setPresentation("");
    setCharacterCountWorkSample(maxLengthWorkSample);
    setPresentationModal(!presentationModal);
  };

  const togglePatentModal = () => {
    setCharacterCountWorkSample(maxLengthWorkSample);
    setPatentModal(!patentModal);
    setPatentTitle("");
    setPatentURL("");
    setPatentOffice("");
    setStatus("");
    setApplicationNumber("");
    setPatentDescp("");
    setIssueYear("");
    setIssueMonth("");
  };

  const handleSaveOnlineProfile = (e) => {
    e.preventDefault();
    validateURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          social_profile: profile.socialProfile,
          url: profile.url,
          description: profile.description,
          user_account_id: userId,
        };
        try {
          let headers = {
            "Content-Type": "application/json; charset=utf-8",
          };
          const res = await axios.post(
            `${subURL}/usersocialprofile`,
            formData,
            {
              headers: headers,
            }
          );

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setOnlineProfileModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(
                  `${subURL}/usersocialprofile/${userId}/`
                );

                setProfileData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setProfile("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
          setOnlineProfileModal(true);

          console.log(e);
        }
      }

      storeData();

      toggleOnlineProfileModal();
    }
  };

  const handleSaveWorkSample = (e) => {
    e.preventDefault();
    validateWorkSampleURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          user_account_id: userId,
          work_title: workTitle,
          url: url,
          duration_from_year: durationFromYear,
          duration_from_month: durationFromMonth,
          duration_to_year: durationToYear,
          duration_to_month: durationToMonth,
          working_staus: currentlyWorking,
          description: description,
        };
        try {
          let headers = {
            "Content-Type": "multipart/form-data",
          };
          const res = await axios.post(`${subURL}/userworkstatus`, formData, {
            headers: headers,
          });

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setWorkSampleModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(
                  `${subURL}/userworkstatus/${userId}/`
                );

                setWorkSampleData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setWorkTitle("");
                setUrl("");
                setDurationFromYear("");
                setDurationFromMonth("");
                setDurationToYear("");
                setDurationToMonth("");
                setCurrentlyWorking("");
                setDescription("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setWorkSampleModal(true);
          console.log(e);
        }
      }

      storeData();

      toggleWorkSampleModal();
    }
  };

  const handleWorkSampleEndYearChange = (e) => {
    const selectedEndYear = e.target.value;
    setDurationToYear(selectedEndYear);

    if (
      selectedEndYear !== "" &&
      durationFromYear !== "" &&
      parseInt(selectedEndYear) < parseInt(durationFromYear)
    ) {
      alert("Ending year cannot be lesser than the starting year!");
      setDurationToYear("");
    }
  };

  const handleSaveCertification = (e) => {
    e.preventDefault();
    validateCertificationURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          user_account_id: userId,
          certification_name: certificationName,
          certification_completion_id: certificationID,
          certification_url: certificationURL,
          certification_validity_from_year: certificationValidityFromYear,
          certification_validity_from_month: certificationValidityFromMonth,
          certification_validity_to_year: certificationValidityToYear,
          certification_validity_to_month: certificationValidityToMonth,
          certification_will_expire: certificationExpiry,
        };
        try {
          let headers = {
            "Content-Type": "multipart/form-data",
          };
          const res = await axios.post(
            `${subURL}/usercertification`,
            formData,
            {
              headers: headers,
            }
          );

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setCertificationModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(
                  `${subURL}/usercertification/${userId}/`
                );

                setcertificationDataData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setCertificationName("");
                setCertificationID("");
                setCertificationURL("");
                setCertificationValidityFromYear("");
                setCertificationValidityFromMonth("");
                setCertificationValidityToYear("");
                setCertificationValidityToMonth("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setCertificationModal(true);
          console.log(e);
        }
      }

      storeData();

      toggleCertificationModal();
    }
  };

  const handleSavePublication = (e) => {
    e.preventDefault();
    validatePublicationURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          user_account_id: userId,
          title: title,
          url: publicationURL,
          published_on_month: month,
          description: publicationdescription,
          published_on_year: year,
        };
        try {
          let headers = {
            "Content-Type": "application/json; charset=utf-8",
          };
          const res = await axios.post(`${subURL}/publications`, formData, {
            headers: headers,
          });

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setPublicationModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(
                  `${subURL}/publications/${userId}/`
                );

                setPublicationData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setTitle("");
                setPublicationURL("");
                setYear("");
                setMonth("");
                setPublicationDescription("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setPublicationModal(true);
          console.log(e);
        }
      }

      storeData();

      togglePublicationModal();
    }
  };

  const handleSavePresentation = (e) => {
    e.preventDefault();
    validatePresentationURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          user_account_id: userId,
          presentation_title: presentation.title,
          url: presentation.url,
          description: presentation.description,
        };
        try {
          let headers = {
            "Content-Type": "application/json; charset=utf-8",
          };
          const res = await axios.post(`${subURL}/presentation`, formData, {
            headers: headers,
          });

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setPresentationModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(
                  `${subURL}/presentation/${userId}/`
                );

                setPresentationData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setPresentation("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setPresentationModal(true);
          console.log(e);
        }
      }

      storeData();

      togglePresenttaionModal();
    }
  };

  const handleSavePatent = (e) => {
    e.preventDefault();
    validatePatentURL();
    if (!errors.url) {
      async function storeData() {
        const formData = {
          user_account_id: userId,
          title: patentTitle,
          url: patentURL,
          patent_office: patentOffice,
          status: status,
          application_number: applicationNumber,
          issue_date_month: issueMonth,
          issue_date_year: issueYear,
          description: patentDescp,
        };
        try {
          let headers = {
            "Content-Type": "application/json; charset=utf-8",
          };
          const res = await axios.post(`${subURL}/patent`, formData, {
            headers: headers,
          });

          if (res.status === 201) {
            setModalTitle("Success");
            setSuccessMessage("Data saved successfully!");
            setShowModal(true);
            setPatentModal(false);
            async function fetchData() {
              try {
                const res = await axios.get(`${subURL}/patent/${userId}/`);

                setPatentData(res.data);
              } catch (error) {
                console.log(error);
              } finally {
                setPatentTitle("");
                setPatentURL("");
                setPatentOffice("");
                setStatus("");
                setApplicationNumber("");
                setPatentDescp("");
                setIssueYear("");
                setIssueMonth("");
              }
            }
            fetchData();
          } else {
            console.log("error");
          }
        } catch (e) {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setPatentModal(true);
          console.log(e);
        }
      }

      storeData();

      togglePatentModal();
    }
  };

  const handleWorkSampleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    const remainingCharacters = maxLengthWorkSample - inputValue.length;

    if (remainingCharacters >= 0) {
      setDescription(inputValue);
      setCharacterCountWorkSample(remainingCharacters);
    }
  };

  const handlePatentDescpChange = (e) => {
    const inputValue = e.target.value;
    const remainingCharacters = maxLengthWorkSample - inputValue.length;

    if (remainingCharacters >= 0) {
      setPatentDescp(inputValue);
      setCharacterCountWorkSample(remainingCharacters);
    }
  };

  const handlePublicationDescpChange = (e) => {
    const inputValue = e.target.value;
    const remainingCharacters = maxLengthWorkSample - inputValue.length;

    if (remainingCharacters >= 0) {
      setPublicationDescription(inputValue);
      setCharacterCountWorkSample(remainingCharacters);
    }
  };

  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({ ...prevProfile, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    if (name === "description") {
      const remainingCharacters = maxLengthWorkSample - value.length;
      setCharacterCountWorkSample(remainingCharacters);
    }
  };

  const handlePresentationChange = (event) => {
    const { name, value } = event.target;
    setPresentation({ ...presentation, [name]: value });
    if (name === "description") {
      const remainingCharacters = maxLengthWorkSample - value.length;
      setCharacterCountWorkSample(remainingCharacters);
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  function handleEditProfiles(id) {
    profileID = id;

    setIsEditProfile(true);
    setEditProfileModal(true);

    const filteredDummuyData = profileData.find((data) => data.id === id);

    setProfile({
      socialProfile: filteredDummuyData.social_profile,
      url: filteredDummuyData.url,
      description: filteredDummuyData.description,
    });
  }

  const validateURL = () => {
    // const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+(\.[^ "]+)*$/;

    if (!profile.url || !urlPattern.test(profile.url)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };

  const validateWorkSampleURL = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (!url || !urlPattern.test(url)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };
  const validatePublicationURL = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (!publicationURL || !urlPattern.test(publicationURL)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };

  const validatePresentationURL = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (!presentation.url || !urlPattern.test(presentation.url)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };

  const validatePatentURL = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (!patentURL || !urlPattern.test(patentURL)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };

  const validateCertificationURL = () => {
    const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;

    if (!certificationURL || !urlPattern.test(certificationURL)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        url: "Please enter a valid URL.",
      }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
    }
  };

  const handleprofileURLChange = (e) => {
    const input = e.target.value;

    // Validate the input as a URL using a regular expression
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+(\.[^ "]+)+$/;
    if (urlRegex.test(input)) {
      setProfile(input);
    } else {
      alert("Please enter a valid URL");
      return;
    }
  };
  async function updateProfileHandler() {
    validateURL();
    if (!errors.url) {
      const formData = {
        social_profile: profile.socialProfile,
        url: profile.url,
        description: profile.description,
        user_account_id: userId,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(
          `${subURL}/usersocialprofile/${profileID}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditProfileModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(
                `${subURL}/usersocialprofile/${userId}/`
              );

              setProfileData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
        console.log(e);
      }
    }
  }

  async function handleDeleteProfile(id) {
    setDeleteProfileModal(true);
    setDelProfileId(id);
  }

  async function deleteItemProfile() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/usersocialprofile/${delProfileId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteProfileModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/usersocialprofile/${userId}/`
            );

            setProfileData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
      console.log(error);
    }
  }

  function handleEditCertification(id) {
    certID = id;

    setIsEditCertification(true);
    setEditCertificationModal(true);

    const filteredDummuyData = certificationDataData.find(
      (data) => data.id === id
    );
    setCertificationName(filteredDummuyData.certification_name);
    setCertificationID(filteredDummuyData.certification_completion_id);
    setCertificationURL(filteredDummuyData.certification_url);
    setCertificationValidityFromYear(
      filteredDummuyData.certification_validity_from_year
    );
    setCertificationValidityFromMonth(
      filteredDummuyData.certification_validity_from_month
    );
    setCertificationValidityToYear(
      filteredDummuyData.certification_validity_to_year
    );
    setCertificationValidityToMonth(
      filteredDummuyData.certification_validity_to_month
    );
    setCertificationExpiry(filteredDummuyData.certification_will_expire);
  }

  async function updateCertification() {
    validateCertificationURL();
    if (!errors.url) {
      const formData = {
        user_account_id: userId,
        certification_name: certificationName,
        certification_completion_id: certificationID,
        certification_url: certificationURL,
        certification_validity_from_year: certificationValidityFromYear,
        certification_validity_from_month: certificationValidityFromMonth,
        certification_validity_to_year: certificationValidityToYear,
        certification_validity_to_month: certificationValidityToMonth,
        certification_will_expire: certificationExpiry,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(
          `${subURL}/usercertification/${certID}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditCertificationModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(
                `${subURL}/usercertification/${userId}/`
              );

              setcertificationDataData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditCertificationModal(false);
        console.log(e);
      }
    }
  }
  function handleDeleteCertification(id) {
    setDeleteCertificationModal(true);
    setDelCertificationd(id);
  }
  async function deleteItemCertification() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/usercertification/${delCertificationId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteCertificationModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/usercertification/${userId}/`
            );

            setcertificationDataData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditCertificationModal(false);
      console.log(error);
    }
  }

  function handleEditWorkSample(id) {
    workSampleID = id;

    setIsEditWorkSample(true);
    setEditWorkSampleModal(true);

    const filteredDummuyData = workSmapleData.find((data) => data.id === id);
    setWorkTitle(filteredDummuyData.work_title);

    setDescription(filteredDummuyData.description);
    setCurrentlyWorking(filteredDummuyData.working_staus);
    setUrl(filteredDummuyData.url);
    setDurationFromMonth(filteredDummuyData.duration_from_month);
    setDurationFromYear(filteredDummuyData.duration_from_year);
    setDurationToMonth(filteredDummuyData.duration_to_month);
    setDurationToYear(filteredDummuyData.duration_to_year);
  }
  async function updateWorkSample() {
    validateWorkSampleURL();
    if (!errors.url) {
      const formData = {
        user_account_id: userId,
        work_title: workTitle,
        url: url,
        duration_from_year: durationFromYear,
        duration_from_month: durationFromMonth,
        duration_to_year: durationToYear,
        duration_to_month: durationToMonth,
        working_staus: currentlyWorking,
        description: description,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(
          `${subURL}/userworkstatus/${workSampleID}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditWorkSampleModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(
                `${subURL}/userworkstatus/${userId}/`
              );

              setWorkSampleData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditWorkSampleModal(false);
        console.log(e);
      }
    }
  }

  function handleDeleteWorkSample(id) {
    setDeleteWorkSampleModal(true);
    setDelWorkSampleId(id);
  }

  async function deleteItemWorkSample() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/userworkstatus/${delWorkSampleId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteWorkSampleModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/userworkstatus/${userId}/`);

            setWorkSampleData(res.data);
          } catch (error) {
            setModalTitle("Failed");
            setSuccessMessage("Oops !Something went wrong");
            setShowModal(true);
            setEditWorkSampleModal(false);
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  function handleEditPatent(id) {
    patentID = id;

    setIsEditPatent(true);
    setEditPatentModal(true);

    const filteredDummuyData = patentData.find((data) => data.id === id);
    setPatentTitle(filteredDummuyData.title);

    setPatentURL(filteredDummuyData.url);
    setPatentOffice(filteredDummuyData.patent_office);
    setStatus(filteredDummuyData.status);
    setApplicationNumber(filteredDummuyData.application_number);
    setPatentDescp(filteredDummuyData.description);
  }

  async function updatePatent() {
    validatePatentURL();
    if (!errors.url) {
      const formData = {
        user_account_id: userId,
        title: patentTitle,
        url: patentURL,
        patent_office: patentOffice,
        status: status,
        application_number: applicationNumber,
        issue_date_month: issueMonth,
        issue_date_year: issueYear,
        description: patentDescp,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(`${subURL}/patent/${patentID}/`, formData, {
          headers: headers,
        });

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditPatentModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/patent/${userId}/`);

              setPatentData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditPatentModal(false);
        console.log(e);
      }
    }
  }

  function handleDeletePatent(id) {
    setDeletePatentModal(true);
    setDelPatentId(id);
  }
  async function deleteItemPatent() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/patent/${delPatentId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeletePatentModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/patent/${userId}/`);

            setPatentData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditPatentModal(false);
      console.log(error);
    }
  }

  function handleEditPresentation(id) {
    presentationID = id;

    setIsEditPresentation(true);
    setEditPresentationModal(true);

    const filteredDummuyData = presentationData.find((data) => data.id === id);

    setPresentation({
      title: filteredDummuyData.presentation_title,
      url: filteredDummuyData.url,
      description: filteredDummuyData.description,
    });
  }

  async function updatePresentation() {
    validatePresentationURL();
    if (!errors.url) {
      const formData = {
        user_account_id: userId,
        presentation_title: presentation.title,
        url: presentation.url,
        description: presentation.description,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(
          `${subURL}/presentation/${presentationID}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditPresentationModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/presentation/${userId}/`);

              setPresentationData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditWorkSampleModal(false);
        console.log(e);
      }
    }
  }

  function handleDeletePresentation(id) {
    setDeletePresentationModal(true);
    setDelPresentationId(id);
  }
  async function deleteItemPresentation() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/presentation/${delPresentationId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeletePresentationModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/presentation/${userId}/`);

            setPresentationData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditWorkSampleModal(false);
      console.log(error);
    }
  }

  function handleEditPublication(id) {
    publicationID = id;

    setIsEditPublication(true);
    setEditPublicationModal(true);

    const filteredDummuyData = publicationData.find((data) => data.id === id);
    setTitle(filteredDummuyData.title);

    setPublicationURL(filteredDummuyData.url);
    setYear(filteredDummuyData.published_on_year);
    setMonth(filteredDummuyData.published_on_month);
    setPublicationDescription(filteredDummuyData.description);
  }

  async function updatePublication() {
    validatePublicationURL();
    if (!errors.url) {
      const formData = {
        user_account_id: userId,
        title: title,
        url: publicationURL,
        published_on_month: month,
        description: publicationdescription,
        published_on_year: year,
      };
      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.put(
          `${subURL}/publications/${publicationID}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          setEditPublicationModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/publications/${userId}/`);

              setPublicationData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditWorkSampleModal(false);
        console.log(e);
      }
    }
  }

  function handleDeletePublication(id) {
    setDeletePublicationModal(true);
    setDelPublicationId(id);
  }
  async function deleteItemPublication() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/publications/${delPublicationId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeletePublicationModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/publications/${userId}/`);

            setPublicationData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      console.log(error);
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditWorkSampleModal(false);
    }
  }
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Accomplishments</h5>
        <div className="button-container">
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Online Profiles</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                type="submit"
                onClick={toggleOnlineProfileModal}
              >
                Add
              </button>
            </ListGroupItem>
            {profileData.length > 0 ? (
              <>
                {profileData.map((profileData) => (
                  <ListGroupItem
                    key={profileData.id}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <a
                      href={profileData.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profileData.social_profile}
                    </a>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "2em",
                        width: "10em",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="green"
                        onClick={() => handleEditProfiles(profileData.id)}
                        style={{ cursor: "pointer" }}
                      />

                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => handleDeleteProfile(profileData.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </ListGroupItem>
                ))}
              </>
            ) : (
              <ListGroupItem>
                <p> No profiles found...</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Work Samples</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor "
                type="submit"
                onClick={toggleWorkSampleModal}
              >
                Add
              </button>
            </ListGroupItem>

            {workSmapleData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Work Title</th>

                    <th style={{ fontWeight: "500" }}>Working Status</th>
                    <th style={{ fontWeight: "500" }}>Description</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {workSmapleData.map((workSampleData, key) => (
                    <tr key={workSampleData.id}>
                      <td>{key + 1}</td>
                      <td>{workSampleData.work_title}</td>

                      <td>
                        {workSampleData.working_staus === true ? "Yes" : "No"}
                      </td>
                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {workSampleData.description}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() =>
                            handleEditWorkSample(workSampleData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() =>
                            handleDeleteWorkSample(workSampleData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> Add link to your Projects (e.g. Github links etc.).</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>White Paper / Research Publication / Journal Entry</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor "
                type="submit"
                onClick={togglePublicationModal}
              >
                Add
              </button>
            </ListGroupItem>
            {publicationData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>
                    <th style={{ fontWeight: "500" }}> Description</th>
                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Published On</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {publicationData.map((publicationData, key) => (
                    <tr key={publicationData.id}>
                      <td>{key + 1}</td>
                      <td>{publicationData.title}</td>
                      <td style={{ wordWrap: "break-word", maxWidth: "250px" }}>
                        {publicationData.description}
                      </td>
                      <td>{publicationData.url}</td>
                      <td>
                        {publicationData.published_on_year}
                        {"-"}
                        {publicationData.published_on_month}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "3 em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() =>
                            handleEditPublication(publicationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() =>
                            handleDeletePublication(publicationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No data found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Presentation</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor "
                type="submit"
                onClick={togglePresenttaionModal}
              >
                Add
              </button>
            </ListGroupItem>

            {presentationData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>

                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Description</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {presentationData.map((presentationData, key) => (
                    <tr key={presentationData.id}>
                      <td>{key + 1}</td>
                      <td>{presentationData.presentation_title}</td>

                      <td style={{ wordWrap: "break-word", maxWidth: "150px" }}>
                        {presentationData.url}
                      </td>
                      <td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
                        {presentationData.description}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "1em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() =>
                            handleEditPresentation(presentationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() =>
                            handleDeletePresentation(presentationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No presentation data found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Patent</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor "
                type="submit"
                onClick={togglePatentModal}
              >
                Add
              </button>
            </ListGroupItem>

            {patentData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>

                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Patent Office</th>
                    <th style={{ fontWeight: "500" }}>Application Number</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {patentData.map((patentData, key) => (
                    <tr key={patentData.id}>
                      <td>{key + 1}</td>
                      <td
                        style={{
                          wordWrap: "break-word",
                          maxWidth: "200px",
                        }}
                      >
                        {patentData.title}
                      </td>

                      <td
                        style={{
                          wordWrap: "break-word",
                          maxWidth: "200px",
                        }}
                      >
                        {patentData.url}
                      </td>
                      <td>{patentData.patent_office}</td>
                      <td>{patentData.application_number}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2em",
                          //width: "9em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() => handleEditPatent(patentData.id)}
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() => handleDeletePatent(patentData.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> Add details of Patents you have filed.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Certification</h6>
              <button
                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor "
                type="submit"
                onClick={toggleCertificationModal}
              >
                Add
              </button>
            </ListGroupItem>

            {certificationDataData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Certification Name</th>
                    <th
                      style={{
                        wordWrap: "break-word",
                        maxWidth: "100px",
                        fontWeight: "500",
                      }}
                    >
                      Certification Completion ID
                    </th>
                    <th style={{ fontWeight: "500" }}>Certification URL</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {certificationDataData.map((certificationData, index) => (
                    <tr key={certificationData.id}>
                      <td>{index + 1}</td>
                      <td>{certificationData.certification_name}</td>
                      <td>{certificationData.certification_completion_id}</td>
                      <td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
                        {certificationData.certification_url}
                      </td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() =>
                            handleEditCertification(certificationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() =>
                            handleDeleteCertification(certificationData.id)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No data found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      <CustomModal
        isOpen={onlineProfilesModal}
        toggleModal={toggleOnlineProfileModal}
        handleSave={handleSaveOnlineProfile}
        modalTitle="Add Online Profile"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSaveOnlineProfile}>
          <FormGroup>
            <Label for="socialProfile">Social Profile</Label>
            <Input
              required
              type="select"
              name="socialProfile"
              id="socialProfile"
              value={profile.socialProfile}
              onChange={handleProfileChange}
            >
              <option value="">Select Social Profile</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="GitHub">GitHub</option>
              <option value="Twitter">Twitter</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="url">URL</Label>
            <Input
              required
              type="text"
              name="url"
              id="url"
              placeholder="Enter URL"
              defaultValue={profile.url}
              onChange={handleProfileChange}
              onBlur={validateURL}
            />

            {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              rows={5}
              type="textarea"
              name="description"
              id="description"
              placeholder="Enter Description"
              value={profile.description}
              onChange={handleProfileChange}
            />
            <small>{characterCountWorkSample} Character(s) Left</small>
          </FormGroup>
        </Form>
      </CustomModal>

      <CustomModal
        isOpen={workSampleModal}
        toggleModal={toggleWorkSampleModal}
        handleSave={handleSaveWorkSample}
        modalTitle="Add Work Samples"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSaveWorkSample}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="workTitle">Work Title</Label>
                <Input
                  required
                  type="text"
                  name="workTitle"
                  id="workTitle"
                  value={workTitle}
                  onChange={(e) => setWorkTitle(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="url">URL</Label>
                <Input
                  type="url"
                  name="url"
                  id="url"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  onBlur={validateWorkSampleURL}
                />
                {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="durationFromMonth">Duration From</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="durationFromYear"
                      id="durationFromYear"
                      value={durationFromYear}
                      onChange={(e) => setDurationFromYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="durationFromMonth"
                      id="durationFromMonth"
                      value={durationFromMonth}
                      onChange={(e) => setDurationFromMonth(e.target.value)}
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            {!currentlyWorking && (
              <Col md={12}>
                <FormGroup>
                  <Label for="durationToMonth">Duration To</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="select"
                        name="durationToYear"
                        id="durationToYear"
                        value={durationToYear}
                        onChange={handleWorkSampleEndYearChange}
                        disabled={currentlyWorking}
                      >
                        <option value="">Select Year</option>
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md={6}>
                      <Input
                        type="select"
                        name="durationToMonth"
                        id="durationToMonth"
                        value={durationToMonth}
                        onChange={(e) => setDurationToMonth(e.target.value)}
                        disabled={currentlyWorking}
                      >
                        <option value="">Select Month</option>
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
          </Row>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="currentlyWorking"
                id="currentlyWorking"
                checked={currentlyWorking}
                onChange={(e) => setCurrentlyWorking(e.target.checked)}
              />{" "}
              I am currently working on this
            </Label>
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              rows={5}
              type="textarea"
              name="description"
              id="description"
              placeholder="Enter Description"
              value={description}
              onChange={handleWorkSampleDescriptionChange}
            />
            <small>{characterCountWorkSample} Character(s) Left</small>
          </FormGroup>
        </Form>
      </CustomModal>

      <CustomModal
        isOpen={certificationModal}
        toggleModal={toggleCertificationModal}
        handleSave={handleSaveCertification}
        modalTitle="Add Certification"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSaveCertification}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="CertificationName">Certification Name</Label>
                <Input
                  type="text"
                  name="CertificationName"
                  id="CertificationName"
                  value={certificationName}
                  onChange={(e) => setCertificationName(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="cID">Certification Completion ID</Label>
                <Input
                  type="number"
                  name="cID"
                  id="cID"
                  value={certificationID}
                  onChange={(e) => {
                    const enteredValue = parseInt(e.target.value, 10);
                    if (enteredValue >= 0) {
                      setCertificationID(enteredValue);
                    }
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="cURL">Certification URL</Label>
                <Input
                  type="text"
                  name="cURL"
                  id="cURL"
                  value={certificationURL}
                  onChange={(e) => setCertificationURL(e.target.value)}
                  onBlur={validateCertificationURL}
                />
                {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="CvalidityY">Certification Validity</Label>
                <Row>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="CvalidityY"
                      id="CvalidityY"
                      value={certificationValidityFromYear}
                      onChange={(e) =>
                        setCertificationValidityFromYear(e.target.value)
                      }
                    >
                      <option value="">Select Year</option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col md={6}>
                    <Input
                      type="select"
                      name="CvalidityM"
                      id="CvalidityM"
                      value={certificationValidityFromMonth}
                      onChange={(e) =>
                        setCertificationValidityFromMonth(e.target.value)
                      }
                    >
                      <option value="">Select Month</option>
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
            {!certificationExpiry && (
              <Col md={12}>
                <FormGroup>
                  <Label for="CExpiryY">Certification Expiry</Label>
                  <Row>
                    <Col md={6}>
                      <Input
                        type="select"
                        name="CExpiryY"
                        id="CExpiryY"
                        value={certificationValidityToYear}
                        onChange={(e) =>
                          setCertificationValidityToYear(e.target.value)
                        }
                        disabled={certificationExpiry}
                      >
                        <option value="">Select Year</option>
                        {yearOptions.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col md={6}>
                      <Input
                        type="select"
                        name="CExpiryM"
                        id="CExpiryM"
                        value={certificationValidityToMonth}
                        onChange={(e) =>
                          setCertificationValidityToMonth(e.target.value)
                        }
                        disabled={certificationExpiry}
                      >
                        <option value="">Select Month</option>
                        {months.map((month) => (
                          <option key={month.value} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            )}
          </Row>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="cexpire"
                id="cexpire"
                checked={certificationExpiry}
                onChange={(e) => setCertificationExpiry(e.target.checked)}
              />{" "}
              This Certification does not expire
            </Label>
          </FormGroup>
        </Form>
      </CustomModal>

      <CustomModal
        isOpen={publicationModal}
        toggleModal={togglePublicationModal}
        handleSave={handleSavePublication}
        modalTitle="Add Publication"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSavePublication}>
          <Row>
            <Col md={12}>
              <FormGroup>
                <Label for="title">Title</Label>
                <Input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="url">URL</Label>
                <Input
                  type="url"
                  id="url"
                  value={publicationURL}
                  onChange={(e) => setPublicationURL(e.target.value)}
                  required
                  onBlur={validatePublicationURL}
                />
                {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label for="publishedOn">Published On</Label>
                <div style={{ display: "flex" }}>
                  <Input
                    type="select"
                    id="year"
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    required
                    style={{ marginRight: "10px" }}
                  >
                    <option value="">--Select Year--</option>
                    {yearOptions.map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                  </Input>
                  <Input
                    type="select"
                    id="month"
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    required
                  >
                    <option value="">--Select Month--</option>
                    {months.map((m) => (
                      <option key={m.value} value={m.label}>
                        {m.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  rows={5}
                  type="textarea"
                  id="description"
                  value={publicationdescription}
                  onChange={handlePublicationDescpChange}
                  required
                />
                <small>{characterCountWorkSample} Character(s) Left</small>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CustomModal>

      <CustomModal
        isOpen={presentationModal}
        toggleModal={togglePresenttaionModal}
        handleSave={handleSavePresentation}
        modalTitle="Add Presentation"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSavePresentation}>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input
              type="text"
              name="title"
              id="title"
              value={presentation.title}
              onChange={handlePresentationChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="url">URL</Label>
            <Input
              type="url"
              name="url"
              id="url"
              placeholder="Enter URL"
              value={presentation.url}
              onChange={handlePresentationChange}
              onBlur={validatePresentationURL}
            />
            {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              rows={5}
              type="textarea"
              name="description"
              id="description"
              placeholder="Enter Description"
              value={presentation.description}
              onChange={handlePresentationChange}
            />
            <small>{characterCountWorkSample} Character(s) Left</small>
          </FormGroup>
        </Form>
      </CustomModal>

      <CustomModal
        isOpen={patentModal}
        toggleModal={togglePatentModal}
        handleSave={handleSavePatent}
        modalTitle="Add Patent"
        buttonLabel="Save"
      >
        <Form onSubmit={handleSavePatent}>
          <FormGroup>
            <Label for="patentTitle">Patent Title</Label>
            <Input
              type="text"
              name="patentTitle"
              id="patentTitle"
              value={patentTitle}
              onChange={(event) => setPatentTitle(event.target.value)}
              placeholder="Enter patent title"
            />
          </FormGroup>
          <FormGroup>
            <Label for="url">URL</Label>
            <Input
              required
              type="text"
              name="url"
              id="url"
              value={patentURL}
              onChange={(event) => setPatentURL(event.target.value)}
              placeholder="Enter URL"
              onBlur={validatePatentURL}
            />
            {errors.url && <div style={{ color: "red" }}>{errors.url}</div>}
          </FormGroup>
          <FormGroup>
            <Label for="patentOffice">Patent Office</Label>
            <Input
              type="text"
              name="patentOffice"
              id="patentOffice"
              value={patentOffice}
              onChange={(event) => setPatentOffice(event.target.value)}
              placeholder="Enter patent office"
            />
          </FormGroup>
          <FormGroup>
            <Label for="status">Status</Label>
            <div className="radio-group">
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    id="patentIssued"
                    name="status"
                    label="Patent Issued"
                    value="patent issued"
                    defaultChecked={status === "patent issued"}
                    onChange={handleStatusChange}
                  />
                  Patent Issued
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    id="patentPending"
                    name="status"
                    label="Patent Pending"
                    value="patent pending"
                    defaultChecked={status === "patent pending"}
                    onChange={handleStatusChange}
                  />
                  Patent Pending
                </Label>
              </FormGroup>
            </div>
          </FormGroup>

          <FormGroup>
            <Label for="applicationNumber">Application Number</Label>
            <Input
              type="text"
              name="applicationNumber"
              id="applicationNumber"
              value={applicationNumber}
              onChange={(event) => setApplicationNumber(event.target.value)}
              placeholder="Enter application number"
            />
          </FormGroup>

          {status === "patent pending" && (
            <div className="p-1">
              <Row>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="year">Issued date (in year)</Label>
                    <Input
                      type="select"
                      name="year"
                      id="year"
                      value={issueYear}
                      onChange={(e) => setIssueYear(e.target.value)}
                    >
                      <option value="">Select Year</option>
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm={6}>
                  <FormGroup>
                    <Label for="month">Issue Date(in month)</Label>
                    <Input
                      type="select"
                      name="month"
                      id="month"
                      value={issueMonth}
                      onChange={(e) => setIssueMonth(e.target.value)}
                    >
                      <option value="">Select month</option>
                      {months.map((month, index) => (
                        <option key={index} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}
          <FormGroup>
            <Label for="patentdescp">Patent Description</Label>
            <Input
              rows={5}
              type="textarea"
              name="patentdescp"
              id="patentdescp"
              value={patentDescp}
              onChange={handlePatentDescpChange}
              //  onChange={(event) => setPatentDescp(event.target.value)}
              placeholder="Enter patent Description"
            />
            <small>{characterCountWorkSample} Character(s) Left</small>
          </FormGroup>
        </Form>
      </CustomModal>

      {isEditProfile && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editProfileModal}
              toggle={openEditProfileModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Profile
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditProfileModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form>
                  <FormGroup>
                    <Label for="socialProfile">Social Profile</Label>
                    <Input
                      type="select"
                      name="socialProfile"
                      id="socialProfile"
                      value={profile.socialProfile}
                      onChange={handleProfileChange}
                    >
                      <option value="">Select Social Profile</option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="GitHub">GitHub</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Instagram">Instagram</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      required
                      type="text"
                      name="url"
                      id="url"
                      placeholder="Enter URL"
                      defaultValue={profile.url}
                      onChange={handleProfileChange}
                      onBlur={validateURL}
                    />

                    {errors.url && (
                      <div style={{ color: "red" }}>{errors.url}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      value={profile.description}
                      onChange={handleProfileChange}
                    />
                    <small>{characterCountWorkSample} Character(s) Left</small>
                  </FormGroup>
                </Form>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditProfileModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateProfileHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteProfileModal}
            toggle={openDeleteProfileModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteProfileModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteProfileModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemProfile}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEditCertification && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editCertificationModal}
              toggle={openEditCertificationModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Certification
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditCertificationModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="CertificationName">
                          Certification Name
                        </Label>
                        <Input
                          type="text"
                          name="CertificationName"
                          id="CertificationName"
                          value={certificationName}
                          onChange={(e) => setCertificationName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="cID">Certification Completion ID</Label>
                        <Input
                          type="cID"
                          name="cID"
                          id="cID"
                          value={certificationID}
                          onChange={(e) => {
                            const enteredValue = parseInt(e.target.value, 10);
                            if (enteredValue >= 0) {
                              setCertificationID(enteredValue);
                            }
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="cURL">Certification URL</Label>
                        <Input
                          type="text"
                          name="cURL"
                          id="cURL"
                          value={certificationURL}
                          onChange={(e) => setCertificationURL(e.target.value)}
                          onBlur={validateCertificationURL}
                        />
                        {errors.url && (
                          <div style={{ color: "red" }}>{errors.url}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="CvalidityY">Certification Validity</Label>
                        <Row>
                          <Col md={6}>
                            <Input
                              type="select"
                              name="CvalidityY"
                              id="CvalidityY"
                              value={certificationValidityFromYear}
                              onChange={(e) =>
                                setCertificationValidityFromYear(e.target.value)
                              }
                            >
                              <option value="">Select Year</option>
                              {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col md={6}>
                            <Input
                              type="select"
                              name="CvalidityM"
                              id="CvalidityM"
                              value={certificationValidityFromMonth}
                              onChange={(e) =>
                                setCertificationValidityFromMonth(
                                  e.target.value
                                )
                              }
                            >
                              <option value="">Select Month</option>
                              {months.map((month) => (
                                <option key={month.value} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {!certificationExpiry && (
                      <Col md={12}>
                        <FormGroup>
                          <Label for="CExpiryY">Certification Expiry</Label>
                          <Row>
                            <Col md={6}>
                              <Input
                                type="select"
                                name="CExpiryY"
                                id="CExpiryY"
                                value={certificationValidityToYear}
                                onChange={(e) =>
                                  setCertificationValidityToYear(e.target.value)
                                }
                                disabled={certificationExpiry}
                              >
                                <option value="">Select Year</option>
                                {yearOptions.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col md={6}>
                              <Input
                                type="select"
                                name="CExpiryM"
                                id="CExpiryM"
                                value={certificationValidityToMonth}
                                onChange={(e) =>
                                  setCertificationValidityToMonth(
                                    e.target.value
                                  )
                                }
                                disabled={certificationExpiry}
                              >
                                <option value="">Select Month</option>
                                {months.map((month) => (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="cexpire"
                        id="cexpire"
                        checked={certificationExpiry}
                        onChange={(e) =>
                          setCertificationExpiry(e.target.checked)
                        }
                      />{" "}
                      This Certification does not expire
                    </Label>
                  </FormGroup>
                </Form>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditCertificationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateCertification}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteCertificationModal}
            toggle={openDeleteCertificationModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteCertificationModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteCertificationModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemCertification}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEditWorkSample && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editWorkSampleModal}
              toggle={openEditWorkSampleModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Work Sample
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditWorkSampleModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="workTitle">Work Title</Label>
                        <Input
                          type="text"
                          name="workTitle"
                          id="workTitle"
                          value={workTitle}
                          onChange={(e) => setWorkTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="url">URL</Label>
                        <Input
                          type="url"
                          name="url"
                          id="url"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          onBlur={validateWorkSampleURL}
                        />
                        {errors.url && (
                          <div style={{ color: "red" }}>{errors.url}</div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="durationFromMonth">Duration From</Label>
                        <Row>
                          <Col md={6}>
                            <Input
                              type="select"
                              name="durationFromYear"
                              id="durationFromYear"
                              value={durationFromYear}
                              onChange={(e) =>
                                setDurationFromYear(e.target.value)
                              }
                            >
                              <option value="">Select Year</option>
                              {yearOptions.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col md={6}>
                            <Input
                              type="select"
                              name="durationFromMonth"
                              id="durationFromMonth"
                              value={durationFromMonth}
                              onChange={(e) =>
                                setDurationFromMonth(e.target.value)
                              }
                            >
                              <option value="">Select Month</option>
                              {months.map((month) => (
                                <option key={month.value} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                    {!currentlyWorking && (
                      <Col md={12}>
                        <FormGroup>
                          <Label for="durationToMonth">Duration To</Label>
                          <Row>
                            <Col md={6}>
                              <Input
                                type="select"
                                name="durationToYear"
                                id="durationToYear"
                                value={durationToYear}
                                onChange={handleWorkSampleEndYearChange}
                                disabled={currentlyWorking}
                              >
                                <option value="">Select Year</option>
                                {yearOptions.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col md={6}>
                              <Input
                                type="select"
                                name="durationToMonth"
                                id="durationToMonth"
                                value={durationToMonth}
                                onChange={(e) =>
                                  setDurationToMonth(e.target.value)
                                }
                                disabled={currentlyWorking}
                              >
                                <option value="">Select Month</option>
                                {months.map((month) => (
                                  <option key={month.value} value={month.value}>
                                    {month.label}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="currentlyWorking"
                        id="currentlyWorking"
                        checked={currentlyWorking}
                        onChange={(e) => setCurrentlyWorking(e.target.checked)}
                      />{" "}
                      I am currently working on this
                    </Label>
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      value={description}
                      onChange={handleWorkSampleDescriptionChange}
                    />
                    <small>{characterCountWorkSample} Character(s) Left</small>
                  </FormGroup>
                </Form>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditWorkSampleModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateWorkSample}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteWorkSampleModal}
            toggle={openDeleteWorkSampleModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteWorkSampleModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteWorkSampleModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemWorkSample}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEditPatent && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editPatentModal}
              toggle={openEditPatentModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Patent
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditPatentModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form onSubmit={handleSavePatent}>
                  <FormGroup>
                    <Label for="patentTitle">Patent Title</Label>
                    <Input
                      type="text"
                      name="patentTitle"
                      id="patentTitle"
                      value={patentTitle}
                      onChange={(event) => setPatentTitle(event.target.value)}
                      placeholder="Enter patent title"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      type="text"
                      name="url"
                      id="url"
                      value={patentURL}
                      onChange={(event) => setPatentURL(event.target.value)}
                      placeholder="Enter URL"
                      onBlur={validatePatentURL}
                    />
                    {errors.url && (
                      <div style={{ color: "red" }}>{errors.url}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="patentOffice">Patent Office</Label>
                    <Input
                      type="text"
                      name="patentOffice"
                      id="patentOffice"
                      value={patentOffice}
                      onChange={(event) => setPatentOffice(event.target.value)}
                      placeholder="Enter patent office"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="patentIssued"
                            name="status"
                            label="Patent Issued"
                            value="patent issued"
                            defaultChecked={status === "patent issued"}
                            onChange={handleStatusChange}
                          />
                          Patent Issued
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="patentPending"
                            name="status"
                            label="Patent Pending"
                            value="patent pending"
                            defaultChecked={status === "patent pending"}
                            onChange={handleStatusChange}
                          />
                          Patent Pending
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>

                  <FormGroup>
                    <Label for="applicationNumber">Application Number</Label>
                    <Input
                      type="text"
                      name="applicationNumber"
                      id="applicationNumber"
                      value={applicationNumber}
                      onChange={(event) =>
                        setApplicationNumber(event.target.value)
                      }
                      placeholder="Enter application number"
                    />
                  </FormGroup>

                  {status === "patent pending" && (
                    <div className="p-1">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="year">Issued date (in year)</Label>
                            <Input
                              type="select"
                              name="year"
                              id="year"
                              value={issueYear}
                              onChange={(e) => setIssueYear(e.target.value)}
                            >
                              {yearOptions.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="month">Issue Date(in month)</Label>
                            <Input
                              type="select"
                              name="month"
                              id="month"
                              value={issueMonth}
                              onChange={(e) => setIssueMonth(e.target.value)}
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <FormGroup>
                    <Label for="patentdescp">Patent Description</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="patentdescp"
                      id="patentdescp"
                      value={patentDescp}
                      onChange={handlePatentDescpChange}
                      placeholder="Enter patent Description"
                    />
                    <small>{characterCountWorkSample} Character(s) Left</small>
                  </FormGroup>
                </Form>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditPatentModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updatePatent}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deletePatentModal}
            toggle={openDeletePatentModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeletePatentModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeletePatentModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemPatent}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEditPresentation && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editPresentationModal}
              toggle={openEditPresentationModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Presentation
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditPresentationModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form>
                  <FormGroup>
                    <Label for="title">Title</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      value={presentation.title}
                      onChange={handlePresentationChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="url">URL</Label>
                    <Input
                      type="url"
                      name="url"
                      id="url"
                      placeholder="Enter URL"
                      value={presentation.url}
                      onChange={handlePresentationChange}
                      onBlur={validatePresentationURL}
                    />
                    {errors.url && (
                      <div style={{ color: "red" }}>{errors.url}</div>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="description">Description</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      value={presentation.description}
                      onChange={handlePresentationChange}
                    />
                    <small>{characterCountWorkSample} Character(s) Left</small>
                  </FormGroup>
                </Form>

                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditPresentationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updatePresentation}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deletePresentationModal}
            toggle={openDeletePresentationModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeletePresentationModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeletePresentationModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemPresentation}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEditPublication && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editPublicationModal}
              toggle={openEditPublicationModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Publication
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditPublicationModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <Form>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="title">Title</Label>
                        <Input
                          type="text"
                          id="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup>
                        <Label for="url">URL</Label>
                        <Input
                          type="url"
                          id="url"
                          value={publicationURL}
                          onChange={(e) => setPublicationURL(e.target.value)}
                          required
                          onBlur={validatePublicationURL}
                        />
                        {errors.url && (
                          <div style={{ color: "red" }}>{errors.url}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="publishedOn">Published On</Label>
                        <div style={{ display: "flex" }}>
                          <Input
                            type="select"
                            id="year"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            required
                            style={{ marginRight: "10px" }}
                          >
                            <option value="">--Select Year--</option>
                            {yearOptions.map((y) => (
                              <option key={y} value={y}>
                                {y}
                              </option>
                            ))}
                          </Input>
                          <Input
                            type="select"
                            id="month"
                            value={month}
                            onChange={(e) => setMonth(e.target.value)}
                            required
                          >
                            <option value="">--Select Month--</option>
                            {months.map((m) => (
                              <option key={m.value} value={m.value}>
                                {m.label}
                              </option>
                            ))}
                          </Input>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Label for="description">Description</Label>
                        <Input
                          rows={5}
                          type="textarea"
                          id="description"
                          value={publicationdescription}
                          onChange={handlePublicationDescpChange}
                          required
                        />
                        <small>
                          {characterCountWorkSample} Character(s) Left
                        </small>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditPublicationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updatePublication}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deletePublicationModal}
            toggle={openDeletePublicationModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeletePublicationModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeletePublicationModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItemPublication}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Accomplishments;
