import axios from "axios";
import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

const softwareNames = [
  "Adobe Photoshop",
  "Microsoft Word",
  "Visual Studio Code",
  "Eclipse",
  // add more software names here
];

const currentYear = 30;
const yearOptions = [];

for (let year = 0; year <= currentYear; year++) {
  yearOptions.push(year.toString());
}

const currentYear1 = new Date().getFullYear();
const yearOptions1 = [];

for (let year = 1950; year <= currentYear1; year++) {
  yearOptions1.push(year.toString());
}
const monthOptions = [
  "",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
];
var ID, USERID;
const Skills = () => {
  const [skillsModal, setSkillsModal] = useState(false);

  const openSkillsModal = () => {
    setSkillsModal(!skillsModal);
    setSoftwareName("");
    setSoftwareVersion("");
    setLastUsedYear("");
    setExperienceYear("");
    setExperienceMonth("");
  };

  const [softwareName, setSoftwareName] = useState("");
  const [softwareVersion, setSoftwareVersion] = useState("");
  const [lastUsedYear, setLastUsedYear] = useState("");
  const [experienceYear, setExperienceYear] = useState("");
  const [experienceMonth, setExperienceMonth] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [skills, setSkills] = useState([]);

  const [editSkillsModal, setEditSkillsModal] = useState(false);
  const openEditSkillsModal = () => setEditSkillsModal(!editSkillsModal);

  const [delId, setDelId] = useState("");
  const [deleteSkillsModal, setDeleteSkillsModal] = useState(false);
  const openDeleteSkillsModal = () => setDeleteSkillsModal(!deleteSkillsModal);

  const [userId, setUserId] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/userskills/${userId}/`)
      .then((res) => {
        setSkills(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const handleSoftwareNameChange = (event, { newValue }) => {
    setSoftwareName(newValue);
  };

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : softwareNames.filter(
          (name) => name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const inputProps = {
    placeholder: "Enter software name",
    value: softwareName,
    onChange: handleSoftwareNameChange,
  };

  async function handleSubmit(event) {
    event.preventDefault();

    async function storeData() {
      const formData = {
        user_account_id: userId,
        skill_name: softwareName,
        software_version: softwareVersion,
        last_used: lastUsedYear,
        experience_year: experienceYear,
        experience_month: experienceMonth,
      };

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.post(`${subURL}/userskills/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setSkillsModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/userskills/${userId}/`);

              setSkills(res.data);
            } catch (error) {
              console.log(error);
            } finally {
              setSoftwareName("");
              setSoftwareVersion("");
              setLastUsedYear("");
              setExperienceYear("");
              setExperienceMonth("");
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setSkillsModal(true);
        console.log(e);
      }
    }

    storeData();
  }
  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditSkillsModal(true);

    const filteredDummuyData = skills.find((data) => data.id === id);
    setSoftwareName(filteredDummuyData.skill_name);
    setSoftwareVersion(filteredDummuyData.software_version);
    setLastUsedYear(filteredDummuyData.last_used);
    setExperienceYear(filteredDummuyData.experience_year);
    setExperienceMonth(filteredDummuyData.experience_month);
  }

  async function updateHandler() {
    const formData = {
      user_account_id: userId,
      skill_name: softwareName,
      software_version: softwareVersion,
      last_used: lastUsedYear,
      experience_year: experienceYear,
      experience_month: experienceMonth,
    };
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(`${subURL}/userskills/${ID}/`, formData, {
        headers: headers,
      });

      if (res.status === 200) {
        setModalTitle("Success");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setEditSkillsModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/userskills/${userId}/`);

            setSkills(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditSkillsModal(false);
      console.log(e);
    }
  }

  function handleDelete(id) {
    setDeleteSkillsModal(true);
    setDelId(id);
  }

  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/userskills/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteSkillsModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/userskills/${userId}/`);

            setSkills(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setDeleteSkillsModal(false);
      console.log(error);
    }
  }
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>IT Skills</h5>
        <div className="button-container">
          <button
            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
            type="submit"
            onClick={openSkillsModal}
            style={{ marginRight: "25px" }}
          >
            Add
          </button>
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <div className="desc">
          {skills.length > 0 ? (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ fontWeight: "500" }}>#</th>
                  <th style={{ fontWeight: "500" }}>Skill Name</th>
                  <th style={{ fontWeight: "500" }}>Software Version</th>
                  <th style={{ fontWeight: "500" }}>Last used</th>
                  <th style={{ fontWeight: "500" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {skills.map((skill, key) => (
                  <tr key={skill.id}>
                    <td>{key + 1}</td>
                    <td>{skill.skill_name}</td>
                    <td>{skill.software_version}</td>
                    <td>{skill.last_used}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "2em",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="green"
                        onClick={() => handleEdit(skill.id)}
                        style={{ cursor: "pointer" }}
                      />

                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => handleDelete(skill.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ListGroupItem>
              <p className="text-center fs-16 mt-13">No skills found.</p>
            </ListGroupItem>
          )}
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={skillsModal} toggle={openSkillsModal} centered>
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Skills
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openSkillsModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className=" p-3">
                <FormGroup>
                  <Label for="softwareName">Software Name</Label>
                  <Autosuggest
                    suggestions={getSuggestions(softwareName)}
                    onSuggestionsClearRequested={() => {}}
                    onSuggestionsFetchRequested={() => {}}
                    getSuggestionValue={(suggestion) => suggestion}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                  />
                </FormGroup>
              </div>

              <Row className=" p-3">
                <Col>
                  <FormGroup>
                    <Label for="softwareversion">Software Version</Label>
                    <Input
                      type="number"
                      name="softwareversion"
                      id="softwareversion"
                      value={softwareVersion}
                      onChange={(e) => {
                        const enteredValue = parseFloat(e.target.value);
                        if (!isNaN(enteredValue) && enteredValue >= 0) {
                          setSoftwareVersion(enteredValue);
                        }
                      }}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="ml-3 pl-3">
                    <Label for="lastUsed">Last Used</Label>
                    <Input
                      type="select"
                      name="lastUsed"
                      id="lastUsed"
                      value={lastUsedYear}
                      onChange={(e) => setLastUsedYear(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Select year
                      </option>
                      {yearOptions1.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row className=" p-3">
                <Col>
                  <Label for="experienceYear">Experience(in years)</Label>
                  <Input
                    type="select"
                    name="experienceYear"
                    id="experienceYear"
                    className="mr-2"
                    value={experienceYear}
                    onChange={(e) => setExperienceYear(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Years
                    </option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Input>
                </Col>
                <Col>
                  <Label for="experienceMonth">Experience(in Months)</Label>
                  <Input
                    type="select"
                    name="experienceMonth"
                    id="experienceMonth"
                    value={experienceMonth}
                    onChange={(e) => setExperienceMonth(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Months
                    </option>
                    {monthOptions.map((month) => (
                      <option key={month} value={month}>
                        {month}
                      </option>
                    ))}
                  </Input>
                </Col>
              </Row>

              <div className="p-3">
                <button
                  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteSkillsModal}
            toggle={openDeleteSkillsModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteSkillsModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteSkillsModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItem}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editSkillsModal}
              toggle={openEditSkillsModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Skills
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditSkillsModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className=" p-3">
                  <FormGroup>
                    <Label for="softwareName">Software Name</Label>
                    <Autosuggest
                      suggestions={getSuggestions(softwareName)}
                      onSuggestionsClearRequested={() => {}}
                      onSuggestionsFetchRequested={() => {}}
                      getSuggestionValue={(suggestion) => suggestion}
                      renderSuggestion={renderSuggestion}
                      inputProps={inputProps}
                    />
                  </FormGroup>
                </div>

                <Row className=" p-3">
                  <Col>
                    <FormGroup>
                      <Label for="softwareversion">Software Version</Label>
                      <Input
                        type="text"
                        name="softwareversion"
                        id="softwareversion"
                        value={softwareVersion}
                        onChange={(e) => setSoftwareVersion(e.target.value)}
                      ></Input>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="ml-3 pl-3">
                      <Label for="lastUsed">Last Used</Label>
                      <Input
                        type="select"
                        name="lastUsed"
                        id="lastUsed"
                        value={lastUsedYear}
                        onChange={(e) => setLastUsedYear(e.target.value)}
                      >
                        <option value="" disabled selected hidden>
                          Select year
                        </option>
                        {yearOptions1.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className=" p-3">
                  <Col>
                    <Label for="experienceYear">Experience(in years)</Label>
                    <Input
                      type="select"
                      name="experienceYear"
                      id="experienceYear"
                      className="mr-2"
                      value={experienceYear}
                      onChange={(e) => setExperienceYear(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Years
                      </option>
                      {yearOptions.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </Col>
                  <Col>
                    <Label for="experienceMonth">Experience(in Months)</Label>
                    <Input
                      type="select"
                      name="experienceMonth"
                      id="experienceMonth"
                      value={experienceMonth}
                      onChange={(e) => setExperienceMonth(e.target.value)}
                    >
                      <option value="" disabled selected hidden>
                        Months
                      </option>
                      {monthOptions.map((month) => (
                        <option key={month} value={month}>
                          {month}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </Row>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditSkillsModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Success</ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Skills;
