import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import axios from "axios";
import { subURL } from "../../../utils/URL's";
import { useHistory } from "react-router-dom";

import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";
import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
var USERID, USERTYPEID;
const EmployerAddCompany = () => {
  const history = useHistory();
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyHQ, setCompanyHQ] = useState("");
  const [profileDescription, setProfileDescription] = useState("");
  const [businessStream, setBusinessStream] = useState("");

  const [companyWebsite, setCompanyWebsite] = useState("");
  const [establishmentDate, setEstablishmentDate] = useState("");

  const [filename, setFilename] = useState("");
  const [businessStreamOptions, setBusinessStreamOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [userId, setUserId] = useState("");
  const [userTypeId, setUserTypeId] = useState("");

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  async function getUserTypeID() {
    try {
      USERTYPEID = await localStorage.getItem("usertypeid");

      if (USERTYPEID !== null) {
        setUserTypeId(USERTYPEID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserTypeID();

  useEffect(() => {
    getUserTypeID();
  }, []);

  useEffect(() => {
    const fetchBusinessStreamOptions = async () => {
      const response = await fetch(`${subURL}/business_stream/`);
      const data = await response.json();

      setBusinessStreamOptions(data);
    };

    fetchBusinessStreamOptions();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    async function storeData() {
      const formData = new FormData();
      formData.append("company_name", companyName);
      formData.append("profile_description", profileDescription);
      formData.append("business_stream_id", businessStream);
      formData.append("establishment_date", establishmentDate);
      formData.append("company_website_url", companyWebsite);
      formData.append("company_image", filename);

      formData.append("company_type", companyType);
      formData.append("company_size", companySize);
      formData.append("company_location", companyHQ);
      formData.append("user_account_id", userId);
      //formData.append("user_type_id", userTypeId);

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.post(
          `${subURL}/company_save_details/`,
          formData,
          { headers: headers }
        );

        if (res.status === 201) {
          setModalTitle("Success!");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setTimeout(() => {
            history.push("/viewcompanyasemployer");
          }, 1000);
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
        console.log(e);
      }
    }

    storeData();
    setCompanyName("");
    setProfileDescription("");
    setBusinessStream("");
    setEstablishmentDate("");
    setCompanyWebsite("");
    setFilename("");
  };
  return (
    <>
      <div className="site-main">
        <EmployerHeader />

        <EmployerPageHeader
          title="Add Company"
          breadcrumb="company"
          className="pb-65 pb-lg-0"
        />
        <section className="ttm-row form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      fill the{" "}
                      <span className="text-theme-SkinColor"> details</span>
                    </h3>
                    <h2 className="title">Post Company Details !</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container>
            <Row>
              <Col lg={12}>
                <div className="bg-soft-primary p-3">
                  <h5 className="mb-0 fs-17">Add Details!</h5>
                </div>
              </Col>
            </Row>
            <form
              action="#"
              className="job-post-form shadow mt-4"
              onSubmit={handleSubmit}
            >
              <div className="job-post-content box-shadow-md rounded-3 p-4">
                <Row className="row">
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label htmlFor="jobtitle" className="form-label">
                        Company Name
                      </Label>
                      <Input
                        type="text"
                        name="companyName"
                        id="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        required
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <label htmlFor="businessstream" className="form-label">
                        Business Stream
                      </label>
                      <Input
                        required
                        type="select"
                        name="businessStream"
                        id="businessStream"
                        value={businessStream}
                        onChange={(e) => setBusinessStream(e.target.value)}
                      >
                        <option value="">Select Business Stream</option>
                        {businessStreamOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.business_stream_name
                              .charAt(0)
                              .toUpperCase() +
                              option.business_stream_name.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="companyWebsite">Company Website URL</Label>
                      <Input
                        required
                        type="url"
                        name="companyWebsite"
                        id="companyWebsite"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                      />
                    </div>
                  </Col>

                  {/* <Col lg={6}>
                  <div className="mb-4">
                    <Label for="establishmentDate">Establishment Date</Label>
                    <Input
                      type="date"
                      name="establishmentDate"
                      id="establishmentDate"
                      value={establishmentDate}
                      onChange={(e) => setEstablishmentDate(e.target.value)}
                    />
                  </div>
                </Col> */}
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="establishmentDate">Establishment Date</Label>
                      <Input
                        required
                        type="date"
                        name="establishmentDate"
                        id="establishmentDate"
                        value={establishmentDate}
                        max={new Date().toISOString().split("T")[0]} // Set the maximum date to today's date
                        min={
                          new Date(
                            new Date().setMonth(new Date().getMonth() - 6)
                          )
                            .toISOString()
                            .split("T")[0]
                        } // Set the minimum date to 6 months ago
                        onChange={(e) => {
                          const selectedDate = new Date(e.target.value);
                          const sixMonthsAgo = new Date(
                            new Date().setMonth(new Date().getMonth() - 6)
                          );
                          if (
                            selectedDate > new Date() ||
                            selectedDate < sixMonthsAgo
                          ) {
                            alert(
                              "Please select a date between today and the last 6 months."
                            );
                            setEstablishmentDate(""); // Clear the input field if the date is invalid
                          } else {
                            setEstablishmentDate(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label htmlFor="companyType" className="form-label">
                        Company Type
                      </Label>
                      <Input
                        type="text"
                        name="companyType"
                        id="companyType"
                        value={companyType}
                        onChange={(e) => setCompanyType(e.target.value)}
                        required
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label htmlFor="companySize" className="form-label">
                        Company Size
                      </Label>
                      <Input
                        type="text"
                        name="companySize"
                        id="companySize"
                        value={companySize}
                        onChange={(e) => setCompanySize(e.target.value)}
                        required
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="companyImage">Company Logo</Label>
                      <Input
                        type="file"
                        name="companyImage"
                        id="companyImage"
                        accept=".png, .jpg, .jpeg"
                        onChange={(e) => setFilename(e.target.files[0])}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label htmlFor="companyHQ" className="form-label">
                        Company Address
                      </Label>
                      <Input
                        type="textarea"
                        name="companyHQ"
                        id="companyHQ"
                        value={companyHQ}
                        onChange={(e) => setCompanyHQ(e.target.value)}
                        required
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-4">
                      <Label htmlFor="jobdescription" className="form-label">
                        Profile Description
                      </Label>
                      <Input
                        rows={5}
                        required
                        type="textarea"
                        name="profileDescription"
                        id="profileDescription"
                        value={profileDescription}
                        onChange={(e) => setProfileDescription(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                {modalTitle}
              </ModalHeader>
              <ModalBody>{successMessage}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => setShowModal(false)}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </section>
        <EmployerFooter />
      </div>
    </>
  );
};

export default EmployerAddCompany;
