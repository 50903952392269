import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

const EmployerNavBar = () => {
  const isActive = (route) => {
    return window.location.pathname === route;
  };
  return (
    <Router>
      <nav className="main-menu">
        <ul className="menu">
          <li
            className={`mega-menu-item ${
              isActive("/employer") ? "active" : ""
            }`}
          >
            <a href="/employer" className="mega-menu-link">
              Home
            </a>
          </li>

          <li
            className={`mega-menu-item with-submenu ${
              isActive("/postjobasemployer") || isActive("/viewemployerJobs")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Jobs
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/postjobasemployer">Post a Job</a>
              </li>

              <li>
                <a href="/viewemployerJobs">Job List</a>
              </li>
            </ul>
          </li>
          <li
            className={`mega-menu-item with-submenu ${
              isActive("/addcompanyasemployer") ||
              isActive("/viewcompanyasemployer")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Company
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/addcompanyasemployer">Add Company </a>
              </li>
              <li>
                <a href="/viewcompanyasemployer">Company Details</a>
              </li>
            </ul>
          </li>

          <li
            className={`mega-menu-item ${
              isActive("/employercandidatelist") ? "active" : ""
            }`}
          >
            <a href="/employercandidatelist" className="mega-menu-link">
              Candidate List
            </a>
          </li>

          <li
            className={`mega-menu-item with-submenu ${
              isActive("/addskillsasemployer") ||
              isActive("/addbusinessstreamasemployer") ||
              isActive("/addjobcategoryasemployer")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Add Data
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/addskillsasemployer">Add Skills</a>
              </li>
              <li>
                <a href="/addbusinessstreamasemployer">Add Business Stream</a>
              </li>
              <li>
                <a href="/addjobcategoryasemployer">Add Job Category</a>
              </li>
            </ul>
          </li>
        </ul>
        <style>
          {`
            .main-menu .mega-menu-item > .mega-menu-link::after {
              display: none;
            }
            
            .main-menu .mega-menu-item > .mega-menu-link + .mega-submenu {
              display: none;
            }
            
            .main-menu .mega-menu-item.with-submenu > .mega-menu-link::after {
              display: inline-block;
              content: "▼" 
              content: "\u2304"/* Dropdown arrow Unicode character */
            }
            
            .main-menu .mega-menu-item.with-submenu > .mega-menu-link + .mega-submenu {
              display: block;
            }
            .main-menu .mega-menu-item.active a {
              color: orange;
            }
            
            `}
        </style>
      </nav>
    </Router>
  );
};

export default EmployerNavBar;
