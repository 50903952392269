import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { imageURL, mainURL, subURL } from "../../utils/URL's";
import axios from "axios";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
var USERID, ID;
const Aboutme = () => {
  const [showSection, setShowSection] = useState(true);
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [resume, setResume] = useState("");
  const [joinType, setJoinType] = useState("");
  const [profilePhoto, setProfilePhoto] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [personalDetails, setPersonalDetails] = useState([]);
  const [existingImage, setExistingImage] = useState("");

  const [editPersonalInfoModal, setEditPersonalInfoModal] = useState(false);
  const openEditPersonalInfoModal = () =>
    setEditPersonalInfoModal(!editPersonalInfoModal);

  const [experience, setExperience] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [isEdit, setIsEdit] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleSection = () => {
    setShowSection(!showSection);
  };
  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    const fetchExpTypeOptions = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchExpTypeOptions();
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/user_save_account/${userId}/`)
      .then((res) => {
        setPersonalDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  function firstNameChangeHandler(event) {
    setFirstName(event.target.value);
  }
  function lastNameChangeHandler(event) {
    setLastName(event.target.value);
  }

  const validatePassword = (password) => {
    return password.length >= 6; // Check if password has at least 6 characters
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleExperienceLevelChange = (event) => {
    setExperienceLevel(event.target.value);
  };

  // const handleResumeChange = (event) => {
  //   const file = event.target.files[0];
  //   const allowedExtensions = /(\.pdf)$/i;
  //   const allowedTypes = ["application/pdf"];

  //   if (
  //     !allowedExtensions.test(file.name) ||
  //     !allowedTypes.includes(file.type)
  //   ) {
  //     setModalTitle("Failed!");
  //     setSuccessMessage("Invalid file format. Only PDF files are  allowed.");
  //     setShowModal(true);
  //   } else {
  //     setResume(event.target.files[0]);
  //   }
  // };

  const handleResumeChange = (event) => {
    setResume(event.target.files[0]);
  };

  const handleJoinTypeChange = (event) => {
    setJoinType(event.target.value);
  };

  const handlePhoneNoChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setEnteredPhone(inputPhone);
  };

  async function updateHandler(event) {
    event.preventDefault();
    if (!isValidPassword) {
      alert("Password should be at least 6 characters long.");
      return;
    }
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (enteredPhone.length < 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }
    async function storeData() {
      const formData = new FormData();
      formData.append("user_type_id", 2);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("experience_level", experienceLevel);
      if (resume) {
        formData.append("resume", resume);
      }

      formData.append("join_type", joinType);
      formData.append("isactive", true);
      if (profilePhoto) {
        formData.append("profile_photo", profilePhoto);
      }

      formData.append("phone_number", enteredPhone);
      formData.append("email_address", email);
      formData.append("password", password);

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.put(
          `${subURL}/user_save_account/${userId}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success");
          setSuccessMessage("Updated Successfully !");
          setShowModal(true);
          setEditPersonalInfoModal(false);
          let existingValue = localStorage.getItem("username");

          // Update the value if it exists
          if (existingValue !== null) {
            // Update with res.data.first_name if it is not undefined
            if (firstName || personalDetails?.first_name !== undefined) {
              existingValue = firstName || personalDetails?.first_name;
              localStorage.removeItem("username");
              localStorage.setItem("username", existingValue);
            }
          }
        } else {
          console.log("error");
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Email already Exists!");
          setShowModal(true);
          setEditPersonalInfoModal(false);
        } else if (e.response.status === 403) {
          setModalTitle("Invalid format !");
          setSuccessMessage("Please upload valid valid profile photo!");
          setShowModal(true);
          setEditPersonalInfoModal(false);
        } else if (e.response.status === 406) {
          setModalTitle("Invalid format !");
          setSuccessMessage("Please upload valid profile photo!");
          setShowModal(true);
          setEditPersonalInfoModal(false);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops! Something went wrong, please try again");
          setShowModal(true);
          setEditPersonalInfoModal(false);
        }
        console.log(e);
      }
    }

    storeData();
  }

  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditPersonalInfoModal(true);

    const filteredDummuyData = personalDetails.find((data) => data.id === id);
    setFirstName(filteredDummuyData.first_name);
    setLastName(filteredDummuyData.last_name);
    setExperienceLevel(filteredDummuyData.experience_level.id);
    setResume(filteredDummuyData.resume);
    setJoinType(filteredDummuyData.join_type.id);
    setExistingImage(filteredDummuyData.profile_photo);
    setEnteredPhone(filteredDummuyData.phone_number);

    setEmail(filteredDummuyData.email_address);
    setPassword(filteredDummuyData.password);
  }

  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Personal Profile</h5>
        <FontAwesomeIcon
          icon={showSection ? faEye : faEyeSlash}
          color="black"
          onClick={toggleSection}
          style={{ cursor: "pointer" }}
        />
      </div>

      {showSection && (
        <>
          {personalDetails.length > 0 ? (
            <div className="desc">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>Photo</th>
                    <th style={{ fontWeight: "500" }}>First Name</th>
                    <th style={{ fontWeight: "500" }}> Last Name</th>

                    <th style={{ fontWeight: "500" }}>Email</th>
                    <th style={{ fontWeight: "500" }}>Phone</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {personalDetails.map((personal, key) => (
                    <tr key={personal?.id}>
                      <td
                        className="photo-cell"
                        style={{
                          position: "relative",
                          maxWidth: "150px", // Adjust the maximum width as needed
                        }}
                      >
                        <div
                          className="photo-wrapper"
                          style={{
                            position: "relative",
                            paddingTop: "100%", // Maintain aspect ratio (height = width)
                            overflow: "hidden",
                          }}
                        >
                          <img
                            src={`${imageURL}${personal.profile_photo}`}
                            alt="Company Logo"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "auto",
                            }}
                          />
                        </div>
                      </td>
                      <td>{personal?.first_name}</td>
                      <td>{personal?.last_name}</td>

                      <td>{personal?.email_address}</td>
                      <td>{personal?.phone_number}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() => handleEdit(personal.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <ListGroupItem>
              <p> No details found...</p>
            </ListGroupItem>
          )}
        </>
      )}

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editPersonalInfoModal}
              toggle={openEditPersonalInfoModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Details
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditPersonalInfoModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className=" p-3">
                  <Label for="education">First Name</Label>
                  <Input
                    required
                    type="text"
                    name="education"
                    id="education"
                    placeholder="Education"
                    value={firstName}
                    onChange={firstNameChangeHandler}
                  />
                </div>
                <div className="p-3">
                  <Label for="university">Last Name</Label>
                  <Input
                    required
                    type="text"
                    name="university"
                    id="university"
                    value={lastName}
                    onChange={lastNameChangeHandler}
                  />
                </div>
                <div className="p-3">
                  <Label for="course">Email</Label>
                  <Input
                    required
                    type="email"
                    name="course"
                    id="course"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="p-3">
                  <Label for="specialization">Password</Label>
                  <InputGroup>
                    <Input
                      type={showPassword ? "text" : "password"}
                      minLength={6}
                      className="form-control"
                      id="passwordInput"
                      placeholder="Enter your password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputGroupText
                      onClick={togglePasswordVisibility}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </InputGroupText>
                  </InputGroup>
                </div>

                <div className="p-3">
                  <Label for="txtphone">Phone Number</Label>
                  <Input
                    maxLength={10}
                    type="tel"
                    id="txtphone"
                    placeholder="Phone Number"
                    value={enteredPhone}
                    onChange={handlePhoneNoChange}
                  />
                </div>

                <Col lg={12}>
                  <div className="mb-4">
                    <label htmlFor="newsImage" className="form-label">
                      Profile photo
                    </label>
                    {existingImage && (
                      <img
                        src={`${imageURL}${existingImage}`}
                        alt="img"
                        className="img-fluid rounded-3"
                        style={{ width: "10em", height: "10em", margin: "1em" }}
                      />
                    )}
                    <input
                      type="file"
                      name="profile_photo"
                      id="newsImage"
                      accept=".png, .jpg, .jpeg"
                      onChange={(e) => setProfilePhoto(e.target.files[0])}
                    />
                  </div>
                </Col>

                <div className="p-3">
                  <Label for="startYear">Experience Level</Label>
                  <Input
                    type="select"
                    name="startYear"
                    id="startYear"
                    value={experienceLevel}
                    onChange={handleExperienceLevelChange}
                  >
                    {experience.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.experince_type.charAt(0).toUpperCase() +
                          option.experince_type.slice(1)}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="p-3">
                  <Label for="startYear">Join Type</Label>
                  <Input
                    type="select"
                    name="startYear"
                    id="startYear"
                    value={joinType}
                    onChange={handleJoinTypeChange}
                  >
                    <option value="" disabled selected hidden>
                      Join Type
                    </option>
                    {jobTypeOptions.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.job_type.charAt(0).toUpperCase() +
                          option.job_type.slice(1)}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="p-3">
                  <Label for="endYear">Upload CV</Label>
                  <Input
                    type="file"
                    name="endYear"
                    id="endYear"
                    accept=".pdf,.doc,.docx"
                    onChange={handleResumeChange}
                  />
                  {/* <p>
                    Already uploaded CV :{" "}
                    {personalDetails?.resume?.substring(
                      personalDetails?.resume?.lastIndexOf("/") + 1
                    )}
                  </p> */}
                </div>

                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditPersonalInfoModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Aboutme;
