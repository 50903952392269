import React, { useEffect, useState } from "react";

import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminPageHeader from "../../../components/layout/Admin/AdminPageHeader";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import axios from "axios";
import { mainURL, subURL } from "../../../utils/URL's";
import Navigation from "../../../components/layout/Navigation";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

var USERID;
const ViewAllCandidatesAdmin = () => {
  const [jobData, setJobData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [companyNameOrJobTitle, setCompanyNameOrJobTitle] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5; // Change this to the number of items to display per page

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        // Handle the case where the start index is beyond the filtered data length
        setFilteredJobs([]);
      }
    } else {
      const slicedData = jobData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  const [showViewMoreBtn, setShowViewMore] = useState(false);
  const [filteredJobsTrue, setFilteredJobsTrue] = useState(false);

  const [userId, setUserId] = useState("");

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && jobData.length > 0) {
      const slicedData = jobData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, jobData, itemsPerPage]);

  useEffect(() => {
    axios
      .get(`${subURL}/employmentdetails`)
      .then((response) => {
        setJobData(response.data);

        const initialTotalPages = Math.ceil(
          response.data.length / itemsPerPage
        );
        setTotalPages(initialTotalPages);
        setFilteredJobs(response.data.slice(0, itemsPerPage));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.get(
      `${subURL}/filterCandidate/${companyNameOrJobTitle}/`
    );

    const filteredData = response.data;

    if (filteredData.length === 0) {
      setFilteredJobs([]);
      setJobData([]);
    }

    const filteredTotalPages = Math.ceil(filteredData.length / itemsPerPage);

    setTotalPages(filteredTotalPages);
    setFilteredData(filteredData);
    setFilteredJobs(filteredData.slice(0, itemsPerPage));
    setCurrentPage(1);
    setFilteredJobsTrue(true);
    setShowViewMore(true);
    setCompanyNameOrJobTitle("");
  };

  const resetJobs = async () => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    const res = await axios.get(`${subURL}/employmentdetails`, {
      headers: headers,
    });

    const allJobs = res.data;
    const initialTotalPages = Math.ceil(allJobs.length / itemsPerPage);

    setJobData(allJobs);
    setTotalPages(initialTotalPages);
    setCurrentPage(1);
    setFilteredData(allJobs);
    setFilteredJobs(allJobs.slice(0, itemsPerPage));
    setFilteredJobsTrue(false);
    setShowViewMore(false);
  };
  return (
    <div className="site-main">
      <AdminHeader />

      <AdminPageHeader
        title="job list"
        breadcrumb="job"
        className="pb-65 pb-lg-0"
      />

      <div className="form-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-30 p-lg-20 mt_60 mt-xl-50">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <Form
                    onSubmit={handleFormSubmit}
                    className="d-flex align-items-end"
                  >
                    <Container>
                      <Row className="filter">
                        <Col>
                          <FormGroup className="me-2 mt-3 mb-0">
                            <Label
                              for="companyNameOrJobTitle"
                              className="visually-hidden"
                            >
                              Company Name or Job Title
                            </Label>
                            <Input
                              type="text"
                              name="companyNameOrJobTitle"
                              id="companyNameOrJobTitle"
                              placeholder="Search by Keyword. Ex: HR"
                              value={companyNameOrJobTitle}
                              onChange={(e) =>
                                setCompanyNameOrJobTitle(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col className="me-2 mt-13 mb-0">
                          <button
                            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                            type="submit"
                            style={{
                              marginTop: "1em",
                            }}
                            onClick={handleFormSubmit}
                          >
                            Search
                          </button>
                        </Col>
                        {filteredJobsTrue && (
                          <Col className="me-2 mt-13 mb-0">
                            <button
                              className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                              //  color="primary"
                              type="submit"
                              style={{
                                marginTop: "1em",
                              }}
                              onClick={resetJobs}
                            >
                              Reset
                            </button>
                          </Col>
                        )}
                        <style>
                          {`
                          @media only screen and (max-width: 991px) {      
                            .filter{
                              display:block
                            
                            }
                          `}
                        </style>
                      </Row>
                    </Container>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-12 content-area">
              {filteredJobs.length > 0 ? (
                filteredJobs.map((candidate) => (
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      {/* featured-imagebox */}
                      <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                        <div className="featured-thumbnail">
                          <img
                            alt="User"
                            src={
                              !showViewMoreBtn
                                ? `${candidate?.user_account_id.profile_photo}`
                                : `${mainURL}${candidate?.user_account_id.profile_photo}`
                            }
                          />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a
                                href={`/Candidate_details/${candidate.user_account_id.id}`}
                              >
                                {candidate.user_account_id.first_name}
                              </a>
                            </h3>
                          </div>
                          <div className="featured-bottom">
                            <div className="job-meta">
                              <span>Phone number:</span>
                              <span>
                                {candidate.user_account_id.phone_number}
                              </span>
                            </div>

                            <div className="job-meta">
                              <span>Email:</span>
                              <span>
                                {candidate.user_account_id.email_address}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h3 className="fs-18 mt-3 mb-5 text-center">No results found</h3>
              )}
            </div>
          </div>
        </div>

        {filteredJobs.length > 0 && (
          <Navigation
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <AdminFooter />

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ViewAllCandidatesAdmin;
