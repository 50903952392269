import React, { useEffect } from "react";

import { Tabs, TabPanel, TabList, Tab } from "react-tabs";
import { useState } from "react";
import axios from "axios";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { subURL } from "../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Footer from "../components/layout/Footer";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
function Register() {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [resume, setResume] = useState(null);
  const [joinType, setJoinType] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [userType, setUserType] = useState([]);

  const [experience, setExperience] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [loginOption, setLoginOption] = useState("");
  const [selectedTab, setSelectedTab] = useState(2);

  useEffect(() => {
    const userTypeOptions = async () => {
      const response = await fetch(`${subURL}/user_type/`);
      const data = await response.json();

      setUserType(data);
    };

    userTypeOptions();
  }, []);

  useEffect(() => {
    const fetchexpType = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchexpType();
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  function firstNameChangeHandler(event) {
    setFirstName(event.target.value);
  }
  function lastNameChangeHandler(event) {
    setLastName(event.target.value);
  }
  const handlePhoneNoChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setEnteredPhone(inputPhone);
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleExperienceLevelChange = (event) => {
    setExperienceLevel(event.target.value);
  };

  const handleResumeChange = (event) => {
    setResume(event.target.files[0]);
  };

  const handleJoinTypeChange = (event) => {
    setJoinType(event.target.value);
  };

  const handleProfilePhotoChange = (event) => {
    setProfilePhoto(event.target.files[0]);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!isValidPassword) {
      alert("Password should be at least 6 characters long.");
      return;
    }
    if (enteredPhone.length < 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }
    async function storeData() {
      const formData = new FormData();
      formData.append("user_type_id", selectedTab);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("experience_level", experienceLevel);
      formData.append("resume", resume);
      formData.append("join_type", joinType);
      formData.append("isactive", true);
      formData.append("profile_photo", profilePhoto);
      formData.append("phone_number", enteredPhone);
      formData.append("email_address", email);
      formData.append("password", password);

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.post(`${subURL}/user_save_account/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          setSuccessMessage("Registered Successfully !");
          setShowModal(true);
          setTimeout(() => {
            history.push("/login");
          }, 1500);
          setFirstName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setEnteredPhone("");
          setJoinType("");
          setExperienceLevel("");
          setResume(null);
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Email already Exists!");
          setShowModal(true);
        } else if (e.response.status === 403) {
          setModalTitle("Invalid format !");
          setSuccessMessage("Please upload valid profile photo!");
          setShowModal(true);
        } else if (e.response.status === 406) {
          setModalTitle("Invalid format !");
          setSuccessMessage("Please upload valid profile photo!");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops! Something went wrong, please try again");
          setShowModal(true);
        }
        console.log(e);
      }
    }

    storeData();
  }

  return (
    <div className="site-main">
      {/* <Header /> */}

      {/* PageHeader */}
      {/* <PageHeader title="register" breadcrumb="register" /> */}
      {/* PageHeader end */}

      {/* register */}
      <div className="ttm-row register-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <div className="text-center mb-20">
                    <h3>Create An Account</h3>
                  </div>
                  <div className="ttm-tabs ttm-tab-style-02">
                    <Tabs>
                      <TabList className="tabs">
                        <Tab className="tab" onClick={() => setSelectedTab(2)}>
                          <a>
                            <i className="flaticon flaticon-research"></i>
                            <span>Candidate</span>
                            <h5>Login as a Candidate</h5>
                          </a>
                        </Tab>
                        <Tab className="tab" onClick={() => setSelectedTab(3)}>
                          <a>
                            <i className="flaticon flaticon-job-search"></i>
                            <span>Employer</span>
                            <h5>Login as a Employer</h5>
                          </a>
                        </Tab>
                      </TabList>
                      <div className="content-tab">
                        <TabPanel>
                          <form
                            id="login_form"
                            className="login_form wrap-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              {/* <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <select
                                    required
                                    id="loginOption"
                                    value={loginOption}
                                    onChange={loginOptionChangeHandler}
                                  >
                                    <option value="">Register as :</option>
                                    <option value="2">User</option>
                                    <option value="3">Employer</option>
                                  </select>
                                </label>
                              </div> */}

                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>

                                  <input
                                    type="text"
                                    id="txtname"
                                    required
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={firstNameChangeHandler}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <input
                                    type="text"
                                    id="txtlastname"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={lastNameChangeHandler}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-email"></i>
                                  <input
                                    type="email"
                                    id="txtemail"
                                    required
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleChangeEmail}
                                  />
                                </label>
                              </div>
                              {/* <div className="col-lg-6">
                                <label>
                                  <InputGroup>
                                    <Input
                                      required
                                      type={showPassword ? "text" : "password"}
                                      minLength={6}
                                      className="form-control"
                                      id="passwordInput"
                                      placeholder="Enter your password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                    />
                                    <InputGroupText
                                      onClick={togglePasswordVisibility}
                                      style={{ cursor: "pointer" }}
                                      className="m-0"
                                    >
                                      <FontAwesomeIcon
                                        icon={showPassword ? faEye : faEyeSlash}
                                      />
                                    </InputGroupText>
                                  </InputGroup>
                                </label>

                                {!isValidPassword && (
                                  <div className="invalid-feedback">
                                    Password should be at least 6 characters
                                    long.
                                  </div>
                                )}
                              </div> */}

                              <div
                                className="col-lg-6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <i className="ti ti-lock"></i>
                                  <input
                                    required
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                  />
                                </label>
                                <i
                                  style={{
                                    position: "absolute",
                                    right: "50px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  className={`fa ${
                                    showPassword ? "fa-eye" : "fa-eye-slash"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                ></i>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-harddrives"></i>
                                  <select
                                    required
                                    id="experience_level"
                                    value={experienceLevel}
                                    onChange={handleExperienceLevelChange}
                                  >
                                    <option value="">Experience Level</option>
                                    {experience.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.experince_type
                                          .charAt(0)
                                          .toUpperCase() +
                                          option.experince_type.slice(1)}
                                      </option>
                                    ))}
                                  </select>
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-check-box"></i>
                                  <select
                                    id="join_type"
                                    value={joinType}
                                    onChange={handleJoinTypeChange}
                                  >
                                    <option value="">Join Type</option>
                                    {jobTypeOptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.job_type
                                          .charAt(0)
                                          .toUpperCase() +
                                          option.job_type.slice(1)}
                                      </option>
                                    ))}
                                  </select>
                                </label>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  {/* <i className="ti ti-upload"></i> */}
                                  <span className="placeholder-text">
                                    Upload profile photo
                                  </span>
                                  <input
                                    type="file"
                                    id="profile_photo"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleProfilePhotoChange}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  {/* <i className="ti ti-upload"></i> */}
                                  <span className="placeholder-text">
                                    Upload CV
                                  </span>
                                  <input
                                    type="file"
                                    id="resume"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleResumeChange}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-mobile"></i>
                                  <input
                                    required
                                    maxLength={10}
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    id="txtphone"
                                    placeholder="Phone Number"
                                    value={enteredPhone}
                                    onChange={handlePhoneNoChange}
                                  />
                                </label>
                                <p style={{ fontSize: "10px", margin: "1em" }}>
                                  Phone number should be 10 digits[0-9]
                                </p>
                              </div>
                              <div className="col-lg-12">
                                <label className="mt-0">
                                  <div className="d-md-flex align-items-center justify-content-between">
                                    <div className="mt-15">
                                      <p>
                                        Already registered?
                                        <a
                                          href="/login"
                                          className="text-theme-SkinColor"
                                        >
                                          {" "}
                                          Sign in here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-lg-12">
                                <label className="mb-0">
                                  <button
                                    className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                    type="submit"
                                  >
                                    Sign up
                                  </button>
                                </label>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form
                            id="login_form"
                            className="login_form wrap-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              {/* <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <select
                                    required
                                    id="loginOption"
                                    value={loginOption}
                                    onChange={loginOptionChangeHandler}
                                  >
                                    <option value="">Register as :</option>
                                    <option value="2">User</option>
                                    <option value="3">Employer</option>
                                  </select>
                                </label>
                              </div> */}
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>

                                  <input
                                    type="text"
                                    id="txtname"
                                    required
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={firstNameChangeHandler}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <input
                                    type="text"
                                    id="txtlastname"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={lastNameChangeHandler}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-email"></i>
                                  <input
                                    type="email"
                                    id="txtemail"
                                    required
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleChangeEmail}
                                  />
                                </label>
                              </div>
                              {/* <div className="col-lg-6">
                                <label>
                                  <InputGroup>
                                    <Input
                                      required
                                      type={showPassword ? "text" : "password"}
                                      minLength={6}
                                      className="form-control"
                                      id="passwordInput"
                                      placeholder="Enter your password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                    />
                                    <InputGroupText
                                      onClick={togglePasswordVisibility}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <FontAwesomeIcon
                                        icon={showPassword ? faEye : faEyeSlash}
                                      />
                                    </InputGroupText>
                                  </InputGroup>
                                </label>

                                {!isValidPassword && (
                                  <div className="invalid-feedback">
                                    Password should be at least 6 characters
                                    long.
                                  </div>
                                )}
                              </div> */}

<div
                                className="col-lg-6"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <label
                                  style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <i className="ti ti-lock"></i>
                                  <input
                                    required
                                    type={showPassword ? "text" : "password"}
                                    id="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                  />
                                </label>
                                <i
                                  style={{
                                    position: "absolute",
                                    right: "50px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  className={`fa ${
                                    showPassword ? "fa-eye" : "fa-eye-slash"
                                  }`}
                                  onClick={togglePasswordVisibility}
                                ></i>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-harddrives"></i>
                                  <select
                                    required
                                    id="experience_level"
                                    value={experienceLevel}
                                    onChange={handleExperienceLevelChange}
                                  >
                                    <option value="">Experience Level</option>
                                    {experience.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.experince_type
                                          .charAt(0)
                                          .toUpperCase() +
                                          option.experince_type.slice(1)}
                                      </option>
                                    ))}
                                  </select>
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-check-box"></i>
                                  <select
                                    id="join_type"
                                    value={joinType}
                                    onChange={handleJoinTypeChange}
                                  >
                                    <option value="">Join Type</option>
                                    {jobTypeOptions.map((option) => (
                                      <option key={option.id} value={option.id}>
                                        {option.job_type
                                          .charAt(0)
                                          .toUpperCase() +
                                          option.job_type.slice(1)}
                                      </option>
                                    ))}
                                  </select>
                                </label>
                              </div>

                              {/* <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-upload"></i>
                                  <label htmlFor="profile_photo">
                                    Upload profile photo
                                  </label>
                                  <input
                                    placeholder="Choose a file"
                                    type="file"
                                    id="profile_photo"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleProfilePhotoChange}
                                  />
                                </label>
                              </div> */}

                              <div className="col-lg-6">
                                <div className="file-input-container">
                                  <label
                                    htmlFor="profile_photo"
                                    className="file-input-label"
                                  >
                                    <span className="placeholder-text">
                                      Upload profile photo
                                    </span>
                                  </label>
                                  <input
                                    type="file"
                                    id="profile_photo"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleProfilePhotoChange}
                                    className="file-input"
                                  />
                                </div>
                              </div>

                              <div className="col-lg-6">
                                <label>
                                  <span className="placeholder-text">
                                    Upload CV
                                  </span>
                                  {/* <label
                                    htmlFor="resume"
                                    className="file-input-label"
                                  >
                                    Upload CV
                                  </label> */}
                                  <input
                                    type="file"
                                    id="resume"
                                    accept=".pdf,.doc,.docx"
                                    onChange={handleResumeChange}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-mobile"></i>
                                  <input
                                    required
                                    maxLength={10}
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    id="txtphone"
                                    placeholder="Phone Number"
                                    value={enteredPhone}
                                    onChange={handlePhoneNoChange}
                                  />
                                </label>
                                <p style={{ fontSize: "10px", margin: "1em" }}>
                                  Phone number should be 10 digits[0-9]
                                </p>
                              </div>
                              <div className="col-lg-12">
                                <label className="mt-0">
                                  <div className="d-md-flex align-items-center justify-content-between">
                                    <div className="mt-15">
                                      <p>
                                        Already registered?
                                        <a
                                          href="/login"
                                          className="text-theme-SkinColor"
                                        >
                                          {" "}
                                          Sign in here
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </label>
                              </div>
                              <div className="col-lg-12">
                                <label className="mb-0">
                                  <button
                                    className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                    type="submit"
                                  >
                                    Sign up
                                  </button>
                                </label>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                  <div className="login-social-buttons">
                    <div className="row">
                      <div className="col-md-6">
                        <button
                          id="login-with-facebook"
                          className="social-account-button w-100 facebook-button"
                        >
                          <i className="ti ti-facebook"></i>
                          <span>Login With facebook</span>
                        </button>
                      </div>
                      <div className="col-md-6">
                        <button
                          id="login-with-google"
                          className="social-account-button w-100 google-button"
                        >
                          <i className="ti ti-google"></i>
                          <span>Login With Google</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* login end */}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      {/* <Footer /> */}
    </div>
  );
}

export default Register;
