import React from "react";
import Navigation from "../../components/layout/Navigation";
import { useState } from "react";
import { useEffect } from "react";
import { imageURL, mainURL, subURL } from "../../utils/URL's";
import axios from "axios";
import { CardBody, Col, Row } from "reactstrap";

import Header from "../../components/layout/Header";
import PageHeader from "../../components/layout/PageHeader";
import Footer from "../../components/layout/Footer";
var USERID;
const MyJobs = () => {
  const [candidateData, setCandidateData] = useState([]);

  const [userImage, setUserImage] = useState(null);
  const [userId, setUserId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const itemsPerPage = 7; // Change this to the number of items to display per page
  const totalPages = Math.ceil(candidateData.length / itemsPerPage);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredData = candidateData.slice(startIndex, endIndex);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    async function fetechUserId() {
      const res = await axios.get(
        `${subURL}/applyjobfilteredTrueUseraccount/${userId}/True/`
      );

      setCandidateData(res.data);
      setUserImage(res.data[0]?.company_id?.companyimage);
    }
    fetechUserId();
  }, [userId]);

  return (
    <React.Fragment>
      <Header />

      <PageHeader
        title="My Jobs"
        breadcrumb="my jobs"
        className="pb-65 pb-lg-0"
      />
      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 content-area">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {candidateData.length === 0 ? (
                    <h5 className="fs-18 mt-3 mb-5 text-center">
                      No applied jobs found
                    </h5>
                  ) : (
                    <>
                      {filteredData.map((candidateDetailsNew, key) => (
                        <div
                          key={key}
                          className={
                            candidateDetailsNew.addclassNameBookmark === true
                              ? "candidate-list-box bookmark-post card "
                              : "candidate-list-box card mt-4"
                          }
                        >
                          <CardBody className="p-4">
                            <Row className="align-items-center">
                              <Col md={2}>
                                <div className="text-center mb-4 mb-md-0">
                                  <img
                                    src={`${imageURL}${candidateDetailsNew.job_post_id.company_id?.company_image}`}
                                    alt="compimg"
                                    className="img-fluid rounded-3"
                                  />
                                </div>
                              </Col>
                              <Col lg={5}>
                                <div className="candidate-list-content mt-3 mt-lg-0">
                                  <h6 className="fs-19 mb-0">
                                    {candidateDetailsNew.job_post_id.job_title}
                                  </h6>
                                  <p className="text-muted mb-2">
                                    {" "}
                                    {candidateDetailsNew.job_description}
                                  </p>
                                  <ul className="list-inline mb-0 text-muted">
                                    <li className="list-inline-item">
                                      {
                                        candidateDetailsNew.job_post_id
                                          .company_id.company_name
                                      }
                                    </li>
                                    <li className="list-inline-item">
                                      <i className="uil uil-wallet"></i>{" "}
                                      {
                                        candidateDetailsNew.job_post_id
                                          .job_type_id.job_type
                                      }
                                    </li>
                                  </ul>
                                </div>
                              </Col>

                              <Col lg={2}>
                                <span
                                  className="badge ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                  style={{
                                    backgroundColor:
                                      candidateDetailsNew.status === "accepted"
                                        ? "green"
                                        : candidateDetailsNew.status ===
                                          "rejected"
                                        ? "red"
                                        : "gray",
                                    fontSize: "11px",
                                    borderRadius: "0.3em",
                                  }}
                                >
                                  {candidateDetailsNew.status
                                    .charAt(0)
                                    .toUpperCase() +
                                    candidateDetailsNew.status.slice(1)}
                                </span>
                              </Col>

                              <Col lg={3}>
                                <span>
                                  Applied date:{" "}
                                  {candidateDetailsNew.apply_date?.substring(
                                    0,
                                    10
                                  )}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </div>
                      ))}
                    </>
                  )}
                  {candidateData.length > 0 && (
                    <Navigation
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default MyJobs;
