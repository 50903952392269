import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListGroup, ListGroupItem, Table } from "reactstrap";

import axios from "axios";
import { subURL } from "../../../../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const currentYear = new Date().getFullYear();
const yearOptions = [];

for (let year = 1950; year <= currentYear; year++) {
  yearOptions.push(year.toString());
}

const Accomplishments = () => {
  const { id } = useParams();

  const [profileData, setProfileData] = useState([]);

  const [certificationDataData, setcertificationDataData] = useState([]);

  const [workSmapleData, setWorkSampleData] = useState([]);

  const [patentData, setPatentData] = useState([]);

  const [presentationData, setPresentationData] = useState([]);

  const [publicationData, setPublicationData] = useState([]);

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  useEffect(() => {
    axios
      .get(`${subURL}/usersocialprofile/${id}/`)
      .then((res) => {
        setProfileData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${subURL}/usercertification/${id}/`)
      .then((res) => {
        setcertificationDataData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${subURL}/userworkstatus/${id}/`)
      .then((res) => {
        setWorkSampleData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${subURL}/patent/${id}/`)
      .then((res) => {
        setPatentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${subURL}/presentation/${id}/`)
      .then((res) => {
        setPresentationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  useEffect(() => {
    axios
      .get(`${subURL}/publications/${id}/`)
      .then((res) => {
        setPublicationData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Accomplishments</h5>
        <FontAwesomeIcon
          icon={showSection ? faEye : faEyeSlash}
          color="black"
          onClick={toggleSection}
          style={{ cursor: "pointer" }}
        />
      </div>

      {showSection && (
        <div className="desc">
          <ListGroup>
            {profileData.length > 0 ? (
              <>
                {profileData.map((profileData) => (
                  <ListGroupItem
                    key={profileData.id}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <a
                      href={profileData.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {profileData.social_profile}
                    </a>
                  </ListGroupItem>
                ))}
              </>
            ) : (
              <ListGroupItem>
                <p> No profiles found...</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Work Samples</h6>
            </ListGroupItem>

            {workSmapleData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Work Title</th>

                    <th style={{ fontWeight: "500" }}>Working Status</th>
                    <th style={{ fontWeight: "500" }}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {workSmapleData.map((workSampleData, key) => (
                    <tr key={workSampleData.id}>
                      <td>{key + 1}</td>
                      <td>{workSampleData.work_title}</td>

                      <td>
                        {workSampleData.working_staus === true ? "Yes" : "No"}
                      </td>
                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {workSampleData.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No data found</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>White Paper / Research Publication / Journal Entry</h6>
            </ListGroupItem>
            {publicationData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>

                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Published On</th>
                  </tr>
                </thead>
                <tbody>
                  {publicationData.map((publicationData, key) => (
                    <tr key={publicationData.id}>
                      <td>{key + 1}</td>
                      <td>{publicationData.title}</td>

                      <td>{publicationData.url}</td>
                      <td>
                        {publicationData.published_on_year}
                        {"-"}
                        {publicationData.published_on_month}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No data found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Presentation</h6>
            </ListGroupItem>

            {presentationData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>

                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {presentationData.map((presentationData, key) => (
                    <tr key={presentationData.id}>
                      <td>{key + 1}</td>
                      <td>{presentationData.presentation_title}</td>

                      <td style={{ wordWrap: "break-word", maxWidth: "200px" }}>
                        {presentationData.url}
                      </td>
                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {presentationData.description}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No presentation data found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Patent</h6>
            </ListGroupItem>

            {patentData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Title</th>

                    <th style={{ fontWeight: "500" }}>URL</th>
                    <th style={{ fontWeight: "500" }}>Patent Office</th>
                    <th style={{ fontWeight: "500" }}>Application Number</th>
                  </tr>
                </thead>
                <tbody>
                  {patentData.map((patentData, key) => (
                    <tr key={patentData.id}>
                      <td>{key + 1}</td>
                      <td>{patentData.title}</td>

                      <td>{patentData.url}</td>
                      <td>{patentData.patent_office}</td>
                      <td>{patentData.application_number}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No patent details found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}

      {showSection && (
        <div className="desc">
          <ListGroup>
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <h6>Certification</h6>
            </ListGroupItem>

            {certificationDataData.length > 0 ? (
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Certification Name</th>
                    <th style={{ fontWeight: "500" }}>
                      Certification Completion ID
                    </th>
                    <th style={{ fontWeight: "500" }}>Certification URL</th>
                  </tr>
                </thead>
                <tbody>
                  {certificationDataData.map((certificationData, key) => (
                    <tr key={certificationData.id}>
                      <td>{key + 1}</td>
                      <td>{certificationData.certification_name}</td>
                      <td>{certificationData.certification_completion_id}</td>
                      <td>{certificationData.certification_url}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <ListGroupItem>
                <p> No Certifications found.</p>
              </ListGroupItem>
            )}
          </ListGroup>
        </div>
      )}
    </div>
  );
};

export default Accomplishments;
