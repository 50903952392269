import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Navigation from "../../../components/layout/Navigation";
import { subURL } from "../../../utils/URL's";
import axios from "axios";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminPageHeader from "../../../components/layout/Admin/AdminPageHeader";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
var USERID, businessStreamID;
const AddBusinessStream = () => {
  const [businessStreamName, setBusinessStreamName] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [businessStreamOptions, setBusinessStreamOptions] = useState([]);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [modal, setModal] = useState(false);
  const [delId, setDelId] = useState("");

  const openModal = () => setModal(!modal);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10; // Change this to the number of items to display per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        // Handle the case where the start index is beyond the filtered data length
        setFilteredJobs([]);
      }
    } else {
      const slicedData = businessStreamOptions.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && businessStreamOptions.length > 0) {
      const slicedData = businessStreamOptions.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, businessStreamOptions, itemsPerPage]);
  const [userId, setUserId] = useState("");

  const [isEditBusinessStream, setIsEditBusinessStream] = useState(false);
  const [editBusinessStreamModal, setIsEditBusinessStreamModal] =
    useState(false);
  const openEditBusinessStreamModal = () =>
    setIsEditBusinessStreamModal(!editBusinessStreamModal);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/business_stream/`)
      .then((response) => {
        setBusinessStreamOptions(response.data);
        const initialTotalPages = Math.ceil(
          response.data.length / itemsPerPage
        );
        setTotalPages(initialTotalPages);
        setFilteredJobs(response.data.slice(0, itemsPerPage));
      })
      .catch((error) => {
        console.error("Error fetching skills:", error);
      });
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    async function storeData() {
      const formData = {
        business_stream_name: businessStreamName,
        user_account_id: userId,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/business_stream/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success!");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/business_stream/`);

              setBusinessStreamOptions(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Business Stream already Exists!");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
        }

        console.log(e);
      }
    }

    storeData();
    setBusinessStreamName("");
  };

  function handleEditBusinessStream(id) {
    businessStreamID = id;

    setIsEditBusinessStream(true);
    setIsEditBusinessStreamModal(true);

    const filteredDummuyData = businessStreamOptions.find(
      (data) => data.id === id
    );
    setBusinessStreamName(filteredDummuyData.business_stream_name);
  }

  async function updateBusinessStream() {
    const formData = {
      user_account_id: userId,
      business_stream_name: businessStreamName,
    };

    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(
        `${subURL}/business_stream/${businessStreamID}/`,
        formData,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        setModalTitle("Success");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setIsEditBusinessStreamModal(false);

        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/business_stream/${userId}/`);

            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setBusinessStreamOptions(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setIsEditBusinessStreamModal(false);
      console.log(e);
    }
    setIsEditBusinessStream(false);
    setBusinessStreamName("");
  }

  function handleDeleteBusinessStream(id) {
    setDelId(id);
    setModal(true);
  }
  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/business_stream/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/business_stream/`);

            // setFilteredJobs(res.data.slice(0, 3));
            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setBusinessStreamOptions(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed!");
      setSuccessMessage("Oops !Something went wrong,Please try again");
      setShowModal(true);
      console.log(error);
    }
  }
  return (
    <>
      <div className="site-main">
        <AdminHeader />
        <AdminPageHeader
          title="Add Business Stream"
          breadcrumb="business stream"
          className="pb-65 pb-lg-0"
        />

        <section className="ttm-row form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      fill the{" "}
                      <span className="text-theme-SkinColor"> details</span>
                    </h3>
                    <h2 className="title">Post Business Stream</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container>
            {!isEditBusinessStream && (
              <Row>
                <Col lg={12}>
                  <div className="bg-soft-primary p-3">
                    <h5 className="mb-0 fs-17">Add Details!</h5>
                  </div>
                </Col>
              </Row>
            )}
            {!isEditBusinessStream && (
              <form
                action="#"
                className="job-post-form shadow mt-4"
                onSubmit={handleSubmit}
              >
                <div className="job-post-content box-shadow-md rounded-3 p-4">
                  <Row className="row">
                    <Col lg={12}>
                      <div className="mb-4">
                        <Label htmlFor="bsname" className="form-label">
                          Business Stream Name
                        </Label>
                        <Input
                          type="text"
                          name="bsname"
                          id="bsname"
                          value={businessStreamName}
                          onChange={(e) =>
                            setBusinessStreamName(e.target.value)
                          }
                          required
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                          type="submit"
                          style={{
                            marginTop: "1em",
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </form>
            )}
            {/* {businessStreamOptions.length > 0 ? (
              <div className="mt-4">
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{fontWeight:'500'}}>Sl No</th>
                      <th style={{fontWeight:'500'}}> Business Stream Name</th>
                    </tr>
                  </thead>
                  <tbody> 
                    {filteredData.map((business, key) => (
                      <tr key={business.id}>
                        <td>{key + 1}</td>
                        <td>{business.business_stream_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <h5 className="fs-15 mt-4 mb-8 text-center">
                No Business stream found
              </h5>
            )} */}

            <div className="desc align-items-center">
              <ListGroup>
                <ListGroupItem className="d-flex justify-content-between align-items-center mt-35">
                  <h6 className="text-center">Business Stream</h6>
                </ListGroupItem>
                {filteredJobs.length > 0 ? (
                  <>
                    {filteredJobs.map((businessstream) => (
                      <ListGroupItem
                        key={businessstream.id}
                        className="d-flex justify-content-between align-items-center text-center pt-3"
                      >
                        <p>{businessstream.business_stream_name}</p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",

                            width: "7em",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            color="green"
                            onClick={() =>
                              handleEditBusinessStream(businessstream.id)
                            }
                            style={{ cursor: "pointer" }}
                          />

                          <FontAwesomeIcon
                            icon={faTrash}
                            color="red"
                            onClick={() =>
                              handleDeleteBusinessStream(businessstream.id)
                            }
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </ListGroupItem>
                    ))}
                  </>
                ) : (
                  <ListGroupItem>
                    <p> No business stream found...</p>
                  </ListGroupItem>
                )}
              </ListGroup>
            </div>
            <div className="sidebar job-sidebar clearfix">
              {filteredJobs.length > 0 && (
                <Navigation
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>

            {isEditBusinessStream && (
              <div
                className="modal fade"
                id="applyNow"
                tabIndex="-1"
                aria-labelledby="applyNow"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <Modal
                    isOpen={editBusinessStreamModal}
                    toggle={openEditBusinessStreamModal}
                    centered
                  >
                    <ModalBody className="modal-body">
                      <div className="text-center mb-4">
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Update Business Stream
                        </h5>
                      </div>
                      <div className="position-absolute end-0 top-0 p-3">
                        <button
                          type="button"
                          onClick={openEditBusinessStreamModal}
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <Form>
                        <Col lg={12}>
                          <div className="mb-4">
                            <Label htmlFor="jobtitle" className="form-label">
                              Business Stream Name
                            </Label>
                            <Input
                              type="text"
                              name="companyName"
                              id="companyName"
                              value={businessStreamName}
                              onChange={(e) =>
                                setBusinessStreamName(e.target.value)
                              }
                              required
                            />
                          </div>
                        </Col>
                      </Form>
                      <div className="p-3 d-flex justify-content-between">
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                          type="submit"
                          onClick={(e) => setIsEditBusinessStreamModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                          type="button"
                          onClick={updateBusinessStream}
                        >
                          Update
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            )}
            <div
              className="modal fade"
              id="deleteModal"
              tabIndex="-1"
              aria-labelledby="deleteModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Delete Business Stream ?
                    </h5>
                  </div>
                  <ModalBody>
                    <div>
                      <h6 className="text-danger">
                        Are you sure you want to delete ?
                      </h6>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={openModal}
                      className="btn btn-primary btn-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={deleteItem}
                    >
                      Yes, delete
                    </button>
                  </div>
                </Modal>
              </div>
            </div>
            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                {modalTitle}
              </ModalHeader>
              <ModalBody>{successMessage}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => setShowModal(false)}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </section>
        <AdminFooter />
      </div>
    </>
  );
};

export default AddBusinessStream;
