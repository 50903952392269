import React, { useEffect } from "react";

import { useState } from "react";

import axios from "axios";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { useHistory } from "react-router-dom";
import Navigation from "../../../components/layout/Navigation";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminPageHeader from "../../../components/layout/Admin/AdminPageHeader";
import { mainURL, subURL } from "../../../utils/URL's";
var USERID;
function FilterRolewise() {
  const { role } = useParams();
  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [jobs, setJobs] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sendSelectedCountry, setSendSelectedCountry] = useState(null);

  const [companyNameOrJobTitle, setCompanyNameOrJobTitle] = useState("");

  const [appliedStatus, setAppliedStatus] = useState([]);

  const [jobType, setJobType] = useState("");

  const [userId, setUserId] = useState();

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [filteredJobsTrue, setFilteredJobsTrue] = useState(false);

  const [compImage, setCompanyImage] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [showViewMoreBtn, setShowViewMore] = useState(true);

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
  }, []);

  const [selectedPostDate, setSelectedPostDate] = useState("");

  const [jobRole, setJobRole] = useState([]);
  const [jobRoleAll, setJobRoleAll] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const [showModalJobRole, setShowModalJobRole] = useState(false);

  const [jobIndustry, setJobIndustry] = useState([]);
  const [jobIndustryAll, setJobIndustryAll] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [showModalJobIndustry, setShowModalJobIndustry] = useState(false);

  const [jobExp, setJobExp] = useState([]);

  const [selectedExp, setSelectedExp] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 5; // Change this to the number of items to display per page

  const [showDateSection, setShowDateSection] = useState(true);
  const toggleDateSection = () => {
    setShowDateSection(!showDateSection);
  };

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const [showIndustrySection, setShowIndustrySection] = useState(true);
  const toggleIndustrySection = () => {
    setShowIndustrySection(!showIndustrySection);
  };

  const [showExpSection, setShowExpSection] = useState(true);
  const toggleExpSection = () => {
    setShowExpSection(!showExpSection);
  };

  useEffect(() => {
    const fetchJobRole = async () => {
      const response = await axios.get(`${subURL}/getjobtitle/`);

      setJobRole(response.data.slice(0, 4));
      setJobRoleAll(response.data);
    };

    fetchJobRole();
  }, []);

  useEffect(() => {
    const fetchJobIndustry = async () => {
      const response = await axios.get(`${subURL}/jobcategory/`);

      setJobIndustry(response.data.slice(0, 4));
      setJobIndustryAll(response.data);
    };

    fetchJobIndustry();
  }, []);

  useEffect(() => {
    const fetchJobExp = async () => {
      const response = await axios.get(`${subURL}/exptype/`);

      setJobExp(response.data);
    };

    fetchJobExp();
  }, []);

  const handlePostDateChange = (event) => {
    const selectedOption = event.target.value;
    setSelectedPostDate(selectedOption);

    const currentDate = new Date(); // Current date
    let startDate = new Date(); // Start date for filtering

    if (selectedOption === "Last 7 days") {
      startDate.setDate(currentDate.getDate() - 7); // Subtract 7 days from the current date
    } else if (selectedOption === "Last 14 days") {
      startDate.setDate(currentDate.getDate() - 14); // Subtract 14 days from the current date
    } else if (selectedOption === "Last 21 days") {
      startDate.setDate(currentDate.getDate() - 21); // Subtract 21 days from the current date
    } else if (selectedOption === "Last 30 days") {
      startDate.setDate(currentDate.getDate() - 30); // Subtract 30 days from the current date
    }

    const formattedStartDate = startDate.toISOString().slice(0, 10); // Format the start date as "yyyy-mm-dd"
    multiSearch(selectedPostDate, formattedStartDate);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    let updatedSelectedRole = [];
    if (checked) {
      updatedSelectedRole = [...selectedRole, value];
    } else {
      updatedSelectedRole = selectedRole.filter((role) => role !== value);
    }
    setSelectedRole(updatedSelectedRole);
    multiSearch(updatedSelectedRole);
    setShowModalJobRole(false);
  };

  const handleCheckboxChangeJobIndustry = (event) => {
    const { value, checked } = event.target;
    let updatedSelectedRole = [];
    if (checked) {
      updatedSelectedRole = [...selectedIndustry, value];
    } else {
      updatedSelectedRole = selectedIndustry.filter((role) => role !== value);
    }
    setSelectedIndustry(updatedSelectedRole);
    multiSearch(updatedSelectedRole);
    setShowModalJobIndustry(false);
  };

  const handleExperienceChange = (event) => {
    setSelectedExp(event.target.value);
    multiSearch(selectedRole, selectedPostDate, event.target.value);
  };

  const multiSearch = async (
    selectedRoles,
    selectedDate,
    selectedIndustry,
    selectedExp
  ) => {
    const response = await axios.get(
      `${subURL}/multisearch/${selectedRoles}/${selectedDate}/${selectedIndustry}/${selectedExp}/`
    );

    const filteredData = response.data;

    if (filteredData.length === 0) {
      setFilteredJobs([]);
      setJobs([]);
    }

    const filteredTotalPages = Math.ceil(filteredData.length / itemsPerPage);

    setTotalPages(filteredTotalPages);
    setFilteredData(filteredData);
    setFilteredJobs(filteredData.slice(0, itemsPerPage));
    setCurrentPage(1);
    setFilteredJobsTrue(true);
    setShowViewMore(true);
    setCompanyNameOrJobTitle("");
    setSelectedCountry("");
    setSendSelectedCountry("");
    setJobType("");
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        setFilteredJobs([]);
      }
    } else {
      const slicedData = jobs.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && jobs.length > 0) {
      const slicedData = jobs.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, jobs, itemsPerPage]);

  useEffect(() => {
    const fetchJobs = async () => {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const res = await axios.get(`${subURL}/filtereJobByRole/${role}/`, {
        headers: headers,
      });

      setJobs(res.data);
      setCompanyImage(res.data[0]?.company_id?.companyimage);
      const initialTotalPages = Math.ceil(res.data.length / itemsPerPage);
      setTotalPages(initialTotalPages);
      setFilteredJobs(res.data.slice(0, itemsPerPage));
    };

    fetchJobs();
  }, [role]);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    const fetchAppliedStatus = async () => {
      const res = await axios.get(`${subURL}/applyjob/${userId}/`);

      setAppliedStatus(res.data);
    };

    fetchAppliedStatus();
  }, [userId]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v2/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const options = data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
        }));

        setCountries(options);
      } catch (error) {
        console.error("Error fetching countries:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
    fetchCountries();
  }, []);

  return (
    <div className="site-main">
      <AdminHeader />

      <AdminPageHeader
        title="job list"
        breadcrumb="job"
        className="pb-65 pb-lg-0"
      />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget job-widget">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-calendar-1"></i>Date
                      Posted
                    </h3>
                    <FontAwesomeIcon
                      icon={showDateSection ? faAngleUp : faAngleDown}
                      color="black"
                      className="mb-3"
                      onClick={toggleDateSection}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {showDateSection && (
                    <form id="list1" className="list-filter">
                      <div onChange={handlePostDateChange}>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Today"
                            name="post_date"
                            checked={selectedPostDate === "Today"}
                          />
                          Today
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 7 days"
                            name="post_date"
                            checked={selectedPostDate === "Last 7 days"}
                          />
                          Last 7 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 14 days"
                            name="post_date"
                            checked={selectedPostDate === "Last 14 days"}
                          />
                          Last 14 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 21 days"
                            name="post_date"
                            checked={selectedPostDate === "Last 21 days"}
                          />
                          Last 21 days
                        </label>
                        <label className="radio">
                          <input
                            type="radio"
                            value="Last 30 days"
                            name="post_date"
                            checked={selectedPostDate === "Last 30 days"}
                          />
                          Last 30 days
                        </label>
                      </div>
                    </form>
                  )}
                </aside>

                <aside className="widget job-widget">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-subfolder-1"></i>
                      Job Role
                    </h3>
                    <FontAwesomeIcon
                      icon={showSection ? faAngleUp : faAngleDown}
                      color="black"
                      className="mb-3"
                      onClick={toggleSection}
                      style={{ cursor: "pointer" }}
                    />
                  </div>

                  {showSection && (
                    <form id="list2" className="list-filter">
                      {jobRole.map((category) => (
                        <div key={category.id} className="checkbox-item">
                          <input
                            type="checkbox"
                            value={category.job_title}
                            name="categories"
                            id={category.id}
                            className="mb-10"
                            onChange={handleCheckboxChange}
                            checked={selectedRole.includes(category.job_title)}
                          />
                          <label htmlFor={category.id} className="ml-10 ">
                            {category.job_title}
                          </label>
                        </div>
                      ))}

                      <button
                        type="button"
                        onClick={() => setShowModalJobRole(true)}
                      >
                        View More
                      </button>
                      <style>
                        {`
                        .checkbox-container {
                          display: flex;
                          flex-wrap: wrap;
                        }
                        
                        .checkbox-item {
                          display: flex;
                          align-items: center;
                          margin-right: 10px; /* Optional: Adjust the spacing between checkbox items */
                        }
                        
                        `}
                      </style>
                    </form>
                  )}
                </aside>

                <aside className="widget job-widget">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-subfolder-1"></i>
                      Industry
                    </h3>
                    <FontAwesomeIcon
                      icon={showIndustrySection ? faAngleUp : faAngleDown}
                      color="black"
                      className="mb-3"
                      onClick={toggleIndustrySection}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {showIndustrySection && (
                    <form id="list2" className="list-filter">
                      {jobIndustry.map((category) => (
                        <div key={category.id} className="checkbox-item">
                          <input
                            type="checkbox"
                            value={category.job_category}
                            name="categories"
                            id={category.id}
                            className="mb-10"
                            onChange={handleCheckboxChangeJobIndustry}
                            checked={selectedIndustry.includes(
                              category.job_category
                            )}
                          />
                          <label htmlFor={category.id} className="ml-10 ">
                            {category.job_category}
                          </label>
                        </div>
                      ))}

                      <button
                        type="button"
                        onClick={() => setShowModalJobIndustry(true)}
                      >
                        View More
                      </button>
                      <style>
                        {`
                        .checkbox-container {
                          display: flex;
                          flex-wrap: wrap;
                        }
                        
                        .checkbox-item {
                          display: flex;
                          align-items: center;
                          margin-right: 10px; /* Optional: Adjust the spacing between checkbox items */
                        }
                        
                        `}
                      </style>
                    </form>
                  )}
                </aside>

                <aside className="widget job-widget">
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="widget-title">
                      <i className="flaticon flaticon-expert"></i>Experience
                    </h3>
                    <FontAwesomeIcon
                      icon={showExpSection ? faAngleUp : faAngleDown}
                      color="black"
                      className="mb-3"
                      onClick={toggleExpSection}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {showExpSection && (
                    <form id="list3" className="list-filter">
                      <div onChange={handleExperienceChange}>
                        {jobExp.map((option) => (
                          <label className="radio" key={option.id}>
                            <input
                              type="radio"
                              value={option.experince_type}
                              name="ex_year"
                              checked={selectedExp === option.experince_type}
                            />
                            {option.experince_type}
                          </label>
                        ))}
                      </div>
                    </form>
                  )}
                </aside>
              </div>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="showing-result-block d-sm-flex align-items-center justify-content-between">
                    <span className="showing-result">
                      Showing 1–10 of 50 Jobs Results :
                    </span>
                    <form>
                      <div className="sort-by">
                        Sort By:
                        <select defaultValue="">
                          <option value="" disabled>
                            A to Z
                          </option>
                          <option value="popularity">a</option>
                          <option value="rating">b</option>
                          <option value="date">c</option>
                          <option value="price">d</option>
                          <option value="price-desc">e</option>
                        </select>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {filteredJobs.length === 0 && (
                <p className="text-center fs-16 mt-13">No jobs found.</p>
              )}
              {filteredJobs &&
                filteredJobs.map((jobVacancyListDetails) => (
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                        <div className="featured-thumbnail">
                          <img
                            src={
                              showViewMoreBtn
                                ? `${mainURL}${jobVacancyListDetails?.company_id?.company_image}`
                                : `${jobVacancyListDetails?.company_id?.company_image}`
                            }
                            alt="Job1"
                          />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href={`/viewjob/${jobVacancyListDetails.id}`}>
                                {jobVacancyListDetails.job_title}
                              </a>
                            </h3>
                          </div>
                          <div className="featured-desc">
                            <p>
                              {" "}
                              {jobVacancyListDetails?.createdDate?.substring(
                                0,
                                10
                              )}
                            </p>
                          </div>
                          <div className="featured-bottom">
                            <div className="job-meta">
                              <span>
                                <i className="fa fa-map-marker-alt"></i>
                                {jobVacancyListDetails.job_location_id.country}
                              </span>
                            </div>
                            <div className="job-time">
                              <span
                                className={`${
                                  jobVacancyListDetails?.job_type_id
                                    ?.job_type === "full time"
                                    ? "light green"
                                    : jobVacancyListDetails?.job_type_id
                                        ?.job_type === "part time"
                                    ? "orange"
                                    : "pink"
                                }`}
                                style={{
                                  padding: "5px",
                                  margin: "3px",
                                  color: "white",

                                  borderRadius: "0.3em",
                                }}
                              >
                                {jobVacancyListDetails.job_type_id.job_type
                                  .charAt(0)
                                  .toUpperCase() +
                                  jobVacancyListDetails.job_type_id.job_type.slice(
                                    1
                                  )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {filteredJobs.length > 0 && (
                <Navigation
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <AdminFooter />
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={showModalJobRole}
        toggle={() => setShowModalJobRole(false)}
      >
        <ModalHeader toggle={() => setShowModalJobRole(false)}>
          Categories
        </ModalHeader>
        <ModalBody>
          <form id="list2" className="list-filter">
            <div className="checkbox-container">
              {jobRoleAll.map((category) => (
                <div key={category.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    value={category.job_title}
                    name="categories"
                    id={category.id}
                    className="mt-0"
                    onChange={handleCheckboxChange}
                    checked={selectedRole.includes(category.job_title)}
                  />
                  <label htmlFor={category.id} className="ml-10">
                    {category.job_title}
                  </label>
                </div>
              ))}
            </div>
            <style>
              {`
        .checkbox-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px; /* Adjust the gap between columns */
        }
        
        .checkbox-item {
          display: flex;
          align-items: center;
          margin-bottom: 7px; /* Adjust the spacing between checkbox items */
        }
        
        `}
            </style>
          </form>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showModalJobIndustry}
        toggle={() => setShowModalJobIndustry(false)}
      >
        <ModalHeader toggle={() => setShowModalJobIndustry(false)}>
          Industry
        </ModalHeader>
        <ModalBody>
          <form id="list2" className="list-filter">
            <div className="checkbox-container">
              {jobIndustryAll.map((category) => (
                <div key={category.id} className="checkbox-item">
                  <input
                    type="checkbox"
                    value={category.job_category}
                    name="categories"
                    id={category.id}
                    className="mt-0"
                    onChange={handleCheckboxChangeJobIndustry}
                    checked={selectedIndustry.includes(category.job_category)}
                  />
                  <label htmlFor={category.id} className="ml-10">
                    {category.job_category}
                  </label>
                </div>
              ))}
            </div>
            <style>
              {`
        .checkbox-container {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 10px; /* Adjust the gap between columns */
        }
        
        .checkbox-item {
          display: flex;
          align-items: center;
          margin-bottom: 7px; /* Adjust the spacing between checkbox items */
        }
        
        `}
            </style>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default FilterRolewise;
