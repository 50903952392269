import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Button,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

const currentYear = new Date().getFullYear();
const yearOptions = [];

for (let year = 1950; year <= currentYear; year++) {
  yearOptions.push(year.toString());
}
var ID, USERID;
const Educationdetails = () => {
  const [userId, setUserId] = useState("");
  const [educationModal, setEducationModal] = useState(false);

  const openEducationModal = () => {
    setEducationModal(!educationModal);
    setEducation("");
    setUniversity("");
    setCourse("");
    setSpecialization("");
    setCourseType("");
    setStartYear("");
    setEndYear("");
    setGradingSystem("");
    setMarks("");
  };

  const [editEducationModal, setEditEducationModal] = useState(false);
  const openEditEducationModal = () =>
    setEditEducationModal(!editEducationModal);

  const [delId, setDelId] = useState("");
  const [deleteEducationModal, setDeleteEducationModal] = useState(false);
  const openDeleteEducationModal = () =>
    setDeleteEducationModal(!deleteEducationModal);

  const [isEdit, setIsEdit] = useState(false);

  const [educationDetails, setEducationDetails] = useState([]);

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/usereducation/${userId}/`)
      .then((res) => {
        setEducationDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const [showSection, setShowSection] = useState(true);

  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const [education, setEducation] = useState("");
  const [university, setUniversity] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [courseType, setCourseType] = useState("");

  const [gradingSystem, setGradingSystem] = useState("");
  const [marks, setMarks] = useState("");

  const [startYear, setStartYear] = useState("");
  const [endYear, setEndYear] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  async function handleSubmit(event) {
    event.preventDefault();

    async function storeData() {
      const formData = {
        user_account_id: userId,
        education: education,
        univesity: university,
        course: course,
        speclalization: specialization,
        course_type: courseType,
        course_duration: startYear,
        completion_date: endYear,
        grading_system: gradingSystem,
        marks: marks,
      };
      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.post(`${subURL}/usereducation/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setEducationModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/usereducation/${userId}/`);

              setEducationDetails(res.data);
            } catch (error) {
              console.log(error);
            } finally {
              setEducation("");
              setUniversity("");
              setCourse("");
              setSpecialization("");
              setCourseType("");
              setStartYear("");
              setEndYear("");
              setGradingSystem("");
              setMarks("");
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Please enter all the fields");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
        }

        console.log(e);
      }
    }

    storeData();
  }

  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditEducationModal(true);

    const filteredDummuyData = educationDetails.find((data) => data.id === id);
    setEducation(filteredDummuyData.education);
    setUniversity(filteredDummuyData.univesity);
    setCourse(filteredDummuyData.course);
    setSpecialization(filteredDummuyData.speclalization);
    setCourseType(filteredDummuyData.course_type);
    setStartYear(filteredDummuyData.course_duration);
    setEndYear(filteredDummuyData.completion_date);
    setGradingSystem(filteredDummuyData.grading_system);
    setMarks(filteredDummuyData.marks);
  }

  async function handleDelete(id) {
    setDeleteEducationModal(true);
    setDelId(id);
  }

  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/usereducation/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteEducationModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/usereducation/${userId}/`);

            setEducationDetails(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleEndYearChange = (e) => {
    const selectedEndYear = e.target.value;
    setEndYear(selectedEndYear);

    if (
      selectedEndYear !== "" &&
      startYear !== "" &&
      parseInt(selectedEndYear) < parseInt(startYear)
    ) {
      alert("Ending year cannot be lesser than the starting year!");
      setEndYear("");
    }
  };

  async function updateHandler() {
    const formData = {
      user_account_id: userId,
      education: education,
      univesity: university,
      course: course,
      speclalization: specialization,
      course_type: courseType,
      course_duration: startYear,
      completion_date: endYear,
      grading_system: gradingSystem,
      marks: marks,
    };
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(`${subURL}/usereducation/${ID}/`, formData, {
        headers: headers,
      });

      if (res.status === 200) {
        setModalTitle("Success");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setEditEducationModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/usereducation/${userId}/`);

            setEducationDetails(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
      console.log(e);
    }
  }
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Education Details</h5>

        <div className="button-container">
          <button
            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
            type="submit"
            onClick={openEducationModal}
            style={{ marginRight: "25px" }}
          >
            Add
          </button>
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <>
          {educationDetails.length > 0 ? (
            <div className="desc">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Education</th>
                    <th style={{ fontWeight: "500" }}>University</th>
                    <th style={{ fontWeight: "500" }}>Course</th>
                    <th style={{ fontWeight: "500" }}>Marks</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {educationDetails.map((education, key) => (
                    <tr key={education?.id}>
                      <td>{key + 1}</td>
                      <td>{education?.education}</td>
                      <td>{education?.univesity}</td>
                      <td>{education?.course}</td>
                      <td>{education?.marks}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() => handleEdit(education.id)}
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() => handleDelete(education.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <ListGroupItem>
              <p> No education details found...</p>
            </ListGroupItem>
          )}
        </>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={educationModal} toggle={openEducationModal} centered>
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Education
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openEducationModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className=" p-3">
                <Label for="education">Education</Label>
                <Input
                  type="text"
                  name="education"
                  id="education"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
              </div>
              <div className="p-3">
                <Label for="university">University</Label>
                <Input
                  type="text"
                  name="university"
                  id="university"
                  value={university}
                  onChange={(e) => setUniversity(e.target.value)}
                />
              </div>
              <div className="p-3">
                <Label for="course">Course</Label>
                <Input
                  type="text"
                  name="course"
                  id="course"
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                />
              </div>
              <div className="p-3">
                <Label for="specialization">Specialization</Label>
                <Input
                  type="text"
                  name="specialization"
                  id="specialization"
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                />
              </div>

              <div className="p-3">
                <FormGroup>
                  <Label for="courseType">Course Type</Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="courseType"
                          value="Full Time"
                          checked={courseType === "Full Time"}
                          onChange={(e) => setCourseType(e.target.value)}
                        />{" "}
                        Full Time
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="courseType"
                          value="Part Time"
                          checked={courseType === "Part Time"}
                          onChange={(e) => setCourseType(e.target.value)}
                        />{" "}
                        Part Time
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>

              <div className="p-3">
                <Label for="startYear">Starting Year</Label>
                <Input
                  type="select"
                  name="startYear"
                  id="startYear"
                  value={startYear}
                  onChange={(e) => setStartYear(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    Starting Year
                  </option>
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </div>

              <div className="p-3">
                <Label for="endYear">Ending Year</Label>
                <Input
                  type="select"
                  name="endYear"
                  id="endYear"
                  value={endYear}
                  onChange={handleEndYearChange}
                >
                  <option value="" disabled selected hidden>
                    Ending Year
                  </option>
                  {yearOptions.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </div>

              <div className="p-3">
                <Label for="gradingSystem">Grading System</Label>
                <Input
                  type="text"
                  name="gradingSystem"
                  id="gradingSystem"
                  value={gradingSystem}
                  onChange={(e) => setGradingSystem(e.target.value)}
                />
              </div>

              <div className="p-3">
                <Label for="marks">Marks</Label>
                <Input
                  type="number"
                  name="marks"
                  id="marks"
                  value={marks}
                  onChange={(e) => {
                    const enteredValue = parseInt(e.target.value, 10);
                    if (enteredValue >= 0) {
                      setMarks(enteredValue);
                    }
                  }}
                />
              </div>
              <div className="p-3">
                <button
                  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteEducationModal}
            toggle={openDeleteEducationModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteEducationModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteEducationModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItem}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editEducationModal}
              toggle={openEditEducationModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Education
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditEducationModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className=" p-3">
                  <Label for="education">Education</Label>
                  <Input
                    type="text"
                    name="education"
                    id="education"
                    placeholder="Education"
                    value={education}
                    onChange={(e) => setEducation(e.target.value)}
                  />
                </div>
                <div className="p-3">
                  <Label for="university">University</Label>
                  <Input
                    type="text"
                    name="university"
                    id="university"
                    value={university}
                    onChange={(e) => setUniversity(e.target.value)}
                  />
                </div>
                <div className="p-3">
                  <Label for="course">Course</Label>
                  <Input
                    type="text"
                    name="course"
                    id="course"
                    value={course}
                    onChange={(e) => setCourse(e.target.value)}
                  />
                </div>
                <div className="p-3">
                  <Label for="specialization">Specialization</Label>
                  <Input
                    type="text"
                    name="specialization"
                    id="specialization"
                    value={specialization}
                    onChange={(e) => setSpecialization(e.target.value)}
                  />
                </div>

                <div className="p-3">
                  <Label for="courseType">Course Type : </Label>
                  <FormGroup check inline style={{ margin: "1em" }}>
                    <Label check>
                      <Input
                        type="radio"
                        name="courseType"
                        value="Full Time"
                        checked={courseType === "Full Time"}
                        onChange={(e) => setCourseType(e.target.value)}
                      />{" "}
                      Full Time
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        type="radio"
                        name="courseType"
                        value="Part Time"
                        checked={courseType === "Part Time"}
                        onChange={(e) => setCourseType(e.target.value)}
                      />{" "}
                      Part Time
                    </Label>
                  </FormGroup>
                </div>

                <div className="p-3">
                  <Label for="startYear">Starting Year</Label>
                  <Input
                    type="select"
                    name="startYear"
                    id="startYear"
                    value={startYear}
                    onChange={(e) => setStartYear(e.target.value)}
                  >
                    <option value="" disabled selected hidden>
                      Starting Year
                    </option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="p-3">
                  <Label for="endYear">Ending Year</Label>
                  <Input
                    type="select"
                    name="endYear"
                    id="endYear"
                    value={endYear}
                    onChange={handleEndYearChange}
                  >
                    <option value="" disabled selected hidden>
                      Ending Year
                    </option>
                    {yearOptions.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </Input>
                </div>

                <div className="p-3">
                  <Label for="gradingSystem">Grading System</Label>
                  <Input
                    type="text"
                    name="gradingSystem"
                    id="gradingSystem"
                    value={gradingSystem}
                    onChange={(e) => setGradingSystem(e.target.value)}
                  />
                </div>

                <div className="p-3">
                  <Label for="marks">Marks</Label>
                  <Input
                    type="number"
                    name="marks"
                    id="marks"
                    value={marks}
                    onChange={(e) => {
                      const enteredValue = parseInt(e.target.value, 10);
                      if (enteredValue >= 0) {
                        setMarks(enteredValue);
                      }
                    }}
                  />
                </div>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditEducationModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Educationdetails;
