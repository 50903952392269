import React from "react";
import EmployerLogo from "./EmployerLogo";
import EmployerNavBar from "./EmployerNavBar";
import EmployerMobile_menu from "./EmployerMobile_menu";
import EmployerMenu from "./EmployerMenu";

const EmployerHeader = () => {
  return (
    <header id="masthead" className="header ttm-header-style-01">
      <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
        <div className="site-header-menu-inner ttm-stickable-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                {/* site-navigation */}
                <div className="site-navigation d-flex align-items-center justify-content-between">
                  {/* site-branding */}
                  <div className="site-branding">
                    <EmployerLogo />
                  </div>
                  {/* site-branding end */}
                  <div className="border-box-block">
                    <div className=" d-flex align-items-center justify-content-between">
                      {/* menu */}
                      {/* <Menu /> */}
                      <EmployerNavBar />
                      <div className="mobile-menu">
                        <EmployerMobile_menu />
                      </div>
                      {/* menu end */}
                    </div>
                  </div>

                  <div className="header_extra ml-auto d-flex align-items-center">
                    <EmployerMenu />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default EmployerHeader;
