import React, { Component } from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import Footer from "../components/layout/Footer";
import US from "../images/Us.jpg";
import Australia from "../images/Australia.jpg";
import Canada from "../images/Canada.jpg";
import { useState } from "react";
import axios from "axios";
import { subURL } from "../utils/URL's";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
var USERID;
const Contact_01 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [userId, setUserId] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };
  const handlePhoneNoChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setPhoneNum(inputPhone);
  };
  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (phoneNum.length < 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }
    async function storeData() {
      const formData = {
        user_account_id: userId,
        name: name,
        email: email,
        phone_number: phoneNum,
        message: message,
      };

      try {
        setLoading(true);
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/contactus/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          setSuccessMessage("Sent Message Successfully !");
          setShowModal(true);
        } else {
          console.log("error");
        }
      } catch (e) {
        console.log(e);

        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
      } finally {
        setLoading(false);
      }
    }

    storeData();

    setName("");
    setEmail("");
    setMessage("");
    setPhoneNum("");
  };
  return (
    <div className="site-main">
      <Header />

      <PageHeader title="Contact us" breadcrumb="Contact form" />

      <section className="ttm-row padding_zero-section bg-layer-equal-height clearfix">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-12">
              <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-GreyColor h-auto p-40 p-lg-30 mt-lg-30 mt-50 mb-50">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <div className="section-title title-style-center_text">
                    <div className="title-header">
                      <h3>
                        Let’s Get{" "}
                        <span className="text-theme-SkinColor">in Touch</span>
                      </h3>
                      <h2 className="title">Request a Callback</h2>
                    </div>
                  </div>
                  <form
                    id="contact_form"
                    className="contact_form wrap-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="row ttm-boxes-spacing-10px">
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            name="name"
                            type="text"
                            placeholder="Your Name"
                            required="required"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </label>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            name="email"
                            type="email"
                            placeholder="Your Email"
                            required="required"
                            value={email}
                            onChange={handleChangeEmail}
                          />
                        </label>
                      </div>

                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <input
                            maxLength={10}
                            type="tel"
                            pattern="[0-9]{10}"
                            id="txtphone"
                            placeholder="Phone Number"
                            value={phoneNum}
                            onChange={handlePhoneNoChange}
                          />
                        </label>
                      </div>
                      <div className="col-md-12 ttm-box-col-wrapper">
                        <label>
                          <textarea
                            name="message"
                            rows="7"
                            placeholder="Message"
                            required="required"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </label>
                      </div>
                    </div>
                    <button
                      className="submit mt-3 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill
                                        ttm-btn-color-skincolor w-100"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          {" Loading..."}
                        </>
                      ) : (
                        "Send a message!"
                      )}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </section>
      {/* padding_zero-section end */}

      <Footer />
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Contact_01;
