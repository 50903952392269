import React, { useEffect, useState } from "react";

import axios from "axios";
import { mainURL, subURL } from "../../../utils/URL's";
import Navigation from "../../../components/layout/Navigation";
import {
  Badge,
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import Select from "react-select";
import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";
var ID, USERID, USERTYPEID;
const ViewJobsEmployer = () => {
  const [modal, setModal] = useState(false);
  const [delId, setDelId] = useState("");

  const openModal = () => setModal(!modal);

  const [jobData, setJobData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [editModal, setEditModal] = useState(false);
  const openEditModal = () => setEditModal(!editModal);

  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [skillLevel, setSkillLevel] = useState("");
  const [skillName, setSkillName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [zip, setZip] = useState("");
  const [isCompanyNameHidden, setIsCompanyNameHidden] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [jobDescription, setJobDescription] = useState("");

  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const [jobcategories, setJobCategories] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState(null);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sendSelectedCountry, setSendSelectedCountry] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [expType, setExpType] = useState("");
  const [experience, setExperience] = useState([]);
  const [companyNameOrJobTitle, setCompanyNameOrJobTitle] = useState("");

  const [filteredData, setFilteredData] = useState([]);
  const [userTypeId, setUserTypeId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 7; // Change this to the number of items to display per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        // Handle the case where the start index is beyond the filtered data length
        setFilteredJobs([]);
      }
    } else {
      const slicedData = jobData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  const [showViewMoreBtn, setShowViewMore] = useState(false);
  const [filteredJobsTrue, setFilteredJobsTrue] = useState(false);

  const [userId, setUserId] = useState("");

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/jobcategory`)
      .then((response) => {
        setJobCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && jobData.length > 0) {
      const slicedData = jobData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, jobData, itemsPerPage]);

  useEffect(() => {
    axios
      .get(`${subURL}/post_job/`)
      .then((response) => {
        setJobData(response.data);

        const initialTotalPages = Math.ceil(
          response.data.length / itemsPerPage
        );
        setTotalPages(initialTotalPages);
        setFilteredJobs(response.data.slice(0, itemsPerPage));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function getUserTypeID() {
    try {
      USERTYPEID = await localStorage.getItem("usertypeid");

      if (USERTYPEID !== null) {
        setUserTypeId(USERTYPEID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserTypeID();

  useEffect(() => {
    getUserTypeID();
  }, []);

  useEffect(() => {
    const fetchSkillSetOptions = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchSkillSetOptions();
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v2/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const options = data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
        }));

        setCountries(options);
      } catch (error) {
        console.error("Error fetching countries:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
    fetchCountries();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/company_save_details`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const toggleEditModal = () => {
    setEditModal(!editModal);
  };
  const handleChangeJobCategory = (event) => {
    setSelectedJobCategory(event.target.value);
  };
  const handleChange = (selectedOption) => {
    const countryName = selectedOption ? selectedOption.label : "";
    setSelectedCountry(selectedOption);
    setSendSelectedCountry(countryName);
  };
  const handleChangeCompany = (event) => {
    setSelectedCompanyId(event.target.value);
  };
  function editItem(id) {
    ID = id;

    setEditModal(true);

    const filteredDummuyData = jobData.find((data) => data.id === id);

    setJobTitle(filteredDummuyData?.job_title);

    // setJobType(filteredDummuyData?.job_type_id.job_type);
    setJobType(filteredDummuyData?.job_type_id?.id);
    setExpType(filteredDummuyData?.experince_type_id?.id);
    setStreetAddress(filteredDummuyData?.job_location_id.street_address);

    setCity(filteredDummuyData?.job_location_id.city);
    setState(filteredDummuyData?.job_location_id.state);
    setSelectedCountry(filteredDummuyData?.job_location_id.country);

    setZip(filteredDummuyData?.job_location_id.zip);
    setIsActive(filteredDummuyData?.is_active);
    setIsCompanyNameHidden(filteredDummuyData?.is_company_name_hidden);

    setJobDescription(filteredDummuyData?.job_description);
    setSelectedCompanyId(filteredDummuyData?.company_id.id);
    setSelectedJobCategory(filteredDummuyData?.job_category_id.id);
  }
  function updateHandler() {
    const formData = {
      job_title: jobTitle,
      job_type_id: jobType,
      company_id: selectedCompanyId,
      job_category_id: selectedJobCategory,
      is_company_name_hidden: isCompanyNameHidden,
      experince_type_id: expType,
      job_description: jobDescription,
      is_active: isActive,

      skill_set_id: skillName,
      skill_level: skillLevel,
      street_address: streetAddress,
      city: city,
      state: state,
      country: sendSelectedCountry,
      zip: zip,
      user_account_id: userId,
      // user_type_id: userTypeId,
    };
    const pincode1 = zip.toString();
    if (pincode1.length < 6) {
      alert("Pincode has to be 6 digits long");
      return;
    }
    async function updateData() {
      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };

        const resLogin = await axios.put(
          `${subURL}/post_job/${ID}/`,
          formData,
          {
            headers: headers,
          }
        );
        if (resLogin.status === 200) {
          setModalTitle("Sucess!");
          setSuccessMessage("Updated Successfully !");
          setShowModal(true);
          setEditModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/post_job/`);

              //setFilteredJobs(res.data.slice(0, 3));
              const updatedData = res.data;

              // Update the jobs state with the fetched data
              setJobData(updatedData);

              // Update the filteredJobs state with the fetched data
              const initialTotalPages = Math.ceil(
                updatedData.length / itemsPerPage
              );
              setTotalPages(initialTotalPages);
              const slicedData = updatedData.slice(startIndex, endIndex);
              setFilteredJobs(slicedData);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        }
      } catch (error) {
        setModalTitle("Failed!");
        setSuccessMessage("Oops !Something went wrong,Please try again");
        setShowModal(true);
        setEditModal(false);
        console.log(error);
      }
    }
    updateData();
  }

  function handleDelete(id) {
    setDelId(id);
    setModal(true);
  }

  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/post_job/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/post_job/`);

            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setJobData(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed!");
      setSuccessMessage("Oops !Something went wrong,Please try again");
      setShowModal(true);
      console.log(error);
    }
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const response = await axios.get(
      `${subURL}/filteredjob/${sendSelectedCountry}/${jobType}/${companyNameOrJobTitle}/`
    );

    const filteredData = response.data;

    if (filteredData.length === 0) {
      setFilteredJobs([]);
      setJobData([]);
    }

    const filteredTotalPages = Math.ceil(filteredData.length / itemsPerPage);

    setTotalPages(filteredTotalPages);
    setFilteredData(filteredData);
    setFilteredJobs(filteredData.slice(0, itemsPerPage));
    setCurrentPage(1);
    setFilteredJobsTrue(true);
    setShowViewMore(true);
    setCompanyNameOrJobTitle("");
    setSelectedCountry("");
    setSendSelectedCountry("");
    setJobType("");
  };

  const resetJobs = async () => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    const res = await axios.get(`${subURL}/post_job/`, {
      headers: headers,
    });

    const allJobs = res.data;
    const initialTotalPages = Math.ceil(allJobs.length / itemsPerPage);

    setJobData(allJobs);
    setTotalPages(initialTotalPages);
    setCurrentPage(1);
    setFilteredData(allJobs); // Reset filtered data as well
    setFilteredJobs(allJobs.slice(0, itemsPerPage));
    setFilteredJobsTrue(false);
    setShowViewMore(false);
  };
  return (
    <div className="site-main">
      <EmployerHeader />

      <EmployerPageHeader
        title="job list"
        breadcrumb="job"
        className="pb-65 pb-lg-0"
      />

      <div className="form-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-30 p-lg-20 mt_60 mt-xl-50">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <Form
                    onSubmit={handleFormSubmit}
                    className="d-flex align-items-end"
                  >
                    <Container>
                      <Row className="filter">
                        <Col>
                          <FormGroup className="me-2 mt-3 mb-0">
                            <Label
                              for="companyNameOrJobTitle"
                              className="visually-hidden"
                            >
                              Company Name or Job Title
                            </Label>
                            <Input
                              type="text"
                              name="companyNameOrJobTitle"
                              id="companyNameOrJobTitle"
                              placeholder="Job Title"
                              value={companyNameOrJobTitle}
                              onChange={(e) =>
                                setCompanyNameOrJobTitle(e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="me-2  mt-3 mb-0">
                            <Label for="location" className="visually-hidden">
                              Location
                            </Label>
                            <Select
                              placeholder="Select Country"
                              options={countries}
                              value={selectedCountry}
                              onChange={handleChange}
                            />
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup className="me-2  mt-3 mb-0">
                            <Label for="jobType" className="visually-hidden">
                              Job Type
                            </Label>
                            <Input
                              type="select"
                              name="jobType"
                              id="jobType"
                              value={jobType}
                              onChange={(e) => setJobType(e.target.value)}
                            >
                              <option value="">Select Job Type</option>
                              {jobTypeOptions.map((option) => (
                                <option key={option.id} value={option.value}>
                                  {option.job_type.charAt(0).toUpperCase() +
                                    option.job_type.slice(1)}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col className="me-2 mt-13 mb-0">
                          <button
                            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                            type="submit"
                            style={{
                              marginTop: "1em",
                            }}
                            onClick={handleFormSubmit}
                          >
                            Find Jobs
                          </button>
                        </Col>
                        {filteredJobsTrue && (
                          <Col className="me-2 mt-13 mb-0">
                            <button
                              className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                              type="submit"
                              style={{
                                marginTop: "1em",
                              }}
                              onClick={resetJobs}
                            >
                              Reset
                            </button>
                          </Col>
                        )}
                        <style>
                          {`
    @media only screen and (max-width: 991px) {
     
.filter{
  display:block
}
      
   
    `}
                        </style>
                      </Row>
                    </Container>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 content-area">
              {filteredJobs.length > 0 ? (
                filteredJobs.map((job) => (
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                        <div className="featured-thumbnail">
                          <img
                            alt="Job1"
                            src={
                              !showViewMoreBtn
                                ? `${job?.company_id?.company_image}`
                                : `${mainURL}${job?.company_id?.company_image}`
                            }
                          />
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">
                            <h3>
                              <a href={`/employerviewjob/${job.id}`}>
                                {job.job_title}
                              </a>
                            </h3>
                          </div>

                          <div className="featured-bottom">
                            <div className="job-meta">
                              <span>Job Desription:</span>
                              <span>
                                {job.job_description.length > 75
                                  ? job.job_description.slice(0, 10) + "..."
                                  : job.job_description}
                              </span>
                            </div>

                            <div className="job-meta">
                              <span>Category:</span>
                              <span>{job.job_category_id.job_category}</span>
                            </div>
                          </div>

                          <div className="featured-bottom">
                            <div className="job-meta">
                              {job?.job_location_id?.country && (
                                <span>
                                  <i className="fa fa-map-marker-alt"></i>
                                  {job.job_location_id.country}
                                </span>
                              )}
                              <span>{job.company_id.company_name}</span>

                              <span>
                                Experience Type:{" "}
                                {job?.experince_type_id?.experince_type}
                              </span>
                            </div>
                            <div className="job-time">
                              <Badge
                                className="fs-13 mt-1"
                                color={
                                  job?.job_type_id?.job_type === "full time"
                                    ? "success"
                                    : job?.job_type_id?.job_type === "part time"
                                    ? "danger"
                                    : job?.job_type_id?.job_type ===
                                      "freelancer"
                                    ? "pink"
                                    : "primary"
                                }
                              >
                                {job.job_type_id?.job_type
                                  .charAt(0)
                                  .toUpperCase() +
                                  job.job_type_id?.job_type.slice(1)}
                              </Badge>

                              {job.user_account_id?.id === parseInt(userId) && (
                                <>
                                  <span
                                    className="edit-icon"
                                    style={{ margin: "10px" }}
                                  >
                                    <a
                                      href="#"
                                      onClick={() => editItem(job.id)}
                                    >
                                      <i
                                        className="fa fa-edit"
                                        style={{ color: "green" }}
                                      ></i>
                                    </a>
                                  </span>

                                  <span
                                    className="spacing"
                                    style={{ margin: "10px" }}
                                  >
                                    <a
                                      href="#"
                                      onClick={() => handleDelete(job.id)}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </a>
                                  </span>
                                </>
                              )}

                              {/* <span
                                className="edit-icon"
                                style={{ margin: "10px" }}
                              >
                                <a href="#" onClick={() => editItem(job.id)}>
                                  <i
                                    className="fa fa-edit"
                                    style={{ color: "green" }}
                                  ></i>
                                </a>
                              </span> */}

                              {/* <span
                                className="spacing"
                                style={{ margin: "10px" }}
                              >
                                <a
                                  href="#"
                                  onClick={() => handleDelete(job.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </a>
                              </span> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h5 className="fs-18 mt-3 mb-5 text-center">No jobs found</h5>
              )}
            </div>
          </div>
        </div>

        {filteredJobs.length > 0 && (
          <Navigation
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <div
        className="modal fade"
        id="editmodal"
        tabIndex="-1"
        aria-labelledby="editmodal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={editModal}
            toggle={toggleEditModal}
            centered
            tabIndex="-1"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Edit Job
              </h5>
            </div>
            <ModalBody>
              <div className="job-post-content box-shadow-md rounded-3 p-4">
                <Row className="row">
                  <Col lg={12}>
                    <div className="mb-4">
                      <Label for="jobtitle">Job Title</Label>
                      <Input
                        type="text"
                        name="jobtitle"
                        id="jobtitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobType">Job Type</Label>
                      <Input
                        type="select"
                        name="jobType"
                        id="jobType"
                        value={jobType}
                        onChange={(e) => setJobType(e.target.value)}
                      >
                        {jobTypeOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.job_type.charAt(0).toUpperCase() +
                              option.job_type.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="exptype">Experience Type</Label>
                      <Input
                        type="select"
                        name="exptype"
                        id="exptype"
                        value={expType}
                        onChange={(e) => setExpType(e.target.value)}
                      >
                        {experience.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.experince_type.charAt(0).toUpperCase() +
                              option.experince_type.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="streetAddress">Street Address</Label>
                      <Input
                        type="text"
                        name="streetAddress"
                        id="streetAddress"
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="city">City</Label>
                      <Input
                        type="text"
                        name="city"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="state">State</Label>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="country">Country</Label>

                      <Select
                        placeholder="Select Country"
                        options={countries}
                        defaultValue={
                          selectedCountry
                            ? {
                                value: selectedCountry,
                                label: selectedCountry,
                              }
                            : undefined
                        }
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="zip">Zip</Label>
                      <Input
                        type="number"
                        name="zip"
                        id="zip"
                        maxLength={7}
                        value={zip}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value, 10);
                          if (enteredValue >= 0 && e.target.value.length <= 6) {
                            setZip(enteredValue);
                          }
                        }}
                      />
                    </div>
                  </Col>

                  {/* <Col lg={6} style={{ marginTop: "3%" }}>
                    <div className="mb-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={isCompanyNameHidden}
                          onChange={(e) =>
                            setIsCompanyNameHidden(e.target.checked)
                          }
                        />{" "}
                        Hide Company Name
                      </Label>
                    </div>
                  </Col> */}

                  <Col lg={4} style={{ marginTop: "3%" }}>
                    <div className="mb-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />{" "}
                        Job is Active
                      </Label>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobDescription">Company Name</Label>
                      <Input
                        type="select"
                        name="companyname"
                        id="companyname"
                        value={selectedCompanyId}
                        onChange={handleChangeCompany}
                      >
                        {companies?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.company_name.charAt(0).toUpperCase() +
                              option.company_name.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobcategory">Job Category</Label>
                      <Input
                        required
                        type="select"
                        name="jobcategory"
                        id="jobcategory"
                        value={selectedJobCategory}
                        onChange={handleChangeJobCategory}
                      >
                        <option value="">Select Job Category</option>
                        {jobcategories?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.job_category.charAt(0).toUpperCase() +
                              option.job_category.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-4">
                      <Label for="jobDescription">Job Description</Label>
                      <Input
                        rows={10}
                        type="textarea"
                        name="jobDescription"
                        id="jobDescription"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  onClick={openEditModal}
                  className="btn btn-danger btn-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  onClick={updateHandler}
                >
                  Update
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Delete Job ?
              </h5>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  Are you sure you want to delete ?
                </h6>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={openModal}
                className="btn btn-primary btn-sm"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger btn-sm"
                onClick={deleteItem}
              >
                Yes, delete
              </button>
            </div>
          </Modal>
        </div>
      </div>

      <EmployerFooter />

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ViewJobsEmployer;
