import React, { useEffect } from "react";

import { useState } from "react";

import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import Navigation from "../../../components/layout/Navigation";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import axios from "axios";
import { imageURL, mainURL, subURL } from "../../../utils/URL's";
import { Link } from "react-router-dom";
import AdminPageHeader from "../../../components/layout/Admin/AdminPageHeader";

function Candidate_list() {
  const [candidateData, setCandidateData] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [editModal, setEditModal] = useState(false);

  const [filterStatus, setFilterStatus] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredJobsTrue, setFilteredJobsTrue] = useState(false);

  const [showViewMoreBtn, setShowViewMore] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        // Handle the case where the start index is beyond the filtered data length
        setFilteredJobs([]);
      }
    } else {
      const slicedData = candidateData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 3; // Change this to the number of items to display per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && candidateData.length > 0) {
      const slicedData = candidateData.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, candidateData, itemsPerPage]);

  async function handleStatusClick(id, userid, jobpostid, status) {
    const formData = {
      user_account_id: userid,
      job_post_id: jobpostid,
      status: status,
      userstatus: true,
    };

    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(`${subURL}/applyjob/${id}/`, formData, {
        headers: headers,
      });

      if (res.status === 201) {
        setModalTitle("Success");
        setSuccessMessage("Status updated Successfully !");
        setShowModal(true);
        setEditModal(false);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 100);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/applyjob/`);

            //setCandidateData(res.data);
            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setCandidateData(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);

      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
    }
  }

  useEffect(() => {
    async function fetechUserId() {
      const res = await axios.get(`${subURL}/applyjob/`);

      setCandidateData(res.data);

      const initialTotalPages = Math.ceil(res.data.length / itemsPerPage);
      setTotalPages(initialTotalPages);
      setFilteredJobs(res.data.slice(0, itemsPerPage));
    }
    fetechUserId();
  }, []);

  async function handleFormSubmit(e) {
    e.preventDefault();

    const response = await axios.get(
      `${subURL}/statuswisefilter/${filterStatus}/`
    );
    const filteredData = response.data;
    if (filteredData.length === 0) {
      setFilteredJobs([]);
      setCandidateData([]);
    }
    const filteredTotalPages = Math.ceil(filteredData.length / itemsPerPage);

    setTotalPages(filteredTotalPages);
    setFilteredData(filteredData);
    setFilteredJobs(filteredData.slice(0, itemsPerPage));
    setCurrentPage(1);
    setFilteredJobsTrue(true);
    setShowViewMore(true);
    setFilterStatus("");
  }

  const resetJobs = async () => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    const res = await axios.get(`${subURL}/applyjob/`, {
      headers: headers,
    });

    const allJobs = res.data;
    const initialTotalPages = Math.ceil(allJobs.length / itemsPerPage);

    setCandidateData(allJobs);
    setTotalPages(initialTotalPages);
    setCurrentPage(1);
    setFilteredData(allJobs); // Reset filtered data as well
    setFilteredJobs(allJobs.slice(0, itemsPerPage));
    setFilteredJobsTrue(false);
    setShowViewMore(false);
  };

  return (
    <div className="site-main">
      <AdminHeader />

      <AdminPageHeader title="candidate list" breadcrumb="candidate" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 content-area">
              <div className="row"></div>
              <div className="row">
                <div className="col-lg-12">
                  <Row>
                    <Form
                      onSubmit={handleFormSubmit}
                      className="d-flex align-items-end"
                    >
                      <Container>
                        <Row className="filter">
                          <Col>
                            <h5 className="fs-15 mt-4 mb-3 text-center">
                              Filter By
                            </h5>
                          </Col>

                          <Col>
                            <FormGroup className="me-2 mt-3 mb-3">
                              <Label for="jobType" className="visually-hidden">
                                Status
                              </Label>
                              <Input
                                type="select"
                                name="jobType"
                                id="jobType"
                                value={filterStatus}
                                onChange={(e) =>
                                  setFilterStatus(e.target.value)
                                }
                              >
                                <option value="">Select Status</option>
                                <option value="accepted">Accepted</option>
                                <option value="rejected">Rejected</option>
                                <option value="pending">Pending</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col className="me-2  mt-13 mb-0">
                            <button
                              className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                              type="submit"
                              style={{
                                marginTop: "1em",
                              }}
                              onClick={handleFormSubmit}
                            >
                              Filter
                            </button>
                          </Col>
                          {filteredJobsTrue && (
                            <Col className=" me-2  mt-13 mb-0">
                              <button
                                className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                                type="submit"
                                style={{
                                  marginTop: "1em",
                                }}
                                onClick={resetJobs}
                              >
                                Reset
                              </button>
                            </Col>
                          )}
                        </Row>

                        <style>
                          {`
    @media only screen and (max-width: 991px) {
     
.filter{
  display:block
}
      
   
    `}
                        </style>
                      </Container>
                    </Form>
                  </Row>

                  {/* <div className="candidate-list">
                    {filteredJobs.length === 0 ? (
                      <h5 className="fs-15 mt-4 mb-8 text-center">
                        No data found
                      </h5>
                    ) : (
                      filteredJobs.map((candidateDetailsNew, key) => (
                        <div
                          key={key}
                          className={
                            candidateDetailsNew.addclassNameBookmark === true
                              ? "candidate-list-box bookmark-post card mt-4"
                              : "candidate-list-box card mt-4"
                          }
                        >
                          <CardBody className="p-4">
                            <Row>
                              <Col lg={2}>
                                <Row>
                                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor">
                                    <div className="featured-thumbnail">
                                      <Link
                                        to={`/candidate_details/${candidateDetailsNew.user_account_id.id}`}
                                      >
                                        <img
                                          src={
                                            !showViewMoreBtn
                                              ? `${candidateDetailsNew.user_account_id.profile_photo}`
                                              : `${mainURL}${candidateDetailsNew.user_account_id.profile_photo}`
                                          }
                                          alt="img"
                                        />
                                      </Link>
                                      <Link
                                        to={`/candidate_details/${candidateDetailsNew.user_account_id.id}`}
                                        className="fs-19 mb-0"
                                      >
                                        {candidateDetailsNew.user_account_id.first_name
                                          .charAt(0)
                                          .toUpperCase() +
                                          candidateDetailsNew.user_account_id.first_name.slice(
                                            1
                                          )}
                                      </Link>
                                    </div>
                                  </div>
                                </Row>
                              </Col>
                              <Col lg={3}>
                                <Row>
                                  <div className="mt-3">
                                    <span>
                                      Applied for:{" "}
                                      <Link
                                        to={`/viewjob/${candidateDetailsNew.job_post_id.id}`}
                                        className="text-muted mb-2"
                                      >
                                        {
                                          candidateDetailsNew.job_post_id
                                            .job_title
                                        }
                                      </Link>
                                    </span>
                                  </div>
                                </Row>
                                <Row>
                                  <div className="mt-2">
                                    <span>
                                      Applied date:{" "}
                                      {candidateDetailsNew.apply_date?.substring(
                                        0,
                                        10
                                      )}
                                    </span>
                                  </div>
                                </Row>
                              </Col>
                              <Col lg={4}>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                    <button
                                      className="btn btn-sm btn-outline-success"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "accepted"
                                        )
                                      }
                                    >
                                      Accepted
                                    </button>
                                    <button
                                      className="btn btn-sm btn-outline-danger"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "rejected"
                                        )
                                      }
                                    >
                                      Rejected
                                    </button>
                                    <button
                                      className="btn btn-sm btn-outline-orange"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "pending"
                                        )
                                      }
                                    >
                                      Pending
                                    </button>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <span
                                  className="badge fs-18 mt-1 p-3 m-5"
                                  style={{
                                    backgroundColor:
                                      candidateDetailsNew.status === "accepted"
                                        ? "green"
                                        : candidateDetailsNew.status ===
                                          "rejected"
                                        ? "red"
                                        : "gray",
                                    fontSize: "11px",
                                    borderRadius: "0.3em",
                                  }}
                                >
                                  {candidateDetailsNew.status
                                    .charAt(0)
                                    .toUpperCase() +
                                    candidateDetailsNew.status.slice(1)}
                                </span>
                              </Col>
                            </Row>
                          </CardBody>
                        </div>
                      ))
                    )}
                  </div> */}

                  <div className="row">
                    {filteredJobs.map((candidateDetailsNew) => (
                      <div className="col-lg-12">
                        <div className="featured-imagebox featured-imagebox-candidate">
                          <div className="featured-thumbnail">
                            <img
                              alt="candidateimg"
                              src={
                                !showViewMoreBtn
                                  ? `${candidateDetailsNew.user_account_id.profile_photo}`
                                  : `${imageURL}${candidateDetailsNew.user_account_id.profile_photo}`
                              }
                            />
                          </div>
                          <div className="featured-content">
                            <div className="featured-title">
                              <h3>
                                {" "}
                                {candidateDetailsNew.user_account_id.first_name
                                  .charAt(0)
                                  .toUpperCase() +
                                  candidateDetailsNew.user_account_id.first_name.slice(
                                    1
                                  )}
                              </h3>
                            </div>
                            <div className="featured-bottom">
                              <div className="job-details">
                                <Link
                                  style={{ color: "gray" }}
                                  to={`/viewjob/${candidateDetailsNew.job_post_id.id}`}
                                >
                                  <strong className="featured-title">
                                    Applied For:
                                  </strong>{" "}
                                  {candidateDetailsNew.job_post_id.job_title}
                                </Link>
                                <div>
                                  <strong>Applied Date:</strong>{" "}
                                  {candidateDetailsNew.apply_date?.substring(
                                    0,
                                    10
                                  )}
                                </div>
                              </div>
                              <div className="job-action">
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                  <div className="mt-2 mt-lg-0 d-flex flex-wrap align-items-start gap-1">
                                    <a
                                      href="#"
                                      className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "accepted"
                                        )
                                      }
                                    >
                                      Accepted
                                    </a>
                                    <a
                                      href="#"
                                      className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "rejected"
                                        )
                                      }
                                    >
                                      Rejected
                                    </a>
                                    <a
                                      href="#"
                                      className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                      onClick={() =>
                                        handleStatusClick(
                                          candidateDetailsNew.id,
                                          candidateDetailsNew.user_account_id
                                            .id,
                                          candidateDetailsNew.job_post_id.id,
                                          "pending"
                                        )
                                      }
                                    >
                                      Pending
                                    </a>
                                  </div>
                                  <span
                                    className="badge ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                    //  className="badge  mt-1 p-2 "
                                    style={{
                                      backgroundColor:
                                        candidateDetailsNew.status ===
                                        "accepted"
                                          ? "green"
                                          : candidateDetailsNew.status ===
                                            "rejected"
                                          ? "red"
                                          : "gray",
                                      fontSize: "11px",
                                      borderRadius: "0.3em",
                                    }}
                                  >
                                    {candidateDetailsNew.status
                                      .charAt(0)
                                      .toUpperCase() +
                                      candidateDetailsNew.status.slice(1)}
                                  </span>
                                </div>
                              </div>

                              <div className="view-block">
                                <a
                                  className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                                  href={`/candidate_details/${candidateDetailsNew.user_account_id.id}`}
                                >
                                  View Profile
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {filteredJobs.length === 0 && (
                  <h5 className="fs-15 mt-4 mb-8 text-center">
                    No candidates found
                  </h5>
                )}
                {filteredJobs.length > 0 && (
                  <Navigation
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                )}

                {/* <div className="col-lg-12">
                  <div className="job-pagination-block">
                    <a className="page-nav-link">prev</a>
                    <a className="page-nav-link current">1</a>
                    <a className="page-nav-link" href="#">
                      2
                    </a>
                    <a className="page-nav-link" href="#">
                      3
                    </a>
                    <a className="page-nav-link" href="#">
                      ....
                    </a>
                    <a className="page-nav-link">next</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <AdminFooter />
    </div>
  );
}

export default Candidate_list;
