import React, { useEffect } from "react";
import Slider from "react-slick";
import Header from "../components/layout/Header";

import Footer from "../components/layout/Footer";
import ProgressBar from "react-animated-progress-bar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import CountUp from "react-countup";
import visionimg from "../newimages/OurVision1.jpg";
import missionimg from "../newimages/OurMision1.jpg";
import values from "../images/Values.jpg";
import client1 from "../newimages/Business1_files/01.jpg";
import client2 from "../newimages/Business1_files/02.jpg";
import client3 from "../newimages/Business1_files/03.jpg";
import blog1 from "../newimages/Business1_files/blog-01-600x430.jpg";
import blog2 from "../newimages/Business1_files/blog-02-600x430.jpg";
import blog3 from "../newimages/Business1_files/blog-03-600x430.jpg";

import flipkart from "../newimages/flipkart-ot.png";
import cts from "../newimages/cognizant-ot.png";
import tcs from "../newimages/tcs-ot.png";
import amazon from "../newimages/amazon-ot.png";
import accenture from "../newimages/accenture-ot.png";
import byjus from "../newimages/byjus-ot.png";

import BannerImage from "../newimages/home1banner.jpg"

import { useState } from "react";

import axios from "axios";
import { subURL } from "../utils/URL's";
import { useHistory } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";
import Services01 from "../newimages/services-01.jpg";
import Services03 from "../newimages/services-03.jpg";
import Services02 from "../newimages/services-02.jpg";
import Services04 from "../newimages/services-04.jpg";
import CommonFooter from "../components/layout/CommonFooter";
var USERID;

function Home() {
  const history = useHistory();
  const slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [showViewMoreBtn, setShowViewMore] = useState(false);
  const [fetchJobs, setFetchJobs] = useState([]);

  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobRole, setJobRole] = useState([]);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sendSelectedCountry, setSendSelectedCountry] = useState(null);

  const [companyNameOrJobTitle, setCompanyNameOrJobTitle] = useState("");

  const [jobType, setJobType] = useState("");

  const [userId, setUserId] = useState();

  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
  }, []);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await axios.get(`${subURL}/job_type/`);
   //   const data = await response.json();

      setJobTypeOptions(response.data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    const fetchJobCategories = async () => {
      const response = await axios.get(`${subURL}/jobcategory/`);

      setJobCategories(response.data.slice(0, 8));
    };

    fetchJobCategories();
  }, []);

  useEffect(() => {
    const fetchJobRole = async () => {
      const response = await axios.get(`${subURL}/getjobtitle/`);

      setJobRole(response.data.slice(0, 9));
    };

    fetchJobRole();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/post_job/`)
      .then((response) => {
        setFetchJobs(response.data.slice(0, 4));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     const response = await fetch("https://restcountries.com/v2/all");
  //     const data = await response.json();
  //     const options = data.map((country) => ({
  //       label: country.name,
  //       value: country.alpha2Code,
  //     }));

  //     setCountries(options);
  //   };
  //   fetchCountries();
  // }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v2/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const options = data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
        }));

        setCountries(options);
      } catch (error) {
        console.error("Error fetching countries:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
    fetchCountries();
  }, []);

  const handleChange = (event) => {
    const selectedOption = event.target.value;
    const countryName = event.target.options[event.target.selectedIndex].text;
    setSelectedCountry(selectedOption);
    setSendSelectedCountry(countryName);
  };

  async function handleFormSubmit() {
    const response = await axios.get(
      `${subURL}/filteredjob/${companyNameOrJobTitle}/${sendSelectedCountry}/${jobType}/`
    );

    const filteredJobs = response.data;
    history.push(
      `/search-results/${encodeURIComponent(JSON.stringify(filteredJobs))}`
    );
    setFetchJobs(filteredJobs);

    if (filteredJobs.length > 0) {
      setShowViewMore(true);
    }

    setCompanyNameOrJobTitle("");
    setSelectedCountry("");
    setSendSelectedCountry("");
    setJobType("");
  }

  async function handleFormSubmitLoggedIn(e) {
    // e.preventDefault();

    // const response = await axios.get(
    //   `${subURL}/filteredjob/${companyNameOrJobTitle}/${sendSelectedCountry}/${jobType}/`
    // );
    const reqModel = {
      user_account_id: userId,
    };

    let headers = {
      "Content-Type": "application/json; charset=utf-8",
    };

    const response = await axios.post(
      `${subURL}/filternotappliedjob/${sendSelectedCountry}/${jobType}/${companyNameOrJobTitle}/`,
      reqModel,
      {
        headers: headers,
      }
    );

    const filteredJobs = response.data;
    history.push(
      `/filterjobs/${encodeURIComponent(JSON.stringify(filteredJobs))}`
    );
    setFetchJobs(filteredJobs);

    if (filteredJobs.length > 0) {
      setShowViewMore(true);
    }

    setCompanyNameOrJobTitle("");
    setSelectedCountry("");
    setSendSelectedCountry("");
    setJobType("");
  }

  return (
    <div className="site-main">
      <Header />

      <Slider
        className="slick_slider banner_slider banner_slider_1"
        {...slick_slider}
        slidesToShow={1}
        autoplay={false}
      >
        <div className="slide">
          <div
            className="slide_img"
            style={{
              backgroundImage: `url(${BannerImage})`,
            }}
            // style={{
            //   backgroundImage:
            //     "url(	https://themetechmount.com/react/hireco/images/slides/slider-mainbg-001.jpg)",
            // }}
          ></div>
          <div className="slide__content">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="slide__content--headings ttm-textcolor-white text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <h2 data-animation="animate__fadeInDown">
                        Recruitment & Staffing Agency
                      </h2>
                    </div>
                    <div className="mt-30">
                      <div className="row">
                        <div className="col-lg-12">
                          <p
                            data-animation="animate__fadeInDown"
                            className="mb-30 mb-md-0"
                          >
                            <Container>
                              <form
                                id="b_search_Form"
                                className="b_search_Form wrap-form d-block"
                                method="post"
                                action="#"
                                data-mailchimp="true"
                                onSubmit={(e) => {
                                  e.preventDefault();

                                  if (isLoggedIn) {
                                    handleFormSubmitLoggedIn();
                                  } else {
                                    handleFormSubmit();
                                  }
                                }}
                              >
                                <Row className="equal-height-row">
                                  <Col lg={3} className="form-column">
                                    <label>
                                      <i className="ti ti-search"></i>
                                      <input
                                        type="text"
                                        id="filter"
                                        placeholder="Job Title"
                                        value={companyNameOrJobTitle}
                                        onChange={(e) =>
                                          setCompanyNameOrJobTitle(
                                            e.target.value
                                          )
                                        }
                                      />
                                    </label>
                                  </Col>
                                  <Col lg={3} className="form-column">
                                    <label>
                                      <i className="ti ti-location-pin"></i>

                                      <select
                                        placeholder="Select Country"
                                        value={selectedCountry}
                                        className="form-control"
                                        onChange={handleChange}
                                      >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                          <option
                                            key={country.id}
                                            value={country.value}
                                          >
                                            {country.label}
                                          </option>
                                        ))}
                                      </select>
                                    </label>
                                  </Col>
                                  <Col lg={3} className="form-column">
                                    <label>
                                      <i className="ti ti-list"></i>
                                      <select
                                        name="categories"
                                        aria-hidden="true"
                                        placeholder="Categories"
                                        value={jobType}
                                        onChange={(e) =>
                                          setJobType(e.target.value)
                                        }
                                      >
                                        <option value="">Job Type</option>
                                        {jobTypeOptions?.map((option) => (
                                          <option
                                            key={option.id}
                                            value={option.value}
                                          >
                                            {option.job_type
                                              .charAt(0)
                                              .toUpperCase() +
                                              option.job_type.slice(1)}
                                          </option>
                                        ))}
                                      </select>
                                    </label>
                                  </Col>
                                  <Col lg={3} className="form-column">
                                    <label>
                                      <button
                                        style={{ padding: "1.1em" }}
                                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                        type="submit"
                                      >
                                        {isLoggedIn
                                          ? "Search Jobs"
                                          : "Find Jobs"}
                                      </button>
                                    </label>
                                  </Col>
                                </Row>
                              </form>
                            </Container>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>

      <section className="ttm-row features-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    Get{" "}
                    <span className="text-theme-SkinColor">Appropriate</span>
                  </h3>
                  <h2 className="title">Search Job By Industry</h2>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-equal-height mb_10">
            {jobCategories?.map((category) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="featured-icon-box icon-align-top-content style1">
                  <div className="ttm-box-view-overlay">
                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                      <div
                        className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                        style={{
                          backgroundImage:
                            "url(https://themetechmount.com/react/hireco/images/services/finance-hover.png)",
                        }}
                      ></div>
                      <div className="layer-content"></div>
                    </div>
                  </div>
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                      <img className="img-fluid" src="images/cat-icon1.png" />
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>{category.job_category}</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        85 Jobs<br></br>Financial Branch Manager
                      </p>
                    </div>
                    <a
                      className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark"
                      href={`/filtercategory/${category.job_category}`}
                    >
                      View Jobs!
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg z-index-1 clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-11">
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h3>
                    Prepare for your next{" "}
                    <span className="text-theme-SkinColor">Interviews!</span>
                  </h3>
                  <h2 className="title">Interview questions by company</h2>
                </div>
                <div className="title-desc">
                  <p>
                    We have over 30 years experience of Financial ,Marketing and
                    Busness Solutions expertise, our goal is to maxnci
                    reputation and financial.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Slider
            className="row slick_slider slick-arrows-style2 pt-20 mb_10"
            {...slick_slider}
            rows={2}
            slidesToShow={3}
            arrows={true}
          >
            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/flipkart"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={flipkart} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>Flipkart</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/cognizant"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={cts} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>Cognizant</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/byjus"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={byjus} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>Byjus</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/tcs"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={tcs} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>TCS</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/amazon"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={amazon} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>Amazon</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 ">
              <a
                href="/interviews/accenture"
                class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
              >
                <div class="featured-icon">
                  <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                    <img src={accenture} alt="img" />
                  </div>
                </div>
                <div class="featured-content">
                  <div class="featured-title mt-3">
                    <h3>Accenture</h3>
                  </div>
                  <div class="featured-desc">
                    <p style={{ color: "gray" }}>488 Interviews</p>
                  </div>
                </div>
              </a>
            </div>
          </Slider>
        </div>
      </section>

      <section className="ttm-row job-list-section bg-theme-GreyColor ttm-bg z-index-1 clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-11">
              {/* section title */}
              <div className="section-title style2 mb-0">
                <div className="title-header">
                  <h3>
                    Find The{" "}
                    <span className="text-theme-SkinColor">Best One!</span>
                  </h3>
                  <h2 className="title">Jobs By Positions</h2>
                </div>
                <div className="title-desc">
                  <p>
                    We have over 30 years experience of Financial ,Marketing and
                    Busness Solutions expertise, our goal is to maxnci
                    reputation and financial.
                  </p>
                </div>
              </div>
              {/* section title end */}
            </div>
          </div>
          {/* row end */}
          {/* row */}
          <Slider
            className="row slick_slider slick-arrows-style2 pt-20 mb_10"
            {...slick_slider}
         //   rows={2}
            slidesToShow={3}
            arrows={true}
          >
            {jobRole?.map((role) => (
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a
                  href={`/filterrole/${role.job_title}`}
                  class="featured-icon-box icon-align-before-content icon-ver_align-top style5"
                >
                  <div class="featured-icon">
                    <div class="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                      <i class="flaticon flaticon-sketch"></i>
                    </div>
                  </div>
                  <div class="featured-content">
                    <div class="featured-title">
                      <h3>{role.job_title}</h3>
                    </div>
                    <div class="featured-desc">
                      <p>25 Open Positions</p>
                    </div>
                    <style>{``}</style>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
          {/* row end */}
        </div>
        <style>
          {`.featured-desc{
              color:gray
            }
            `}
        </style>
      </section>

      {isLoggedIn && (
        <section
          className="ttm-row services-section bg-img1 bg-theme-GreyColor ttm-bg ttm-bgimage-yes clearfix"
          style={{ backgroundImage: "url(images/bg-image/row-bgimage-1.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-11">
                <div className="section-title style2 mb-0">
                  <div className="title-header">
                    <h3>
                      Explore{" "}
                      <span className="text-theme-SkinColor">Our Services</span>
                    </h3>
                    <h2 className="title">
                      We Connect Job Seekers and Employers
                    </h2>
                  </div>
                  <div className="title-desc">
                    <p>
                      Our job portal offers a{" "}
                      <span className="text-theme-SkinColor">
                        range of services
                      </span>{" "}
                      to help both job seekers and employers find their perfect
                      match. Whether you are looking for a new opportunity or
                      seeking top talent for your company, we have you covered.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Slider
              className="row slick_slider slick-arrows-style2 pt-20 mb_10"
              {...slick_slider}
              slidesToShow={2}
              rows={2}
              arrows={true}
              autoplay={false}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: { slidesToShow: 2, slidesToScroll: 2 },
                },
                {
                  breakpoint: 575,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ]}
            >
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img src={Services01} alt="services01" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="#">Job Search</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Find the latest job opportunities in various industries
                        and locations. Our comprehensive job search feature
                        makes it easy to discover and apply for your dream job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={Services02} alt="image2" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="/services_details">Employer Services</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Access a range of employer services including posting
                        job listings, managing applications, and finding the
                        right candidates for your company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={Services03} alt="image3" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="/services_details">Resume Writing</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Our resume writing service ensures that your skills and
                        achievements are showcased effectively to impress
                        potential employers.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={Services04} alt="image4" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="/services_details">Career Resources</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Expand your knowledge ,fFrom interview tips to career
                        development advice, we provide the resources you need to
                        succeed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={Services02} alt="image1" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="/services_details">Internship Opportunities</a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Gain practical experience and kickstart your career with
                        our wide range of internship opportunities. Explore
                        industry-specific internships in your field.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-services style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={Services02} alt="image1" />
                  </div>

                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>
                        <a href="/services_details">
                          Skill Development Programs
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Enhance your skills and stay competitive with our skill
                        development programs and workshops in key areas of your
                        expertise.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      )}

      {isLoggedIn && (
        <section className="ttm-row about-section bg-layer-equal-height clearfix">
          <div className="container">
            <div className="row g-0">
              <div className="col-md-6">
                <div className="pb-70 pb-md-50 pr-60 pr-md-0">
                  <div className="section-title">
                    <div className="title-header">
                      <h3>
                        Explore{" "}
                        <span className="text-theme-SkinColor">
                          Opportunities
                        </span>
                      </h3>
                      <h2 className="title">Find Your Dream Job!</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        Discover job openings that match your skills and
                        interests. We are dedicated to helping job seekers like
                        you connect with the right opportunities. Take the next
                        step in your career and start applying today!
                      </p>
                    </div>
                  </div>

                  <div className="mb-md-40 mb-30">
                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          Access a wide range of job listings from top companies
                        </div>
                      </li>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          Easily track the status of your applications in one
                          place
                        </div>
                      </li>
                      <li>
                        <i className="far fa-check-circle"></i>
                        <div className="ttm-list-li-content">
                          Build a comprehensive profile to showcase your skills
                          and experience
                        </div>
                      </li>
                    </ul>
                  </div>
                  <a
                    className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-dark"
                    href="/job_list"
                  >
                    Start Your Job Search!
                  </a>
                </div>
              </div>
              <div className="col-md-6">
                <div className="col-bg-img-one ttm-col-bgimage-yes ttm-bg">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                    style={{
                      backgroundImage:
                        "url(https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-1.jpg)",
                    }}
                  ></div>
                  <div className="layer-content"></div>
                  <img
                    src="	https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-2.jpg"
                    className="img-fluid col-bg-img-res"
                    alt="bgimage"
                  />
                </div>
              </div>
            </div>
            <div className="row g-0">
              <div className="col-md-6">
                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg mt-md-50">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                    style={{
                      backgroundImage:
                        "url(	https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-2.jpg)",
                    }}
                  ></div>
                  <div className="layer-content"></div>
                  <img
                    src="https://via.placeholder.com/570x520?text=570x520+col-bgimage-2.jpg"
                    className="img-fluid col-bg-img-res"
                    alt="bgimage"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="pt-70 pt-md-50 pl-70 pl-md-0">
                  <div className="section-title">
                    <div className="title-header">
                      <h3>
                        Choose <span className="text-theme-SkinColor">any</span>
                      </h3>
                      <h2 className="title">Business!</h2>
                    </div>
                    <div className="title-desc">
                      <p>
                        HireCo has over 30 years experience of Financial,
                        Marketing and Business Solutions expertise. Helps
                        businesses improve their processes, products, services.
                        Serve as the intermediary between businesses.
                      </p>
                    </div>
                  </div>

                  <div className="ttm-progress-bar clearfix">
                    <h3 className="progressbar-title">
                      Recruitment Outsourcing
                    </h3>
                    <ProgressBar rect percentage="90" />
                  </div>

                  <div className="ttm-progress-bar clearfix">
                    <h3 className="progressbar-title">
                      Contingency Recruitment
                    </h3>
                    <ProgressBar rect percentage="70" />
                  </div>

                  <div className="ttm-progress-bar clearfix">
                    <h3 className="progressbar-title">On-demand Recruitment</h3>
                    <ProgressBar rect percentage="80" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isLoggedIn && (
        <section className="ttm-row padding_zero-section bg-layer bg-layer-equal-height mb-70 mb-md-0 clearfix">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-7 col-md-12">
                <div className="col-bg-img-three ttm-bg ttm-col-bgimage-yes ttm-left-span z-index-2">
                  <div
                    className="ttm-col-wrapper-bg-layer ttm-bg-layer"
                    style={{
                      backgroundImage:
                        "url(https://themetechmount.com/react/hireco/images/bg-image/col-bgimage-1.jpg)",
                    }}
                  ></div>

                  <div className="layer-content"></div>
                  <img
                    className="img-fluid col-bg-img-res"
                    src="https://via.placeholder.com/1092x708?text=1092x708+col-bgimage-3.jpg"
                    alt="bgimage"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="ttm-bg ttm-col-bgcolor-yes bg-theme-DarkColor ttm-bg ttm-right-span spacing-1 z-index-1">
                  <div className="ttm-col-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor">
                    <div className="ttm-col-wrapper-bg-layer-inner"></div>
                  </div>
                  <div className="layer-content text-theme-WhiteColor">
                    <div className="section-title">
                      <div className="title-header">
                        <h3>
                          How{" "}
                          <span className="text-theme-SkinColor">
                            we can help you!{" "}
                          </span>
                        </h3>
                        <h2 className="title">
                          Your Trusted Partner in Job Search and Recruitment
                        </h2>
                      </div>
                    </div>

                    <div className="ttm-tabs ttm-tab-style-01">
                      <Tabs>
                        <TabList className="tabs">
                          <Tab className="tab">
                            <a className="tab-1" tabIndex="0">
                              Job Seekers
                            </a>
                          </Tab>
                          <Tab className="tab">
                            <a className="tab-2" tabIndex="0">
                              Employers
                            </a>
                          </Tab>
                          <Tab className="tab">
                            <a className="tab-3" tabIndex="0">
                              Our Mission
                            </a>
                          </Tab>
                        </TabList>
                        <div className="content-tab mb_15">
                          <TabPanel>
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-sm-4">
                                <img
                                  className="img-fluid alignleft"
                                  src={missionimg}
                                  height="200"
                                  width="200"
                                  alt="visonimg"
                                />
                              </div>
                              <div className="col-lg-8 col-md-9 col-sm-8">
                                <div className="pt-15">
                                  <p>
                                    Find your dream job with our job portal.
                                    Create a profile, upload your resume, and
                                    apply directly to the positions that match
                                    your skills and preferences.
                                  </p>
                                  <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Search and apply for job opportunities
                                        easily
                                      </div>
                                    </li>
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Build a professional profile to showcase
                                        your skills and experience
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-sm-4">
                                <img
                                  className="img-fluid alignleft"
                                  src={visionimg}
                                  height="200"
                                  width="200"
                                  alt="visonimg"
                                />
                              </div>
                              <div className="col-lg-8 col-md-9 col-sm-8">
                                <div className="pt-15">
                                  <p>
                                    Simplify your hiring process andBenefit from
                                    our extensive network of job seekers and
                                    streamline your recruitment efforts.
                                  </p>
                                  <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Post and manage job listings
                                        effortlessly
                                      </div>
                                    </li>
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Access a large pool of qualified
                                        candidates
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                          <TabPanel>
                            <div className="row">
                              <div className="col-lg-4 col-md-3 col-sm-4">
                                <img
                                  className="img-fluid alignleft"
                                  src={values}
                                  height="200"
                                  width="200"
                                  alt="visonimg"
                                />
                              </div>
                              <div className="col-lg-8 col-md-9 col-sm-8">
                                <div className="pt-15">
                                  <p>
                                    Our mission is to connect job seekers with
                                    the right opportunities and help employers
                                    find the best talent for their organizations
                                    by facilitating successful career.
                                  </p>
                                  <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-WhiteColor">
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Enable job seekers to find fulfilling
                                        employment
                                      </div>
                                    </li>
                                    <li>
                                      <i className="far fa-check-circle"></i>
                                      <div className="ttm-list-li-content">
                                        Assist employers in finding top talent
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </TabPanel>
                        </div>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="ttm-row testimonial-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title title-style-center_text">
                <div className="title-header">
                  <h3>
                    Our <span className="text-theme-SkinColor">Clients!</span>
                  </h3>
                  <h2 className="title">What Our Client Says!</h2>
                </div>
              </div>
            </div>
          </div>
          <Slider
            className="row slick_slider slick-arrows-style1"
            {...slick_slider}
            slidesToShow={3}
            arrows={true}
          >
            <div className="col-lg-12">
              <div className="testimonials ttm-testimonial-box-view-style1">
                <div className="testimonial-content">
                  <blockquote className="testimonial-text">
                    It has always been so easy to do work with team member at
                    HireCo. It's an much effortless partnership.
                  </blockquote>
                  <div className="star-ratings">
                    <ul className="rating">
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="testimonial-bottom">
                  <div className="testimonial-avatar">
                    <div className="testimonial-img">
                      <img
                        className="img-fluid"
                        src={client1}
                        alt="testimonial-img"
                      />
                    </div>
                  </div>
                  <div className="testimonial-caption">
                    <label>Manager</label>
                    <h3>Alex Jhon Martin</h3>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-lg-12">
              <div className="testimonials ttm-testimonial-box-view-style1">
                <div className="testimonial-content">
                  <blockquote className="testimonial-text">
                    The national award-winning staffing agency that was built
                    with our trademarked tagline, Expect More, We Do.
                  </blockquote>
                  <div className="star-ratings">
                    <ul className="rating">
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="testimonial-bottom">
                  <div className="testimonial-avatar">
                    <div className="testimonial-img">
                      <img
                        className="img-fluid"
                        src={client2}
                        alt="testimonial-img"
                      />
                    </div>
                  </div>
                  <div className="testimonial-caption">
                    <label>Manager</label>
                    <h3>Michal Wincent</h3>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-lg-12">
              <div className="testimonials ttm-testimonial-box-view-style1">
                <div className="testimonial-content">
                  <blockquote className="testimonial-text">
                    This was my first experience working with a recruiter and it
                    has been amazing. I couldn’t have asked for more
                  </blockquote>
                  <div className="star-ratings">
                    <ul className="rating">
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="testimonial-bottom">
                  <div className="testimonial-avatar">
                    <div className="testimonial-img">
                      <img
                        className="img-fluid"
                        src={client3}
                        alt="testimonial-img"
                      />
                    </div>
                  </div>
                  <div className="testimonial-caption">
                    <label>Manager</label>
                    <h3>Rayan Methew</h3>
                  </div>
                </div>
              </div>{" "}
            </div>
            <div className="col-lg-12">
              <div className="testimonials ttm-testimonial-box-view-style1">
                <div className="testimonial-content">
                  <blockquote className="testimonial-text">
                    The national award-winning staffing agency that was built
                    with our trademarked tagline, Expect More, We Do.
                  </blockquote>
                  <div className="star-ratings">
                    <ul className="rating">
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                      <li className="active">
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="testimonial-bottom">
                  <div className="testimonial-avatar">
                    <div className="testimonial-img">
                      <img
                        className="img-fluid"
                        src={client3}
                        alt="testimonial-img"
                      />
                    </div>
                  </div>
                  <div className="testimonial-caption">
                    <label>Manager</label>
                    <h3>Michal Wincent</h3>
                  </div>
                </div>
              </div>{" "}
            </div>
          </Slider>
        </div>
      </section>

      {isLoggedIn && (
        <section
          className="ttm-row about-section bg-img2 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix"
          style={{
            backgroundImage:
              'url("https://themetechmount.com/react/hireco/images/bg-image/row-bgimage-2.jpg")',
          }}
        >
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xl-5 col-lg-4 col-md-12">
                <div className="section-title">
                  <div className="title-header">
                    <h3>
                      More than 50,000{" "}
                      <span className="text-theme-SkinColor">happy client</span>
                    </h3>
                    <h2 className="title">
                      The Most Trusted and Professional Recruitment{" "}
                      <span className="text-theme-SkinColor"> Agency</span>
                    </h2>
                  </div>
                </div>

                <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white mt-15"
                  href="/candidate_list"
                >
                  Hiring Now!
                </a>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                <div className="pt-lg-30">
                  <div className="ttm-fid inside ttm-fid-view-topicon style1">
                    <div className="ttm-fid-icon-wrapper">
                      <i className="flaticon flaticon-recruiting"></i>
                    </div>
                    <div className="ttm-fid-contents">
                      <h4>
                        <CountUp start={0} end={2887} duration={20} delay={2} />
                      </h4>
                      <h3 className="ttm-fid-title">Happy Clients</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-8 col-sm-7">
                <div className="pt-lg-20 pb-30">
                  <p className="text-theme-WhiteColor">
                    We have over 30 years experience of Financial, Musiness
                    Solutions expertise, our goal imize your.
                  </p>
                </div>

                <div className="ttm-progress-bar style2 clearfix">
                  <h3 className="progressbar-title">Consulting Experience</h3>
                  <ProgressBar rect percentage="90" />
                </div>

                <div className="ttm-progress-bar style2 clearfix">
                  <h3 className="progressbar-title">Investment Plan</h3>
                  <ProgressBar rect percentage="85" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="pt-100 pt-md-0"></div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isLoggedIn && (
        <section className="ttm-row padding_zero-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="pt-5 bg-theme-SkinColor mt_100 mt-md-60"></div>
                <div className="bg-theme-WhiteColor box-shadow pt-md-40 pb-md-30">
                  <div className="row g-0 ttm-vertical_sep">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon style2">
                        <div className="ttm-fid-icon-wrapper">
                          <i className="flaticon flaticon-headhunting"></i>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4>
                            <CountUp
                              start={0}
                              end={8705}
                              duration={20}
                              delay={2}
                            />
                          </h4>
                          <h3 className="ttm-fid-title">
                            Welath Of Experiance
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon style2">
                        <div className="ttm-fid-icon-wrapper">
                          <i className="flaticon flaticon-technical-support"></i>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4>
                            <CountUp
                              start={0}
                              end={480}
                              duration={20}
                              delay={2}
                            />
                          </h4>
                          <h3 className="ttm-fid-title">
                            Professional Recruiters
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon style2">
                        <div className="ttm-fid-icon-wrapper">
                          <i className="flaticon flaticon-recruitment-4"></i>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4>
                            <CountUp
                              start={0}
                              end={6260}
                              duration={20}
                              delay={2}
                            />
                          </h4>
                          <h3 className="ttm-fid-title">
                            No. of Candidates Hired
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                      <div className="ttm-fid inside ttm-fid-view-topicon style2">
                        <div className="ttm-fid-icon-wrapper">
                          <i className="flaticon flaticon-recruitment-3"></i>
                        </div>
                        <div className="ttm-fid-contents">
                          <h4>
                            <CountUp
                              start={0}
                              end={9774}
                              duration={20}
                              delay={2}
                            />
                          </h4>
                          <h3 className="ttm-fid-title">Larger Community</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {isLoggedIn && (
        <section className="ttm-row blog-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      Explore{" "}
                      <span className="text-theme-SkinColor">Insights</span>
                    </h3>
                    <h2 className="title">Career Advice & Tips</h2>
                  </div>
                </div>
              </div>
            </div>

            <Slider
              className="row slick_slider mb_10"
              {...slick_slider}
              slidesToShow={3}
              arrows={false}
              autoplay={false}
            >
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={blog1} alt="blog-image" />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>

                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Career Advice
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href="/blog_details">
                          Mastering the Art of Job Interviews
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Get valuable tips and techniques to ace your job
                        interviews and stand out from the competition.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={blog2} alt="blogimage" />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>

                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Resume Tips
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href="/blog_details">
                          Crafting an Effective Resume for Job Applications
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Learn how to create a compelling resume that grabs the
                        attention of recruiters and gets you noticed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={blog3} alt="blogimage" />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>

                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Career Growth
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href="/blog_details">
                          Strategies for Advancing Your Career
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Discover effective strategies and techniques to
                        accelerate your career growth and achieve your
                        professional goals.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="featured-imagebox featured-imagebox-post style1">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={blog1} alt="blogimage" />
                    <div className="ttm-box-post-date">
                      <span className="ttm-entry-date">
                        <time
                          className="entry-date"
                          dateTime="2021-03-18T04:16:25+00:00"
                        >
                          18
                          <span className="entry-month entry-year">Mar</span>
                        </time>
                      </span>
                    </div>
                  </div>

                  <div className="featured-content">
                    <div className="post-meta">
                      <span className="ttm-meta-line byline">Admin</span>
                      <span className="ttm-meta-line category-link">
                        Job Search Tips
                      </span>
                    </div>
                    <div className="featured-title">
                      <h3>
                        <a href="/blog_details">
                          Effective Strategies for Job Hunting
                        </a>
                      </h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        Discover proven strategies and techniques to enhance
                        your job search and secure your dream job.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      )}

      <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="d-md-flex align-items-center justify-content-between">
                <div className="featured-icon-box icon-align-before-content style2">
                  <div className="featured-icon">
                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                      <i className="flaticon flaticon-recruitment"></i>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title">
                      <h3>Incredible Recruitment & Staffing Agency</h3>
                    </div>
                    <div className="featured-desc">
                      <p>
                        We have over 30 years experience oft Business porro
                        qusquam dol ipsum quia dolor sit amet.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {isLoggedIn ? <Footer /> : <CommonFooter />}
    </div>
  );
}

export default Home;
