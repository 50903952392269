import {
  MDBCollapse,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBHamburgerToggler,
  MDBNavItem,
  MDBNavbar,
  MDBNavbarNav,
} from "mdbreact";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const EmployerMobile_menu = () => {
  const [collapse1, setCollapse1] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEmployerLogout, setIsEmployerLogout] = useState(false);

  const toggleSingleCollapse = () => {
    setCollapse1(!collapse1);
  };
  const logoutHandler = () => {
    (async () => {
      try {
        await localStorage.removeItem("userid");
        await localStorage.removeItem("isEmployer");
        await localStorage.removeItem("username");
        await localStorage.removeItem("usertypeid");

        setTimeout(() => {
          window.location.reload();
        }, 100);
        setIsEmployerLogout(true);
      } catch (error) {
        console.log(error);
      }
    })();
  };
  useEffect(() => {
    if (isEmployerLogout) {
      window.location.href = "/"; // Redirect to login page
    }
  }, [isEmployerLogout]);
  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler id="hamburger1" onClick={toggleSingleCollapse} />
        <MDBCollapse isOpen={collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/employer" nav caret>
                  Home
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Jobs
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href="/postjobemployer">
                    Post Job
                  </MDBDropdownItem>
                  <MDBDropdownItem href="/viewemployerJobs">
                    Job List
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle nav caret>
                  Company
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem href="/addcompanyasemployer">
                    Add Company
                  </MDBDropdownItem>
                  <MDBDropdownItem href="/viewcompanyasemployer">
                    Company Details
                  </MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/employercandidatelist" nav caret>
                  Candidate List
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/employerprofile" nav caret>
                  My Profile
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem onClick={() => setShowModal(true)}>
                  Logout
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            {/* <MDBNavItem>
          <MDBDropdown>
            <MDBDropdownToggle nav caret>
              Blog
            </MDBDropdownToggle>
            <MDBDropdownMenu>
              <MDBDropdownItem href="/blog_classic">
                Blog Classic
              </MDBDropdownItem>
              <MDBDropdownItem href="/blog_grid">Blog Grid</MDBDropdownItem>
              <MDBDropdownItem href="/blog_details">
                Blog Details
              </MDBDropdownItem>
            </MDBDropdownMenu>
          </MDBDropdown>
        </MDBNavItem> */}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Logout !</ModalHeader>
        <ModalBody>
          <h6>Are you sure you want to logout ?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="grey" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button color="primary" onClick={logoutHandler}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Router>
  );
};

export default EmployerMobile_menu;
