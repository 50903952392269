import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { imageURL, mainURL, subURL } from "../../../utils/URL's";

import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";
import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";

const ViewEmployerJobDetails = () => {
  const { id } = useParams();

  const [jobDetails, setJobDetails] = useState(null);
  const [compImage, setCompanyImage] = useState("");
  const [fetchSkills, setFetchSkills] = useState([]);

  useEffect(() => {
    const fetchAppliedStatus = async () => {
      const res = await axios.get(`${subURL}/post_job/${id}/`);

      setJobDetails(res.data);

      setCompanyImage(res.data?.company_id?.company_image);
    };

    fetchAppliedStatus();
  }, [id]);

  useEffect(() => {
    const fetchSkills = async () => {
      const res = await axios.get(`${subURL}/fetchJobSkills/${id}/`);

      setFetchSkills(res.data);
    };

    fetchSkills();
  }, [id]);

  return (
    <div className="site-main">
      <EmployerHeader />

      {/* PageHeader */}
      <EmployerPageHeader title="job details" breadcrumb="job" />
      {/* PageHeader end */}

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          {/* row */}
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget job-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>Job Informations
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">Job Type:</b>{" "}
                      {jobDetails?.job_type_id?.job_type
                        .charAt(0)
                        .toUpperCase() +
                        jobDetails?.job_type_id?.job_type.slice(1)}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Location:</b>{" "}
                      {jobDetails?.job_location_id?.country}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Offered Salary:</b>Rs.
                      {jobDetails?.salary}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Posted:</b>{" "}
                      {jobDetails?.createdDate?.substring(0, 10)}
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Experience:</b>{" "}
                      {jobDetails?.experince_type_id?.experince_type}
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  {/* featured-imagebox */}
                  <div className="featured-imagebox featured-imagebox-job bg-theme-GreyColor m-0">
                    <div className="featured-thumbnail">
                      <img src={`${imageURL}${compImage}`} alt="Job1" />
                    </div>
                    <div className="featured-content">
                      <div className="featured-title">
                        <h3>{jobDetails?.job_title}</h3>
                      </div>
                      <div className="featured-desc">
                        <p>
                          Published on{" "}
                          {jobDetails?.createdDate?.substring(0, 10)}
                        </p>
                      </div>
                      <div className="featured-bottom">
                        <div className="job-meta">
                          {jobDetails?.job_location_id?.country && (
                            <span>
                              <i className="fa fa-map-marker-alt"></i>{" "}
                              {jobDetails?.job_location_id?.country}
                            </span>
                          )}
                          <span>
                            Company Name: {jobDetails?.company_id?.company_name}
                          </span>
                        </div>
                        <div className="job-time">
                          <span className="green">
                            {" "}
                            {jobDetails?.job_type_id?.job_type}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* featured-imagebox end */}
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="overview-box">
                    <div className="title">
                      <h5>Job Description :</h5>
                    </div>
                    <div className="desc">
                      <p style={{ wordWrap: "break-word", maxWidth: "700px" }}>
                        {jobDetails?.job_description}
                      </p>
                    </div>
                  </div>

                  <div className="overview-box">
                    <div className="title">
                      <h5>Skills Required</h5>
                    </div>

                    {fetchSkills.length > 0 ? (
                      fetchSkills.map((skill) => (
                        <div className="" style={{ margin: "1em" }}>
                          <ul className="ttm-list ttm-list-style-icon ttm-textcolor-darkgrey">
                            <li>
                              <i className="ti ti-check-box"></i>
                              <div className="ttm-list-li-content">
                                {skill.skill_set_id.skill_set_name}
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))
                    ) : (
                      <p className="text-center fs-16 mt-3 mb-3">
                        No skills found.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
      </div>

      <EmployerFooter />
    </div>
  );
};

export default ViewEmployerJobDetails;
