import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { subURL } from "../../../utils/URL's";
import axios from "axios";
import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";
var USERID, USERTYPEID;
const EmployerPostJobs = () => {
  const history = useHistory();
  const [jobTitle, setJobTitle] = useState("");
  const [jobType, setJobType] = useState("");
  const [skillLevel, setSkillLevel] = useState("");
  const [skillName, setSkillName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");

  const [zip, setZip] = useState("");
  const [isCompanyNameHidden, setIsCompanyNameHidden] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [jobDescription, setJobDescription] = useState("");

  const [jobTypeOptions, setJobTypeOptions] = useState([]);
  const [skillSetOptions, setSkillSetOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);

  const [jobcategories, setJobCategories] = useState([]);
  const [selectedJobCategory, setSelectedJobCategory] = useState(null);

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [sendSelectedCountry, setSendSelectedCountry] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  // const [skillSetOptions, setSkillSetOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [salary, setSalary] = useState(0);

  const [expType, setExpType] = useState("");
  const [experience, setExperience] = useState([]);
  const [userId, setUserId] = useState("");
  const [userTypeId, setUserTypeId] = useState("");

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  async function getUserTypeID() {
    try {
      USERTYPEID = await localStorage.getItem("usertypeid");

      if (USERTYPEID !== null) {
        setUserTypeId(USERTYPEID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserTypeID();

  useEffect(() => {
    getUserTypeID();
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/jobcategory`)
      .then((response) => {
        setJobCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/company_save_details`)
      .then((response) => {
        setCompanies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchSkillSetOptions = async () => {
      const response = await fetch(`${subURL}/addskill/`);
      const data = await response.json();

      setSkillSetOptions(data);
    };

    fetchSkillSetOptions();
  }, []);

  useEffect(() => {
    const fetchSkillSetOptions = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchSkillSetOptions();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/skillset/`)
      .then((response) => {
        setSkillSetOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching skills:", error);
      });
  }, []);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v2/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const options = data.map((country) => ({
          label: country.name,
          value: country.alpha2Code,
        }));

        setCountries(options);
      } catch (error) {
        console.error("Error fetching countries:", error);
        // Handle the error gracefully, e.g., show a message to the user
      }
    };
    fetchCountries();
  }, []);

  const handleChange = (selectedOption) => {
    const countryName = selectedOption ? selectedOption.label : "";
    setSelectedCountry(selectedOption);
    setSendSelectedCountry(countryName);
  };
  const handleChangeCompany = (event) => {
    setSelectedCompanyId(event.target.value);
  };
  const handleChangeJobCategory = (event) => {
    setSelectedJobCategory(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const pincode1 = zip.toString();
    if (pincode1.length < 6) {
      alert("Pincode has to be 6 digits long");
      return;
    }
    const selectedSkillNames = selectedSkills
      .map((skill) => skill.id)
      .join(",");
    async function storeData() {
      const formData = {
        job_title: jobTitle,
        job_type_id: jobType,
        company_id: selectedCompanyId,
        is_company_name_hidden: isCompanyNameHidden,
        experince_type_id: expType,
        job_description: jobDescription,
        is_active: isActive,
        job_category_id: selectedJobCategory,
        skill_set_id: selectedSkillNames,
        skill_level: skillLevel,
        street_address: streetAddress,
        city: city,
        state: state,
        country: sendSelectedCountry,
        zip: zip,
        user_account_id: userId,
        salary: salary,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/post_job/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success!");
          setSuccessMessage("Posted job successfully!");
          setShowModal(true);
          setTimeout(() => {
            history.push("/viewemployerJobs");
          }, 1000);
        } else {
          console.log("error");
        }
      } catch (e) {
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
        console.log(e);
      }
    }
    storeData();
    setJobType("");
    setSkillName("");
    setSkillLevel("");
    setStreetAddress("");
    setCity("");
    setState("");
    setCountries("");
    setZip("");
    setJobDescription("");
    setSelectedCountry("");
    setJobTitle("");
    setIsActive(false);
    setIsCompanyNameHidden(false);
    setCompanies([]);
    setJobCategories([]);
    setExpType("");
    setSalary("");
  };
  const handleSelectSkill = (e) => {
    const selectedSkill = skillSetOptions.find(
      (skill) => skill.id === parseInt(e.target.value)
    );
    if (!selectedSkills.includes(selectedSkill)) {
      setSelectedSkills([...selectedSkills, selectedSkill]);
    }
  };
  const handleRemoveSkill = (skillToRemove) => {
    setSelectedSkills(
      selectedSkills.filter((skill) => skill !== skillToRemove)
    );
  };
  return (
    <>
      <div className="site-main">
        <EmployerHeader />
        <EmployerPageHeader
          title="Job Post"
          breadcrumb="post job"
          className="pb-65 pb-lg-0"
        />

        <section className="ttm-row form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      fill the{" "}
                      <span className="text-theme-SkinColor"> details</span>
                    </h3>
                    <h2 className="title">Post Your dream Jobs</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container>
            <Row>
              <Col lg={12}>
                <div className="bg-soft-primary p-3">
                  <h5 className="mb-0 fs-17">Post a New Job!</h5>
                </div>
              </Col>
            </Row>
            <form
              action="#"
              className="job-post-form shadow mt-4"
              onSubmit={handleSubmit}
            >
              <div className="job-post-content box-shadow-md rounded-3 p-4">
                <Row className="row">
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobtitle">Job Title</Label>
                      <Input
                        required
                        type="text"
                        name="jobtitle"
                        id="jobtitle"
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobType">Job Type</Label>
                      <Input
                        required
                        type="select"
                        name="jobType"
                        id="jobType"
                        value={jobType}
                        onChange={(e) => setJobType(e.target.value)}
                      >
                        <option value="">Select Job Type</option>
                        {jobTypeOptions.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.job_type.charAt(0).toUpperCase() +
                              option.job_type.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="exptype">Experience Type</Label>
                      <Input
                        required
                        type="select"
                        name="exptype"
                        id="exptype"
                        value={expType}
                        onChange={(e) => setExpType(e.target.value)}
                      >
                        <option value="">Select Experience Type</option>
                        {experience.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.experince_type.charAt(0).toUpperCase() +
                              option.experince_type.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="salary">Salary</Label>
                      <Input
                        required
                        type="number"
                        name="salary"
                        id="salary"
                        value={salary}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value, 10);
                          if (enteredValue >= 0) {
                            setSalary(enteredValue);
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div>
                      <label htmlFor="skillname">Select Skills:</label>
                      <select
                        id="skillname"
                        onChange={handleSelectSkill}
                        required
                      >
                        <option value="">Select Skill Name</option>
                        {skillSetOptions.map((skill) => (
                          <option key={skill.id} value={skill.id}>
                            {skill.skill_set_name}
                          </option>
                        ))}
                      </select>
                      <div>
                        {selectedSkills.map((skill) => (
                          <div key={skill.id} className="skill-chip">
                            {skill.skill_set_name}
                            <button onClick={() => handleRemoveSkill(skill)}>
                              x
                            </button>
                          </div>
                        ))}
                      </div>
                      <style jsx>{`
                        .skill-chip {
                          display: inline-block;
                          padding: 5px;
                          margin: 5px;
                          background-color: #eee;
                        }
                      `}</style>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="skillLevel">Skill Level</Label>
                      <Input
                        required
                        type="select"
                        name="skillLevel"
                        id="skillLevel"
                        value={skillLevel}
                        onChange={(e) => setSkillLevel(e.target.value)}
                      >
                        <option value="">Select Skill Level</option>
                        <option value="1"> 1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Input>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-4">
                      <Label for="streetAddress">Street Address</Label>
                      <Input
                        required
                        type="text"
                        name="streetAddress"
                        id="streetAddress"
                        value={streetAddress}
                        onChange={(e) => setStreetAddress(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-4">
                      <Label for="country">Country</Label>

                      <Select
                        required
                        placeholder="Select Country"
                        options={countries}
                        value={selectedCountry}
                        onChange={handleChange}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-4">
                      <Label for="state">State</Label>
                      <Input
                        required
                        type="text"
                        name="state"
                        id="state"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="city">City</Label>
                      <Input
                        required
                        type="text"
                        name="city"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="zip">Zip</Label>
                      <Input
                        required
                        type="number"
                        name="zip"
                        id="zip"
                        maxLength={6}
                        value={zip}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value, 10);
                          if (enteredValue >= 0 && e.target.value.length <= 6) {
                            setZip(enteredValue);
                          }
                        }}
                      />
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobDescription">Company Name</Label>
                      <Input
                        required
                        type="select"
                        name="companyname"
                        id="companyname"
                        // value={companies}
                        onChange={handleChangeCompany}
                      >
                        <option value="">Select Company Name</option>
                        {companies?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.company_name.charAt(0).toUpperCase() +
                              option.company_name.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="mb-4">
                      <Label for="jobcategory">Job Category</Label>
                      <Input
                        required
                        type="select"
                        name="jobcategory"
                        id="jobcategory"
                        // value={companies}
                        onChange={handleChangeJobCategory}
                      >
                        <option value="">Select Job Category</option>
                        {jobcategories?.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.job_category.charAt(0).toUpperCase() +
                              option.job_category.slice(1)}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </Col>

                  <Col lg={6} style={{ marginTop: "3%" }}>
                    <div className="mb-4">
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={isActive}
                          onChange={(e) => setIsActive(e.target.checked)}
                        />{" "}
                        Job is Active
                      </Label>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="mb-4">
                      <Label for="jobDescription">Job Description</Label>
                      <Input
                        rows={10}
                        required
                        type="textarea"
                        name="jobDescription"
                        id="jobDescription"
                        value={jobDescription}
                        onChange={(e) => setJobDescription(e.target.value)}
                      />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
                      {/* <Link to="/employer" className="btn btn-grey">
                        Back
                      </Link> */}
                      <button
                        className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </form>

            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                {modalTitle}
              </ModalHeader>
              <ModalBody>{successMessage}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => setShowModal(false)}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </section>
        <EmployerFooter />
      </div>
    </>
  );
};

export default EmployerPostJobs;
