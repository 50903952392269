import React from "react";

const AdminFooter = () => {
  return (
    <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
      <div className="second-footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
              <div className="widget widget_text clearfix">
                <div className="footer-logo">
                  {/* <img
                    id="footer-logo-img"
                    className="img-fluid auto_size"
                    height="40"
                    width="162"
                    src="images/footer-logo.svg"
                    alt="footer-logo"
                  /> */}
                  <a
                    className="home-link"
                    href="/admin"
                    title="Hireco"
                    rel="home"
                  >
                    <h5 className="title text-white">wokwantaim.com</h5>
                  </a>
                </div>
                <p>
                  wokwantaim.com is a multi award-winning executive search,
                  management recruiter and employer branding organization. Our
                  customized recruiting process delivers fast & accurate results
                </p>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
              <div className="widget widget-recent-post clearfix">
                <h3 className="widget-title">Quick links</h3>
                <ul className="widget-post ttm-recent-post-list">
                  <li>
                    <div className="post-detail">
                      <div className="cat-link">
                        <a href="/postjob">Post Job</a>
                      </div>
                      <a href="/postjob">Post Job !</a>
                    </div>
                  </li>
                  <li>
                    <div className="post-detail">
                      <div className="cat-link">
                        <a href="/addcompany">Add Company</a>
                      </div>
                      <a href="/addcompany">Add Company details !</a>
                    </div>
                  </li>
                  <li>
                    <div className="post-detail">
                      <div className="cat-link">
                        <a href="/candidate_list">Candidate List</a>
                      </div>
                      <a href="/candidate_list">View Candidate List !</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
              <div className="widget widget-contact clearfix">
                <h3 className="widget-title">Quick Contact!</h3>
                <ul className="widget_contact_wrapper">
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                    <h3>Call Us On:</h3>+92 777 555 0000
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                    <h3>Address:</h3>Suite 20 Golden Street USA
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-email"></i>
                    <h3>Email:</h3>
                    <a href="mailto:info@example.com">info@example.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <span className="copyright">
                Copyright © 2021 <a href="/admin">Recruitment </a>All rights
                reserved.
              </span>
              <ul className="footer-nav-menu">
                <li>
                  <a href="/admin">Privacy & Policy</a>
                </li>
                <li>
                  <a href="/admin">Terms & Conditions</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AdminFooter;
