import React from "react";

import AdminLogo from "./AdminLogo";
import AdminNavBar from "./AdminNavBar";
import AdminMenu from "./AdminMenu";
import AdminMobile_menu from "./AdminMobile_menu";

const AdminHeader = () => {
  return (
    <header id="masthead" className="header ttm-header-style-01">
   
      <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
        <div className="site-header-menu-inner ttm-stickable-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
           
                <div className="site-navigation d-flex align-items-center justify-content-between">
                 
                  <div className="site-branding">
                    <AdminLogo />
                  </div>
             
                  <div className="border-box-block">
                    <div className=" d-flex align-items-center justify-content-between">
                    
                      <AdminNavBar />
                      <div className="mobile-menu">
                        <AdminMobile_menu />
                      </div>
                     
                    </div>
                  </div>
                
                  <div className="header_extra ml-auto d-flex align-items-center">
                   
                    <AdminMenu />
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* site-header-menu end */}
    </header>
  );
};

export default AdminHeader;
