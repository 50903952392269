import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./css/vendor/flaticon.css";
import "./css/vendor/fontawesome.min.css";
import "./css/vendor/themify-icons.css";

import Home from "./pages/Home";
import Home2 from "./pages/Home2";
import Home3 from "./pages/Home3";

import Contact_01 from "./pages/Contact_01";
import Contact_02 from "./pages/Contact_02";
import Job_list from "./pages/Job_list";
import Job_details from "./pages/Job_details";

import Candidate_list from "./pages/AdminPanel/CandidateList/Candidate_list";

import Login from "./pages/Login";
import Register from "./pages/Register";

import PostJob from "./pages/AdminPanel/PostJob/PostJob";
import ViewJobs from "./pages/AdminPanel/ViewJobs/ViewJobs";
import AddCompany from "./pages/AdminPanel/AddCompany/AddCompany";
import AddSkills from "./pages/AdminPanel/AddSkills/AddSkills";
import CompanyDetails from "./pages/AdminPanel/AddCompany/CompanyDetails";
import AdminProfile from "./pages/AdminPanel/AdminProfile/AdminProfile";
import MyProfile from "./pages/MyProfile/MyProfile";
import AddAdmin from "./pages/AdminPanel/AddAdmin/AddAdmin";
import MyJobs from "./pages/MyJobs/MyJobs";
import CandidateProfile from "./pages/AdminPanel/CandidateList/CandidateDetails/MyProfile/CandidateProfile";
import AddBusinessStream from "./pages/AdminPanel/AddBusinessStream/AddBusinessStream";
import ViewJobDetails from "./pages/AdminPanel/ViewJobs/ViewJobDetails";
import ViewEmployerJobDetails from "./pages/EmployerLogin/ViewJobs/ViewEmployerJobDetails";

import EmployerAddCompany from "./pages/EmployerLogin/EmployerAddCompany/EmployerAddCompany";
import ViewJobsEmployer from "./pages/EmployerLogin/ViewJobs/ViewJobsEmployer";
import CompanyDetailsEmployer from "./pages/EmployerLogin/EmployerAddCompany/CompanyDetailsEmployer";
import EmployerAddSkills from "./pages/EmployerLogin/EmployerAddSkills/EmployerAddSkills";
import EmployerAddBusinessStream from "./pages/EmployerLogin/EmployerAddBusinessStream/EmployerAddBusinessStream";
import EmployerProfile from "./pages/EmployerLogin/EmployerProfile/EmployerProfile";
import AddEmployer from "./pages/EmployerLogin/AddEmployer/AddEmployer";
import EmployerCandidateList from "./pages/EmployerLogin/EmployerCandidateList/EmployerCandidateList";
import EmployerCandidateProfile from "./pages/EmployerLogin/EmployerCandidateList/EmployerCandidateDetails/MyProfile/EmployerCandidateProfile";
import EmployerPostJobs from "./pages/EmployerLogin/EmployerPostJobs/EmployerPostJobs";
import Blog_details from "./pages/Blog_details";

import AllJobs from "./pages/AllJobs";
import FilterCategory from "./pages/FilterCategory";
import SearchResult from "./pages/SearchResult";
import FIlterJobs from "./pages/FilterJobs";
import FilterCategorywise from "./pages/AdminPanel/ViewJobs/FilterCategorywise";
import AddCategory from "./pages/AdminPanel/AddCategory/AddCategory";
import EmployerAddJobCategory from "./pages/EmployerLogin/EmployerAddJobCategory/EmployerAddJobCategory";
import FilterRole from "./pages/FilterRole";
import Services_details from "./pages/Services_details";
import FilterRolewise from "./pages/AdminPanel/ViewJobs/FilterRolewise";
import CompanyDetailsPage from "./pages/CompanyDetailsPage";
import FilterCategoryCompany from "./pages/FilterCategoryCompany";
import ViewAllCandidates from "./pages/EmployerLogin/ViewAllCandidates/ViewAllCandidates";
import ViewAllCandidatesAdmin from "./pages/AdminPanel/ViewAllCandidates/ViewAllCandidatesAdmin";
import Error_404 from "./pages/Error_404";
import Error_405 from "./pages/Error_405";
import Error_406 from "./pages/Error_406";
import { Switch } from "react-router-dom/cjs/react-router-dom.min";

// function App() {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isLoggedIn, setIsLoggedIn] = useState(
//     localStorage.getItem("userid") !== null
//   );
//   const [isAdmin, setIsAdmin] = useState(
//     localStorage.getItem("isAdmin") === "true"
//   );

//   const [isEmployee, setIsEmployee] = useState(
//     localStorage.getItem("isEmployer") === "true"
//   );

//   useEffect(() => {
//     setIsLoggedIn(localStorage.getItem("userid") !== null);
//     setIsAdmin(localStorage.getItem("isAdmin") === "true");
//     setIsEmployee(localStorage.getItem("isEmployer") === "true");
//   }, []);

//   const Loader = () => {
//     return <div>Loading...</div>;
//   };

//   useEffect(() => {
//     // Simulating an asynchronous call to check admin authentication
//     setTimeout(() => {
//       setIsLoading(false);
//     }, 2000); // Adjust the duration as needed
//   }, []);

//   return (
//     <div className="page">
//       <Router>
//         <Switch>
//           <Route exact path="/">
//             {isLoggedIn ? (
//               isAdmin ? (
//                 <Redirect to="/admin" />
//               ) : isEmployee ? (
//                 <Redirect to="/employer" />
//               ) : (
//                 <Redirect to="/home" />
//               )
//             ) : (
//               <Login setLoggedIn={setIsLoggedIn} />
//             )}
//           </Route>

//           <Route exact path="/home">
//             {isLoggedIn ? <Home /> : <Redirect to="/" />}
//           </Route>

//           {/* User routes */}

//           {isLoggedIn && !isAdmin && !isEmployee && (
//             <Switch>
//               <Route exact path="/contactus" component={Contact_01} />
//               <Route exact path="/Job_list" component={Job_list} />
//               <Route exact path="/jobdetails/:id" component={Job_details} />
//               <Route exact path="/myprofile" component={MyProfile} />
//               <Route exact path="/myjobs" component={MyJobs} />
//               <Route path="*" component={Error_404} />
//             </Switch>
//           )}

//           {/* Admin routes */}

//           {isLoggedIn && isAdmin && (
//             <Switch>
//               <Route exact path="/admin">
//                 <Home3 />
//               </Route>
//               <Route exact path="/postjob" component={PostJob} />
//               <Route exact path="/Candidate_list" component={Candidate_list} />
//               <Route exact path="/contact_us" component={Contact_02} />
//               <Route exact path="/viewpostedjobs" component={ViewJobs} />
//               <Route exact path="/addcompany" component={AddCompany} />
//               <Route exact path="/addskills" component={AddSkills} />
//               <Route exact path="/companydetails" component={CompanyDetails} />
//               <Route exact path="/adminprofile" component={AdminProfile} />
//               <Route exact path="/addadmin" component={AddAdmin} />
//               <Route
//                 exact
//                 path="/Candidate_details/:id"
//                 component={CandidateProfile}
//               />

//               <Route
//                 exact
//                 path="/addbusinesstream"
//                 component={AddBusinessStream}
//               />
//               <Route exact path="/viewjob/:id" component={ViewJobDetails} />
//               <Route path="*" component={Error_405} />
//             </Switch>
//           )}

//           {/* {isLoading ? (
//             <Loader />
//           ) : (
//             <>
//               {isLoggedIn && isAdmin ? (
//                 <Switch>
//                   <Route exact path="/admin">
//                     <Home3 />
//                   </Route>
//                   <Route exact path="/postjob" component={PostJob} />
//                   <Route
//                     exact
//                     path="/Candidate_list"
//                     component={Candidate_list}
//                   />
//                   <Route exact path="/contact_us" component={Contact_02} />
//                   <Route exact path="/viewpostedjobs" component={ViewJobs} />
//                   <Route exact path="/addcompany" component={AddCompany} />
//                   <Route exact path="/addskills" component={AddSkills} />
//                   <Route
//                     exact
//                     path="/companydetails"
//                     component={CompanyDetails}
//                   />
//                   <Route exact path="/adminprofile" component={AdminProfile} />
//                   <Route exact path="/addadmin" component={AddAdmin} />
//                   <Route
//                     exact
//                     path="/Candidate_details/:id"
//                     component={CandidateProfile}
//                   />
//                   <Route
//                     exact
//                     path="/addbusinesstream"
//                     component={AddBusinessStream}
//                   />
//                   <Route exact path="/viewjob/:id" component={ViewJobDetails} />
//                   <Route path="*" component={Error_405} />
//                 </Switch>
//               ) : (
//                 <div>You are not authorized to access this page.</div>
//               )}
//             </>
//           )} */}

//           {isLoggedIn && isEmployee && (
//             <>
//               <Route exact path="/employer">
//                 <Redirect to="/employer" />
//               </Route>
//               <Route exact path="/employer" component={Home2} />
//               <Route
//                 exact
//                 path="/employerviewjob/:id"
//                 component={ViewEmployerJobDetails}
//               />

//               <Route
//                 exact
//                 path="/postjobasemployer"
//                 component={EmployerPostJobs}
//               />
//               <Route
//                 exact
//                 path="/addcompanyasemployer"
//                 component={EmployerAddCompany}
//               />
//               <Route
//                 exact
//                 path="/viewemployerJobs"
//                 component={ViewJobsEmployer}
//               />
//               <Route
//                 exact
//                 path="/viewcompanyasemployer"
//                 component={CompanyDetailsEmployer}
//               />
//               <Route
//                 exact
//                 path="/addskillsasemployer"
//                 component={EmployerAddSkills}
//               />
//               <Route
//                 exact
//                 path="/addbusinessstreamasemployer"
//                 component={EmployerAddBusinessStream}
//               />
//               <Route
//                 exact
//                 path="/employercandidatelist"
//                 component={EmployerCandidateList}
//               />
//               <Route
//                 exact
//                 path="/employercandidatedetails/:id"
//                 component={EmployerCandidateProfile}
//               />
//               <Route
//                 exact
//                 path="/employerprofile"
//                 component={EmployerProfile}
//               />
//               <Route exact path="/addemployer" component={AddEmployer} />
//               {/* <Route path="*" component={Error_406} /> */}
//             </>
//           )}

//           <Route exact path="/Register" component={Register} />

//           <Route path="/">
//             <Redirect to="/" />
//           </Route>
//           <Route exact path="/Register" component={Register} />

//           <Route exact path="/employer" component={Home2} />
//         </Switch>
//       </Router>
//     </div>
//   );
// }

// export default App;

//without error page

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  const [isAdmin, setIsAdmin] = useState(
    localStorage.getItem("isAdmin") === "true"
  );

  const [isEmployee, setIsEmployee] = useState(
    localStorage.getItem("isEmployer") === "true"
  );

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
    setIsAdmin(localStorage.getItem("isAdmin") === "true");
    setIsEmployee(localStorage.getItem("isEmployer") === "true");
  }, []);

  return (
    // <div className="page">
    //   <Router>
    //     <Route exact path="/contactus" component={Contact_01} />
    //     <Route exact path="/Job_list" component={Job_list} />
    //     <Route path="/search-results/:searchResult" component={SearchResult} />
    //     <Route path="/filterjobs/:text" component={FIlterJobs} />
    //     <Route exact path= '/interviews' component={Blog_details} />

    //     <Route exact path="/alljobs" component={AllJobs} />
    //     <Route exact path="/jobdetails/:id" component={Job_details} />
    //     <Route
    //       exact
    //       path="/filtercategory/:category"
    //       component={FilterCategory}
    //     />
    //     <Route exact path="/myprofile" component={MyProfile} />
    //     <Route exact path="/myjobs" component={MyJobs} />

    //     <Route exact path="/admin">
    //       <Home3 />
    //     </Route>
    //     <Route exact path="/postjob" component={PostJob} />
    //     <Route
    //       exact
    //       path="/filtercategorywise/:category"
    //       component={FilterCategorywise}
    //     />
    //     <Route exact path="/Candidate_list" component={Candidate_list} />
    //     <Route exact path="/contact_us" component={Contact_02} />
    //     <Route exact path="/viewpostedjobs" component={ViewJobs} />
    //     <Route exact path="/addcompany" component={AddCompany} />
    //     <Route exact path="/addskills" component={AddSkills} />
    //     <Route exact path="/companydetails" component={CompanyDetails} />
    //     <Route exact path="/adminprofile" component={AdminProfile} />
    //     <Route exact path="/addadmin" component={AddAdmin} />
    //     <Route
    //       exact
    //       path="/Candidate_details/:id"
    //       component={CandidateProfile}
    //     />

    //     <Route exact path="/addbusinesstream" component={AddBusinessStream} />
    //     <Route exact path="/viewjob/:id" component={ViewJobDetails} />

    //     <Route exact path="/employer" component={Home2} />
    //     <Route
    //       exact
    //       path="/employerviewjob/:id"
    //       component={ViewEmployerJobDetails}
    //     />

    //     <Route exact path="/postjobasemployer" component={EmployerPostJobs} />
    //     <Route
    //       exact
    //       path="/addcompanyasemployer"
    //       component={EmployerAddCompany}
    //     />
    //     <Route exact path="/viewemployerJobs" component={ViewJobsEmployer} />
    //     <Route
    //       exact
    //       path="/viewcompanyasemployer"
    //       component={CompanyDetailsEmployer}
    //     />
    //     <Route
    //       exact
    //       path="/addskillsasemployer"
    //       component={EmployerAddSkills}
    //     />
    //     <Route
    //       exact
    //       path="/addbusinessstreamasemployer"
    //       component={EmployerAddBusinessStream}
    //     />
    //     <Route
    //       exact
    //       path="/employercandidatelist"
    //       component={EmployerCandidateList}
    //     />
    //     <Route
    //       exact
    //       path="/employercandidatedetails/:id"
    //       component={EmployerCandidateProfile}
    //     />
    //     <Route exact path="/employerprofile" component={EmployerProfile} />
    //     <Route exact path="/addemployer" component={AddEmployer} />

    //     <Route exact path="/Register" component={Register} />

    //     <Route exact path="/login" component={Login} />

    //     {/* employer routes */}

    //     <Route exact path="/" component={Home} />
    //   </Router>
    // </div>

    <div className="page">
      <Router>
      {!isAdmin && !isEmployee &&  <Switch>
        <Route exact path="/contactus" component={Contact_01} />
        <Route exact path="/Job_list" component={Job_list} />
        <Route
          path="/search-results/:searchResult"
          component={SearchResult}
        />
        <Route path="/filterjobs/:text" component={FIlterJobs} />
        <Route exact path="/interviews/:company" component={Blog_details} />

        <Route exact path="/alljobs" component={AllJobs} />
        <Route exact path="/jobdetails/:id" component={Job_details} />
        <Route
          exact
          path="/company_details/:id"
          component={CompanyDetailsPage}
        />
        <Route
          exact
          path="/filtercategory/:category"
          component={FilterCategory}
        />
        <Route
          exact
          path="/filtercategorycompanywise/:company/:category"
          component={FilterCategoryCompany}
        />
        <Route exact path="/filterrole/:role" component={FilterRole} />
        <Route exact path="/myprofile" component={MyProfile} />
        <Route exact path="/myjobs" component={MyJobs} />

        <Route exact path="/services_details" component={Services_details} />
        <Route exact path="/" component={Home} />
        <Route exact path="/Register" component={Register} />
            <Route exact path="/login" component={Login} />
            {/* <Route path="*">
              <Error_406 />
            </Route> */}
        </Switch>}
        {isAdmin && (
          <Switch>
            <Route exact path="/admin">
              <Home3 />
            </Route>
            <Route exact path="/postjob" component={PostJob} />
            <Route
              exact
              path="/filtercategorywise/:category"
              component={FilterCategorywise}
            />
            <Route
              exact
              path="/filterrolewise/:role"
              component={FilterRolewise}
            />
            <Route exact path="/Candidate_list" component={Candidate_list} />
            <Route
              exact
              path="/viewcandidates"
              component={ViewAllCandidatesAdmin}
            />
            <Route exact path="/contact_us" component={Contact_02} />
            <Route exact path="/viewpostedjobs" component={ViewJobs} />
            <Route exact path="/addcompany" component={AddCompany} />
            <Route exact path="/addskills" component={AddSkills} />
            <Route exact path="/addjobcategory" component={AddCategory} />
            <Route exact path="/companydetails" component={CompanyDetails} />
            <Route exact path="/adminprofile" component={AdminProfile} />
            <Route exact path="/addadmin" component={AddAdmin} />
            <Route
              exact
              path="/Candidate_details/:id"
              component={CandidateProfile}
            />
            <Route
              exact
              path="/addbusinesstream"
              component={AddBusinessStream}
            />
            <Route exact path="/viewjob/:id" component={ViewJobDetails} />
            {/* <Route path="*">
              <Error_404 />
            </Route> */}
          </Switch>
        )}

        {isEmployee && (
          <Switch>
            <Route exact path="/employer" component={Home2} />
            <Route
              exact
              path="/employerviewjob/:id"
              component={ViewEmployerJobDetails}
            />
            <Route
              exact
              path="/postjobasemployer"
              component={EmployerPostJobs}
            />
            <Route exact path="/viewcandidates" component={ViewAllCandidates} />
            <Route
              exact
              path="/addcompanyasemployer"
              component={EmployerAddCompany}
            />
            <Route
              exact
              path="/viewemployerJobs"
              component={ViewJobsEmployer}
            />
            <Route
              exact
              path="/viewcompanyasemployer"
              component={CompanyDetailsEmployer}
            />
            <Route
              exact
              path="/addskillsasemployer"
              component={EmployerAddSkills}
            />
            <Route
              exact
              path="/addbusinessstreamasemployer"
              component={EmployerAddBusinessStream}
            />
            <Route
              exact
              path="/addjobcategoryasemployer"
              component={EmployerAddJobCategory}
            />
            <Route
              exact
              path="/employercandidatelist"
              component={EmployerCandidateList}
            />
            <Route
              exact
              path="/employercandidatedetails/:id"
              component={EmployerCandidateProfile}
            />
             <Route
              exact
              path="/Candidate_details/:id"
              component={CandidateProfile}
            />
            <Route exact path="/employerprofile" component={EmployerProfile} />
            <Route exact path="/addemployer" component={AddEmployer} />
            {/* <Route path="*">
              <Error_405 />
            </Route> */}
          </Switch>
        )}

        {/* {!isAdmin && !isEmployee && (
          <>
            <Route exact path="/Register" component={Register} />
            <Route exact path="/login" component={Login} />

          </>
        )}



        <Route exact path="/" component={Home} /> */}




      </Router>
    </div>
  );
}

export default App;
