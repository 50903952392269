import React, { useState } from "react";

import { Tabs, TabPanel } from "react-tabs";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { subURL } from "../utils/URL's";
export var Username, UserID;

function Login({ setLoggedIn }) {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loginOption, setLoginOption] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function handlePasswordChange(event) {
    setPassword(event.target.value);
  }
  function firstNameChangeHandler(event) {
    setFirstName(event.target.value);
  }
  async function handleSubmit(event) {
    event.preventDefault();

    async function storeData() {
      const formData = {
        email_address: firstName,
        password: password,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/user_login/`, formData, {
          headers: headers,
        });

        if (res.status === 200) {
          if (res.data.user_type_id === 1) {
            setTimeout(() => {
              window.location.reload();
            }, 100);
            // setLoggedIn(true);
            history.push("/admin");
          } else if (res.data.user_type_id === 2) {
            // history.push("/");
            setTimeout(() => {
              window.location.reload();
            }, 100);
            // setLoggedIn(true);
            history.push("/");
          } else if (res.data.user_type_id === 3) {
            setTimeout(() => {
              window.location.reload();
            }, 100);
            // setLoggedIn(true);
            history.push("/employer");
          }
          setFirstName("");
          setLoginOption("");
          setPassword("");
        } else {
          console.log("error");
        }
        try {
          if (res.data.user_type_id === 1) {
            localStorage.setItem("isAdmin", true);
          }
        } catch (e) {
          console.log(e);
        }
        try {
          if (res.data.user_type_id === 2) {
            localStorage.setItem("isAdmin", false);
          }
        } catch (e) {
          console.log(e);
        }
        try {
          if (res.data.user_type_id === 3) {
            localStorage.setItem("isEmployer", true);
          }
        } catch (e) {
          console.log(e);
        }
        try {
          localStorage.setItem("userid", res.data.userid);
        } catch (e) {
          console.log(e);
        }
        try {
          localStorage.setItem("usertypeid", res.data.user_type_id);
        } catch (e) {
          console.log(e);
        }
        try {
          localStorage.setItem("username", res.data.username);
        } catch (e) {
          console.log(e);
        }
      } catch (error) {
        if (error.response?.status === 404) {
          setSuccessMessage("User does not exists !Please Register");
          setShowModal(true);
        } else if (error.response?.status === 401) {
          setSuccessMessage("Invalid Username or password");
          setShowModal(true);
        } else {
          setSuccessMessage("Oops!Something went wrong,please try again");
          setShowModal(true);
        }
      }
    }
    storeData();
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="ttm-row login-section clearfix">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
              <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
              <div className="layer-content">
                <div className="text-center mb-20">
                  <h3>Login To Your Account</h3>
                </div>
                <div className="ttm-tabs ttm-tab-style-02">
                  <Tabs>
                    <div className="content-tab">
                      <TabPanel>
                        <form
                          id="login_form"
                          className="login_form wrap-form"
                          onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-lg-12">
                              <label>
                                <i className="ti ti-email"></i>
                                <input
                                  required
                                  type="email"
                                  id="txtemail"
                                  placeholder="Email Address"
                                  value={firstName}
                                  onChange={firstNameChangeHandler}
                                />
                              </label>
                            </div>
                            {/* <div className="col-lg-12">
                              <label>
                                <i className="ti ti-lock"></i>
                                <input
                                  required
                                  type="password"
                                  id="password"
                                  placeholder="Password"
                                  value={password}
                                  onChange={handlePasswordChange}
                                />
                              </label>
                            </div> */}

                            <div
                              className="col-lg-12"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <label
                                style={{
                                  flex: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <i className="ti ti-lock"></i>
                                <input
                                  required
                                  type={showPassword ? "text" : "password"}
                                  id="password"
                                  placeholder="Password"
                                  value={password}
                                  onChange={handlePasswordChange}
                                />
                              </label>
                              <i
                                style={{
                                  position: "absolute",
                                  right: "50px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  cursor: "pointer",
                                }}
                                className={`fa ${
                                  showPassword ? "fa-eye" : "fa-eye-slash"
                                }`}
                                onClick={togglePasswordVisibility}
                              ></i>
                            </div>

                            <div className="col-lg-12">
                              <label>
                                <div className="forgot-pwd text-center mt-5">
                                  <p>
                                    Don't have account?{" "}
                                    <a
                                      href="/register"
                                      className="text-theme-SkinColor"
                                    >
                                      Sign Up here
                                    </a>
                                  </p>
                                </div>
                              </label>
                            </div>
                            <div className="col-lg-6 mx-auto">
                              <label className="mb-0">
                                <button
                                  className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                  type="submit"
                                >
                                  Sign in
                                </button>
                              </label>
                            </div>
                          </div>
                        </form>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
                <div className="login-social-buttons">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        id="login-with-facebook"
                        className="social-account-button w-100 facebook-button"
                      >
                        <i className="ti ti-facebook"></i>
                        <span>Login With facebook</span>
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        id="login-with-google"
                        className="social-account-button w-100 google-button"
                      >
                        <i className="ti ti-google"></i>
                        <span>Login With Google</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Failed !</ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Login;
