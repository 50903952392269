import React, { useEffect, useState } from "react";
import { imageURL, mainURL, subURL } from "../../../utils/URL's";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
var USERID;
const EmployerMenu = () => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [userName, setUserName] = useState("");
  const [userID, setUserID] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userImage, setUserImage] = useState("");
  const [isAdminLogout, setIsAdminLogout] = useState(false);

  const getUserName = async () => {
    try {
      const USERNAME = await localStorage.getItem("username");

      if (USERNAME !== null) {
        setUserName(USERNAME);
      }
    } catch (e) {
      console.log(e);
    }
  };
  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserID(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    setTimeout(() => {
      getUserName();
    }, 100);
  }, []);

  useEffect(() => {
    async function fetechUserId() {
      const res = await axios.get(`${subURL}/user_save_account/${userID}/`);

      setUserData(res.data);
      setUserImage(res.data[0].profile_photo);
    }
    fetechUserId();
  }, [userID]);

  const toggle = () => setShow(!show);

  //user Profile Dropdown
  const [userProfile, setUserProfile] = useState(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

  const logoutHandler = () => {
    (async () => {
      try {
        await localStorage.removeItem("userid");
        await localStorage.removeItem("isEmployer");
        await localStorage.removeItem("username");
        await localStorage.removeItem("usertypeid");

        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        //  setIsAdminLogout(true);
        history.push("/");
      } catch (error) {
        console.log(error);
      }
    })();
  };
  useEffect(() => {
    if (isAdminLogout) {
      window.location.href = "/"; // Redirect to login page
    }
  }, [isAdminLogout]);
  return (
    <>
      <Dropdown
        onClick={() => setUserProfile(!userProfile)}
        isOpen={userProfile}
        toggle={dropDownuserprofile}
        className="list-inline-item"
      >
        <DropdownToggle
          to="#"
          className="header-item"
          id="userdropdown"
          type="button"
          tag="a"
          aria-expanded="false"
        >
          <img
            src={`${imageURL}${userImage}`}
            alt="user"
            width="35"
            height="35"
            className="rounded-circle me-1"
          />{" "}
          <span className="d-none d-md-inline-block fw-medium">
            Hi, {userName.charAt(0).toUpperCase() + userName.slice(1)}
          </span>
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          aria-labelledby="userdropdown"
          end
        >
          <li>
            <Link className="dropdown-item" to="/employerprofile">
              My Profile
            </Link>
          </li>
          {/* <li>
            <Link className="dropdown-item" to="/addemployer">
              Add Employer
            </Link>
          </li> */}
          <li>
            <Link className="dropdown-item" onClick={() => setShowModal(true)}>
              Logout
            </Link>
          </li>
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Logout !</ModalHeader>
        <ModalBody>
          <h6>Are you sure you want to logout ?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="grey" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button color="primary" onClick={logoutHandler}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EmployerMenu;
