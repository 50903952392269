import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { subURL } from "../../../utils/URL's";
import axios from "axios";

import { TabPanel, Tabs } from "react-tabs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";

const AddEmployer = () => {
  const history = useHistory();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [resume, setResume] = useState(null);
  const [joinType, setJoinType] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [experience, setExperience] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const userTypeOptions = async () => {
      const response = await fetch(`${subURL}/user_type/`);
      const data = await response.json();
    };

    userTypeOptions();
  }, []);

  useEffect(() => {
    const fetchexpType = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchexpType();
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  function firstNameChangeHandler(event) {
    setFirstName(event.target.value);
  }
  function lastNameChangeHandler(event) {
    setLastName(event.target.value);
  }
  const handlePhonenoChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setEnteredPhone(inputPhone);
  };
  const validatePassword = (password) => {
    return password.length >= 6; // Check if password has at least 6 characters
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleProfilePhotoChange = (event) => {
    setProfilePhoto(event.target.files[0]);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!isValidPassword) {
      alert("Password should be at least 6 characters long.");
      return;
    }
    if (enteredPhone.length < 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }
    async function storeData() {
      const formData = new FormData();
      formData.append("user_type_id", 1);
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);

      formData.append("profile_photo", profilePhoto);
      formData.append("phone_number", enteredPhone);
      formData.append("email_address", email);
      formData.append("password", password);

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.post(`${subURL}/user_save_account/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setSuccessMessage("Added admin successfully!");
          setShowModal(true);
          history.push("/admin");
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Email already Exists!");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops ! Something went wrong ,please try again");
          setShowModal(true);
        }
        console.log(e);
      }
    }

    storeData();

    setFirstName("");
    setLastName("");
    setEmail("");
    setPassword("");
    setEnteredPhone("");
    setJoinType("");
    setExperienceLevel("");
  }
  return (
    <div className="site-main">
      <EmployerHeader />

      <EmployerPageHeader title="Add Admin" breadcrumb="add admin" />

      <div className="ttm-row register-section clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="bg-theme-GreyColor ttm-col-bgcolor-yes ttm-bg rounded p-50 p-lg-20">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="layer-content">
                  <div className="text-center mb-20">
                    <h3>Add Admin</h3>
                  </div>
                  <div className="ttm-tabs ttm-tab-style-02">
                    <Tabs>
                      <div className="content-tab">
                        <TabPanel>
                          <form
                            id="login_form"
                            className="login_form wrap-form"
                            onSubmit={handleSubmit}
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <input
                                    required
                                    type="text"
                                    id="txtname"
                                    placeholder="First name"
                                    value={firstName}
                                    onChange={firstNameChangeHandler}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <input
                                    type="text"
                                    id="txtlastname"
                                    placeholder="Last name"
                                    value={lastName}
                                    onChange={lastNameChangeHandler}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-email"></i>
                                  <input
                                    required
                                    type="email"
                                    id="txtemail"
                                    placeholder="Email Address"
                                    value={email}
                                    onChange={handleEmailChange}
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <InputGroup>
                                    <Input
                                      required
                                      type={showPassword ? "text" : "password"}
                                      minLength={6}
                                      className="form-control"
                                      id="passwordInput"
                                      placeholder="Enter your password"
                                      value={password}
                                      onChange={handlePasswordChange}
                                    />
                                    <InputGroupText
                                      onClick={togglePasswordVisibility}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <FontAwesomeIcon
                                        icon={showPassword ? faEye : faEyeSlash}
                                      />
                                    </InputGroupText>
                                  </InputGroup>
                                </label>
                              </div>

                              <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-upload"></i>
                                  <label htmlFor="profile_photo">
                                    Upload profile photo
                                  </label>
                                  <input
                                    type="file"
                                    id="profile_photo"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={handleProfilePhotoChange}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-mobile"></i>
                                  <input
                                    required
                                    maxLength={10}
                                    type="tel"
                                    id="txtphone"
                                    placeholder="Phone Number"
                                    value={enteredPhone}
                                    onChange={handlePhonenoChange}
                                  />
                                </label>
                              </div>

                              <div className="col-lg-12">
                                <label className="mb-0">
                                  <button
                                    className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                    type="submit"
                                  >
                                    Add
                                  </button>
                                </label>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <form
                            id="login_form"
                            className="login_form wrap-form"
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-user"></i>
                                  <input
                                    type="text"
                                    id="txtname"
                                    placeholder="Username"
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-email"></i>
                                  <input
                                    type="email"
                                    id="txtemail"
                                    placeholder="Email Address"
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-lock"></i>
                                  <input
                                    type="password"
                                    id="password"
                                    placeholder="Password"
                                  />
                                </label>
                              </div>
                              <div className="col-lg-6">
                                <label>
                                  <i className="ti ti-lock"></i>
                                  <input
                                    type="password"
                                    id="cpassword"
                                    placeholder="Confirm Password *"
                                  />
                                </label>
                              </div>
                              <div className="col-lg-12">
                                <label>
                                  <i className="ti ti-mobile"></i>
                                  <input
                                    type="tel"
                                    id="txtphone"
                                    placeholder="Phone Number"
                                  />
                                </label>
                              </div>

                              <div className="col-lg-12">
                                <label className="mb-0">
                                  <button
                                    className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                                    type="submit"
                                  >
                                    Add
                                  </button>
                                </label>
                              </div>
                            </div>
                          </form>
                        </TabPanel>
                      </div>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <label className="mb-0">
                <button
                  className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor mt-3"
                  type="submit"
                  onClick={() => history.goBack("/employer")}
                >
                  Back
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
      <EmployerFooter />
    </div>
  );
};

export default AddEmployer;
