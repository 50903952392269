import axios from "axios";
import React, { Component, useEffect, useState } from "react";
import { subURL } from "../../utils/URL's";
var USERID;
function Footer() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChangeEmail = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (email === "") {
      alert("Please enter email address");
      return;
    }
    async function storeData() {
      const formData = {
        user_account_id: userId,
        email: email,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/subscribe/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          alert("Subscribed Successfully !");
          setSuccessMessage("Subscribed Successfully !");
          setShowModal(true);
        } else {
          console.log("error");
        }
      } catch (e) {
        console.log(e);
        alert("Oops !Something went wrong ,please try again");
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
      }
    }

    storeData();

    setEmail("");
  };
  return (
    <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
      <div className="second-footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4 widget-area">
              <div className="widget widget_text clearfix">
                <div className="footer-logo">
                  <a
                    className="home-link"
                    href="/home"
                    title="Hireco"
                    rel="home"
                  >
                    <h6 className="title text-white">wokwantaim.com</h6>
                  </a>
                </div>
                <p>
                  wokwantaim.com is a multi award-winning executive search,
                  management recruiter and employer branding organization. Our
                  customized recruiting process delivers fast & accurate results
                </p>
                <div className="d-flex align-items-center pt-15 pb-25">
                  <h6 className="fw-normal">Social Share: </h6>
                  <div className="social-icons">
                    <ul className="social-icons list-inline">
                      <li>
                        <a
                          href="#facebook"
                          rel="noopener"
                          aria-label="facebook"
                        >
                          <i className="ti ti-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#twitter" rel="noopener" aria-label="twitter">
                          <i className="ti ti-twitter-alt"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#instagram"
                          rel="noopener"
                          aria-label="instagram"
                        >
                          <i className="ti ti-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#pinterest"
                          rel="noopener"
                          aria-label="pinterest"
                        >
                          <i className="ti ti-pinterest"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <a
                  className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white"
                  href="/contactus"
                >
                  Contact Us
                </a> */}
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
              <div className="widget widget-recent-post clearfix">
                <h3 className="widget-title">Quick links</h3>
                <ul className="widget-post ttm-recent-post-list">
                  <li>
                    <div className="post-detail">
                      <div className="cat-link">
                        <a href="/job_list">Find Jobs</a>
                      </div>
                      <a href="/job_list">Explore jobs of your choice !</a>
                    </div>
                  </li>
                  <li>
                    <div className="post-detail">
                      <div className="cat-link">
                        <a href="/contactus">Contact us</a>
                      </div>
                      <a href="/contactus">Contact us for more information !</a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
              <div className="widget widget-contact clearfix">
                <h3 className="widget-title">Quick Contact!</h3>
                <ul className="widget_contact_wrapper">
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                    <h3>Call Us On:</h3>+92 777 555 0000
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                    <h3>Address:</h3>Suite 20 Golden Street USA
                  </li>
                  <li>
                    <i className="ttm-textcolor-skincolor flaticon-email"></i>
                    <h3>Email:</h3>
                    <a href="mailto:info@example.com">info@example.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-text">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <span className="copyright">
                Copyright © 2021 <a href="/home">wokwantaim.com </a>All rights
                reserved.
              </span>
              <ul className="footer-nav-menu">
                <li>
                  <a href="/">Privacy & Policy</a>
                </li>
                <li>
                  <a href="/">Terms & Conditions</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-md-6 d-lg-flex align-items-center justify-content-between">
              <form
                id="subscribe-form"
                className="subscribe-form"
                onSubmit={handleSubmit}
              >
                <div
                  className="mailchimp-inputbox clearfix"
                  id="subscribe-content"
                >
                  <input
                    type="email"
                    name="EMAIL"
                    id="txtemail"
                    placeholder="Enter Your Email Address..."
                    required=""
                    value={email}
                    onChange={handleChangeEmail}
                  />
                  <button
                    className="submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor"
                    type="submit"
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
