import React, { useEffect } from "react";

import PageHeader from "../../../components/layout/PageHeader";

import { useState } from "react";

import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import axios from "axios";
import { imageURL, mainURL, subURL } from "../../../utils/URL's";
import AdminFooter from "../../../components/layout/Admin/AdminFooter";
import AdminHeader from "../../../components/layout/Admin/AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
var USERID;
function AdminProfile() {
  const [userId, setUserId] = useState("");
  const [singleData, setSingleData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [email, setEmail] = useState("");
  const [experienceLevel, setExperienceLevel] = useState("");
  const [resume, setResume] = useState(null);
  const [joinType, setJoinType] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [experience, setExperience] = useState([]);
  const [jobTypeOptions, setJobTypeOptions] = useState([]);

  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const fetchSkillSetOptions = async () => {
      const response = await fetch(`${subURL}/exptype/`);
      const data = await response.json();

      setExperience(data);
    };

    fetchSkillSetOptions();
  }, []);

  useEffect(() => {
    const fetchJobTypeOptions = async () => {
      const response = await fetch(`${subURL}/job_type/`);
      const data = await response.json();

      setJobTypeOptions(data);
    };

    fetchJobTypeOptions();
  }, []);

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const res = await axios.get(`${subURL}/user_save_account/${userId}`);

      setSingleData(res.data[0]);
    }
    fetchData();
  }, [userId]);

  const handlePhonenoChange = (event) => {
    const inputPhone = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    setEnteredPhone(inputPhone);
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Check if password has at least 6 characters
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsValidPassword(validatePassword(newPassword));
  };

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const forceDownload = (response, title, contentType) => {
    if (!response || !response.data) {
      console.error("Response or response data is undefined");
      return;
    }
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  };

  const updateHandler = () => {
    if (!isValidEmail) {
      alert("Please enter a valid email address.");
      return;
    }
    if (!isValidPassword) {
      alert("Password should be at least 6 characters long.");
      return;
    }
    const phoneNumberString = singleData.phone_number.toString();

    if (phoneNumberString.length < 10) {
      alert("Phone number must be exactly 10 digits.");
      return;
    }
    if (enteredPhone && enteredPhone.length < 10) {
      alert("Phone number must be exactly 10 digits");
      return;
    }
    async function storeData() {
      const formData = new FormData();
      formData.append("user_type_id", 1);
      formData.append("first_name", firstName || singleData.first_name);
      formData.append("last_name", lastName || singleData.last_name);

      formData.append("email_address", email || singleData.email_address);
      formData.append("password", password || singleData.password);
      formData.append("phone_number", enteredPhone || singleData.phone_number);
      if (profilePhoto) {
        formData.append(
          "profile_photo",
          profilePhoto || singleData.profile_photo
        );
      }

      try {
        let headers = {
          "Content-Type": "multipart/form-data",
        };
        const res = await axios.put(
          `${subURL}/user_save_account/${userId}/`,
          formData,
          {
            headers: headers,
          }
        );

        if (res.status === 200) {
          setModalTitle("Success !");
          setSuccessMessage("Updated successfully!");
          setShowModal(true);
          let existingValue = localStorage.getItem("username");
          if (existingValue !== null) {
            // Update with res.data.first_name if it is not undefined
            if (firstName || singleData?.first_name !== undefined) {
              existingValue = firstName || singleData?.first_name;
              localStorage.removeItem("username");
              localStorage.setItem("username", existingValue);
            }
          }
        } else {
          console.log("error");
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (e) {
        if (e.response.status === 403) {
          setModalTitle("Invalid format !");
          setSuccessMessage("Please upload valid valid profile photo!");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
        }

        console.log(e);
      }
    }

    storeData();
  };
  const handleExperienceLevelChange = (event) => {
    setExperienceLevel(event.target.value);
  };
  const handleJoinTypeChange = (event) => {
    setJoinType(event.target.value);
  };
  return (
    <div className="site-main">
      <AdminHeader />

      <PageHeader title="Profile" breadcrumb="My Profile" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget candidate-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>My Profile
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">First Name :</b>
                      <span>
                        {" "}
                        {singleData.first_name?.charAt(0).toUpperCase() +
                          singleData.first_name?.slice(1)}
                      </span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Last Name:</b>
                      <span>
                        {" "}
                        {singleData.last_name?.charAt(0).toUpperCase() +
                          singleData.last_name?.slice(1)}
                      </span>
                    </li>

                    <li className="d-flex">
                      <b className="mr-5">Phone Number:</b>
                      <span>{singleData.phone_number}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Email:</b>
                      <span>{singleData.email_address}</span>
                    </li>
                  </ul>
                </aside>
              </div>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="candidate-data">
                    <div className="candidate-img">
                      <img
                        src={`${imageURL}${singleData.profile_photo}`}
                        alt="user"
                      />
                    </div>
                    <div className="candidate-caption">
                      <h5>
                        {singleData.first_name} {singleData.last_name}
                      </h5>

                      <div className="meta-line">
                        <span>
                          <i className="ti ti-email"></i>{" "}
                          {singleData.email_address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div
                    className="overview-box"
                    style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
                  >
                    <div className="title">
                      <h5>Edit profile</h5>

                      <div className=" p-3">
                        <Label for="education">First Name</Label>
                        <Input
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="First Name"
                          value={firstName || singleData.first_name}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="p-3">
                        <Label for="lastname">Last Name</Label>
                        <Input
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Lastname"
                          value={lastName || singleData.last_name}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div className="p-3">
                        <Label for="email">Email</Label>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          value={email || singleData.email_address}
                          onChange={handleEmailChange}
                        />
                      </div>
                      <div className="p-3">
                        <Label for="email">Password</Label>

                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            minLength={6}
                            className="form-control"
                            id="passwordInput"
                            value={password || singleData.password}
                            onChange={handlePasswordChange}
                          />
                          <InputGroupText
                            onClick={togglePasswordVisibility}
                            style={{ cursor: "pointer" }}
                          >
                            <FontAwesomeIcon
                              icon={showPassword ? faEye : faEyeSlash}
                            />
                          </InputGroupText>
                        </InputGroup>
                      </div>

                      <div className="p-3">
                        <Label for="phone">Contact Number</Label>
                        <Input
                          maxLength={10}
                          type="tel"
                          name="phone"
                          id="phone"
                          value={enteredPhone || singleData.phone_number}
                          onChange={handlePhonenoChange}
                        />
                      </div>

                      <div className="p-3">
                        <Label for="profile">Profile photo</Label>
                        <Input
                          type="file"
                          name="profile_photo"
                          id="profile"
                          accept=".png, .jpg, .jpeg"
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const allowedTypes = [
                              "image/png",
                              "image/jpeg",
                              "image/jpg",
                            ];

                            if (file && allowedTypes.includes(file.type)) {
                              setProfilePhoto(file);
                            } else {
                              setModalTitle("Invalid format !");
                              setSuccessMessage(
                                "Please upload valid valid profile photo!"
                              );
                              setShowModal(true);
                            }
                          }}
                          // onChange={(e) => setProfilePhoto(e.target.files[0])}
                        />
                      </div>
                      {singleData && singleData?.profile_photo && (
                        <div
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          <label
                            for="uploadedCV"
                            style={{ marginRight: "10px" }}
                          >
                            Already Uploaded Photo:
                          </label>
                          <div>
                            <p>
                              {singleData?.profile_photo.substring(
                                singleData?.profile_photo?.lastIndexOf("/") + 1
                              )}
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="p-3 d-flex justify-content-between">
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                          type="button"
                          onClick={updateHandler}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AdminFooter />
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default AdminProfile;
