import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
var ID, USERID;
const currentYear = new Date().getFullYear();
const years = [];
for (let year = currentYear; year >= 1900; year--) {
  years.push(year);
}
const maxLength = 4000; // Maximum number of characters allowed

const months = [
  { value: "0", label: "Jan" },
  { value: "1", label: "Feb" },
  { value: "2", label: "Mar" },
  { value: "3", label: "Apr" },
  { value: "4", label: "May" },
  { value: "5", label: "Jun" },
  { value: "6", label: "Jul" },
  { value: "7", label: "Aug" },
  { value: "8", label: "Sep" },
  { value: "9", label: "Oct" },
  { value: "10", label: "Nov" },
  { value: "11", label: "Dec" },
];
const yearsArray = Array.from({ length: 51 }, (_, i) => i);

// Create an array of months from 0 to 11
const monthsArray = Array.from({ length: 12 }, (_, i) => i);
const Employment = () => {
  const [userId, setUserId] = useState("");
  const [employmentModal, setEmploymentModal] = useState(false);

  const openEmploymentModal = () => {
    setCharacterCount(maxLength);
    setEmploymentModal(!employmentModal);
    setIsCurrentEmployment("");
    setCurrentCompanyName("");
    setCurrentCompanyDesignation("");
    setPrevCompanyName("");
    setPrevCompanyDesignation("");
    setEmploymentType("");
    setJoiningYear("");
    setJoiningMonth("");
    setCurrentSalary("");
    setWorkedTillYear("");
    setWorkedTillMonth("");
    setJobProfile("");
    setSkillsUsed("");
    setNoticePeriod("");
    setTotalExperienceYears("");
    setTotalExperienceMonths("");
  };

  const [isCurrentEmployment, setIsCurrentEmployment] = useState(false);
  const [prevCompanyName, setPrevCompanyName] = useState("");
  const [prevCompanyDesignation, setPrevCompanyDesignation] = useState("");
  const [employmentType, setEmploymentType] = useState("");

  const [totalExperienceYears, setTotalExperienceYears] = useState(0);
  const [totalExperienceMonths, setTotalExperienceMonths] = useState(0);
  const [currentCompanyName, setCurrentCompanyName] = useState("");
  const [currentCompanyDesignation, setCurrentCompanyDesignation] =
    useState("");
  const [joiningYear, setJoiningYear] = useState("");
  const [joiningMonth, setJoiningMonth] = useState("");

  const [workedTillYear, setWorkedTillYear] = useState("");
  const [workedTillMonth, setWorkedTillMonth] = useState("");

  const [skillsUsed, setSkillsUsed] = useState("");
  const [jobProfile, setJobProfile] = useState("");
  const [noticePeriod, setNoticePeriod] = useState("");

  const [currentSalary, setCurrentSalary] = useState(0);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [employmentData, setEmploymentData] = useState([]);

  const [editEmploymentModal, setEditEmploymentModal] = useState(false);
  const openEditEmploymentModal = () =>
    setEditEmploymentModal(!editEmploymentModal);

  const [delId, setDelId] = useState("");
  const [deleteEmploymentModal, setDeleteEmploymentModal] = useState(false);
  const openDeleteEmploymentModal = () =>
    setDeleteEmploymentModal(!deleteEmploymentModal);

  const [isEdit, setIsEdit] = useState(false);
  const [characterCount, setCharacterCount] = useState(maxLength);
  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/employmentdetails/${userId}/`)
      .then((res) => {
        setEmploymentData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const handleTotalExperienceYearsChange = (event) => {
    setTotalExperienceYears(parseInt(event.target.value));
  };

  const handleTotalExperienceMonthsChange = (event) => {
    setTotalExperienceMonths(parseInt(event.target.value));
  };
  const handleJobProfile = (e) => {
    const inputValue = e.target.value;
    const remainingCharacters = maxLength - inputValue.length;

    if (remainingCharacters >= 0) {
      setJobProfile(inputValue);
      setCharacterCount(remainingCharacters);
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();

    async function storeData() {
      const userid=parseInt(userId)
      const formData = {
        user_account_id: userid,
        employment_type: employmentType,
        is_current_employment: isCurrentEmployment,
        total_experince_year: totalExperienceYears
          ? totalExperienceYears
          : null,
        total_experince_month: totalExperienceMonths
          ? totalExperienceMonths
          : null,
        joining_date_month: joiningMonth,
        joining_date_year: joiningYear,
        current_company_name: currentCompanyName ? currentCompanyName : "",
        current_designation: currentCompanyDesignation
          ? currentCompanyDesignation
          : "",
        worked_till_month: workedTillMonth ? workedTillMonth : null,
        worked_till_year: workedTillYear ? workedTillYear : null,
        previous_company: prevCompanyName ? prevCompanyName : "",
        previous_designaion: prevCompanyDesignation
          ? prevCompanyDesignation
          : "",
        current_salary: currentSalary ? currentSalary : 0,
        skills_used: skillsUsed,
        job_profile: jobProfile,
        notice_period: noticePeriod,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/employmentdetails`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Sucess!");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setEmploymentModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(
                `${subURL}/employmentdetails/${userId}/`
              );

              setEmploymentData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
              setIsCurrentEmployment("");
              setCurrentCompanyName("");
              setCurrentCompanyDesignation("");
              setPrevCompanyName("");
              setPrevCompanyDesignation("");
              setEmploymentType("");
              setJoiningYear("");
              setJoiningMonth("");
              setCurrentSalary("");
              setWorkedTillYear("");
              setWorkedTillMonth("");
              setJobProfile("");
              setSkillsUsed("");
              setNoticePeriod("");
              setTotalExperienceYears("");
              setTotalExperienceMonths("");
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        console.log(e);
        setModalTitle("Failed !");
        setSuccessMessage("Oops !Something went wrong ,please try again");
        setShowModal(true);
      }
    }

    storeData();
  }

  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditEmploymentModal(true);

    const filteredDummuyData = employmentData.find((data) => data.id === id);

    setEmploymentType(filteredDummuyData.employment_type);
    setIsCurrentEmployment(filteredDummuyData.is_current_employment);
    setTotalExperienceYears(filteredDummuyData.total_experince_year);
    setTotalExperienceMonths(filteredDummuyData.total_experince_month);
    setJoiningMonth(filteredDummuyData.joining_date_month);
    setJoiningYear(filteredDummuyData.joining_date_year);
    setCurrentCompanyName(filteredDummuyData.current_company_name);
    setCurrentCompanyDesignation(filteredDummuyData.current_designation);
    setWorkedTillMonth(filteredDummuyData.worked_till_month);
    setWorkedTillYear(filteredDummuyData.worked_till_year);
    setPrevCompanyName(filteredDummuyData.previous_company);
    setPrevCompanyDesignation(filteredDummuyData.previous_designaion);
    setJobProfile(filteredDummuyData.job_profile);

    setCurrentSalary(filteredDummuyData.current_salary);
    setSkillsUsed(filteredDummuyData.skills_used);
    setNoticePeriod(filteredDummuyData.notice_period);
  }

  async function updateHandler() {
    const formData = {
      user_account_id: userId,
      employment_type: employmentType,
      is_current_employment: isCurrentEmployment,
      total_experince_year: totalExperienceYears ? totalExperienceYears : null,
      total_experince_month: totalExperienceMonths
        ? totalExperienceMonths
        : null,
      joining_date_month: joiningMonth,
      joining_date_year: joiningYear,
      current_company_name: currentCompanyName ? currentCompanyName : "",
      current_designation: currentCompanyDesignation
        ? currentCompanyDesignation
        : "",
      worked_till_month: workedTillMonth ? workedTillMonth : null,
      worked_till_year: workedTillYear ? workedTillYear : null,
      previous_company: prevCompanyName ? prevCompanyName : "",
      previous_designaion: prevCompanyDesignation ? prevCompanyDesignation : "",
      current_salary: currentSalary ? currentSalary : 0,
      skills_used: skillsUsed,
      job_profile: jobProfile,
      notice_period: noticePeriod,
    };
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(
        `${subURL}/employmentdetails/${ID}/`,
        formData,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        setModalTitle("Sucess!");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setEditEmploymentModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/employmentdetails/${userId}/`
            );

            setEmploymentData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
      setEditEmploymentModal(false);
      console.log(e);
    }
  }
  function handleDelete(id) {
    setDeleteEmploymentModal(true);
    setDelId(id);
  }
  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/employmentdetails/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Sucess!");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteEmploymentModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/employmentdetails/${userId}/`
            );

            setEmploymentData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
      console.log(error);
    }
  }
  const handleEndYearChange = (e) => {
    const selectedEndYear = e.target.value;
    setWorkedTillYear(selectedEndYear);

    if (
      selectedEndYear !== "" &&
      joiningYear !== "" &&
      parseInt(selectedEndYear) < parseInt(joiningYear)
    ) {
      alert("Ending year cannot be lesser than the starting year!");
      setWorkedTillYear("");
    }
  };

  const handleStatusChange = (event) => {
    setEmploymentType(event.target.value);
  };
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Employment Details</h5>
        <div className="button-container">
          <button
            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
            type="submit"
            onClick={openEmploymentModal}
            style={{ marginRight: "25px" }}
          >
            Add
          </button>
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <>
          {employmentData.length > 0 ? (
            <div className="desc">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}> Employment Type</th>
                    <th style={{ fontWeight: "500" }}>Job Profile</th>

                    {/* {isCurrentEmployment && <th>Total Experience</th>}
                <th>Joining Date</th>
              
                <th>
                  {!isCurrentEmployment
                    ? "Current Company Name"
                    : "Previous Company Name"}
                </th>
                <th>
                  {!isCurrentEmployment
                    ? "Current Company Designation"
                    : "Previous Company Designation"}
                </th> */}
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {employmentData.map((employmentData, key) => (
                    <tr key={employmentData.id}>
                      <td>{key + 1}</td>
                      <td>{employmentData.employment_type}</td>

                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {employmentData.job_profile}
                      </td>

                      {/* {isCurrentEmployment && (
                    <td>
                      {employmentData.total_experince_year}
                      {"years"} {employmentData.total_experince_month}
                      {"months"}
                    </td>
                  )}
                  <td>{employmentData.joining_date_year}</td>
                
                  <td>
                    {!isCurrentEmployment
                      ? employmentData.current_company_name
                      : employmentData.previous_company}
                  </td>
                  <td>
                    {!isCurrentEmployment
                      ? employmentData.current_designation
                      : employmentData.previous_designaion}
                  </td> */}

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2em",
                          //width: "10em",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() => handleEdit(employmentData.id)}
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() => handleDelete(employmentData.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <ListGroupItem>
              <p> No employment details found...</p>
            </ListGroupItem>
          )}
        </>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={employmentModal} toggle={openEmploymentModal} centered>
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Employment
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openEmploymentModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className=" p-3">
                <FormGroup>
                  <Label for="isCurrentEmployment">
                    Is this your current employment?
                  </Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="currentYes"
                          name="currentEmployment"
                          label="Yes"
                          onChange={() => setIsCurrentEmployment(true)}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="currentNo"
                          name="currentEmployment"
                          label="No"
                          onChange={() => setIsCurrentEmployment(false)}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              {!isCurrentEmployment && (
                <>
                  <FormGroup>
                    <Label for="prevCompanyName">Previous Company Name</Label>
                    <Input
                      type="text"
                      id="prevCompanyName"
                      value={prevCompanyName}
                      onChange={(e) => setPrevCompanyName(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="prevCompanyDesignation">
                      Previous Company Designation
                    </Label>
                    <Input
                      type="text"
                      id="prevCompanyDesignation"
                      value={prevCompanyDesignation}
                      onChange={(e) =>
                        setPrevCompanyDesignation(e.target.value)
                      }
                    />
                  </FormGroup>
                </>
              )}
              <div className="p-3">
                <FormGroup>
                  <Label for="employmentType">Employment Type</Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="fullTime"
                          name="employmentType"
                          label="Full Time"
                          onChange={() => setEmploymentType("Full Time")}
                        />
                        Full time
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="internship"
                          name="employmentType"
                          label="Internship"
                          onChange={() => setEmploymentType("Internship")}
                        />
                        Internship
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>

              {isCurrentEmployment && (
                <div className="p-3">
                  <FormGroup>
                    <Label for="totalExperience">Total Experience</Label>
                    <Row>
                      <Col>
                        <Input
                          type="select"
                          name="totalExperienceYears"
                          id="totalExperienceYears"
                          onChange={handleTotalExperienceYearsChange}
                          value={totalExperienceYears}
                        >
                          <option value="">Select Year</option>
                          {yearsArray.map((year) => (
                            <option key={year} value={year}>
                              {year} {year === 1 ? "year" : "years"}
                            </option>
                          ))}
                        </Input>
                      </Col>
                      <Col>
                        <Input
                          type="select"
                          name="totalExperienceMonths"
                          id="totalExperienceMonths"
                          onChange={handleTotalExperienceMonthsChange}
                          value={totalExperienceMonths}
                        >
                          <option value="">Select Month</option>
                          {monthsArray.map((month) => (
                            <option key={month} value={month}>
                              {month} {month === 1 ? "month" : "months"}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </div>
              )}

              <div className="p-3">
                <>
                  {isCurrentEmployment && (
                    <FormGroup>
                      <Label for="currentCompanyName">
                        Current Company Name
                      </Label>
                      <Input
                        type="text"
                        name="currentCompanyName"
                        id="currentCompanyName"
                        value={currentCompanyName}
                        onChange={(e) => setCurrentCompanyName(e.target.value)}
                      />
                    </FormGroup>
                  )}
                  {isCurrentEmployment && (
                    <FormGroup>
                      <Label for="currentDesignation">
                        Current Designation
                      </Label>
                      <Input
                        type="text"
                        name="currentDesignation"
                        id="currentDesignation"
                        value={currentCompanyDesignation}
                        onChange={(e) =>
                          setCurrentCompanyDesignation(e.target.value)
                        }
                      />
                    </FormGroup>
                  )}

                  <div className="p-1">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="year">Joining Date (in year)</Label>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            value={joiningYear}
                            onChange={(e) => setJoiningYear(e.target.value)}
                          >
                            <option value="">Select Year</option>
                            {years.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="month">Joining Date(in month)</Label>
                          <Input
                            type="select"
                            name="month"
                            id="month"
                            value={joiningMonth}
                            onChange={(e) => setJoiningMonth(e.target.value)}
                          >
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>

                  {isCurrentEmployment && (
                    <FormGroup>
                      <Label for="currentSalary">Current Salary</Label>
                      <Input
                        type="number"
                        name="currentSalary"
                        id="currentSalary"
                        value={currentSalary}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value, 10);
                          if (enteredValue >= 0) {
                            setCurrentSalary(enteredValue);
                          }
                        }}
                      />
                    </FormGroup>
                  )}
                </>

                {!isCurrentEmployment && (
                  <div className="p-1">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="year">Worked Till (in year)</Label>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            value={workedTillYear}
                            onChange={handleEndYearChange}
                          >
                            <option value="">Select Year</option>
                            {years.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="month">Worked Till(in month)</Label>
                          <Input
                            type="select"
                            name="month"
                            id="month"
                            value={workedTillMonth}
                            onChange={(e) => setWorkedTillMonth(e.target.value)}
                          >
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                )}

                <>
                  <FormGroup>
                    <Label for="jobProfile">Job Profile</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="jobProfile"
                      id="jobProfile"
                      value={jobProfile}
                      onChange={handleJobProfile}
                    />
                    <small>{characterCount} Character(s) Left</small>
                  </FormGroup>
                  {isCurrentEmployment && (
                    <FormGroup>
                      <Label for="skillsUsed">Skills Used</Label>
                      <Input
                        type="text"
                        name="skillsUsed"
                        id="skillsUsed"
                        value={skillsUsed}
                        onChange={(e) => setSkillsUsed(e.target.value)}
                      />
                    </FormGroup>
                  )}
                  {isCurrentEmployment && (
                    <FormGroup>
                      <Label for="noticePeriod">Notice Period</Label>
                      <Input
                        type="text"
                        name="noticePeriod"
                        id="noticePeriod"
                        value={noticePeriod}
                        onChange={(e) => setNoticePeriod(e.target.value)}
                      />
                    </FormGroup>
                  )}
                </>
              </div>

              <div className="p-3">
                <button
                  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editEmploymentModal}
              toggle={openEditEmploymentModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Employment
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditEmploymentModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className=" p-3">
                  <FormGroup>
                    <Label for="isCurrentEmployment">
                      Is this your current employment?
                    </Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="currentYes"
                            name="currentEmployment"
                            label="Yes"
                            defaultChecked
                            onChange={() => setIsCurrentEmployment(true)}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="currentNo"
                            name="currentEmployment"
                            label="No"
                            onChange={() => setIsCurrentEmployment(false)}
                          />
                          No
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>
                <div className="p-3">
                  <FormGroup>
                    <Label for="employmentType">Employment Type</Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="fullTime"
                            name="employmentType"
                            label="Full Time"
                            value="Full Time"
                            checked={employmentType === "Full Time"}
                            onChange={handleStatusChange}
                            //  onChange={() => setEmploymentType("Full Time")}
                          />
                          Full time
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="internship"
                            name="employment_type"
                            label="Internship"
                            value="Internship"
                            onChange={handleStatusChange}
                            checked={employmentType === "Internship"}
                            // onChange={() => setEmploymentType("Internship")}
                          />
                          Internship
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>

                {!isCurrentEmployment && (
                  <>
                    <div className="p-3">
                      <FormGroup>
                        <Label for="prevCompanyName">
                          Previous Company Name
                        </Label>
                        <Input
                          type="text"
                          id="prevCompanyName"
                          value={prevCompanyName}
                          onChange={(e) => setPrevCompanyName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="prevCompanyDesignation">
                          Previous Company Designation
                        </Label>
                        <Input
                          type="text"
                          id="prevCompanyDesignation"
                          value={prevCompanyDesignation}
                          onChange={(e) =>
                            setPrevCompanyDesignation(e.target.value)
                          }
                        />
                      </FormGroup>
                    </div>
                  </>
                )}

                {isCurrentEmployment && (
                  <div className="p-3">
                    <FormGroup>
                      <Label for="totalExperience">Total Experience</Label>
                      <Row>
                        <Col>
                          <Input
                            type="select"
                            name="totalExperienceYears"
                            id="totalExperienceYears"
                            onChange={handleTotalExperienceYearsChange}
                            value={totalExperienceYears}
                          >
                            {yearsArray.map((year) => (
                              <option key={year} value={year}>
                                {year} {year === 1 ? "year" : "years"}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col>
                          <Input
                            type="select"
                            name="totalExperienceMonths"
                            id="totalExperienceMonths"
                            onChange={handleTotalExperienceMonthsChange}
                            value={totalExperienceMonths}
                          >
                            {monthsArray.map((month) => (
                              <option key={month} value={month}>
                                {month} {month === 1 ? "month" : "months"}
                              </option>
                            ))}
                          </Input>
                        </Col>
                      </Row>
                    </FormGroup>
                  </div>
                )}

                <div className="p-3">
                  <>
                    {isCurrentEmployment && (
                      <FormGroup>
                        <Label for="currentCompanyName">
                          Current Company Name
                        </Label>
                        <Input
                          type="text"
                          name="currentCompanyName"
                          id="currentCompanyName"
                          value={currentCompanyName}
                          onChange={(e) =>
                            setCurrentCompanyName(e.target.value)
                          }
                        />
                      </FormGroup>
                    )}
                    {isCurrentEmployment && (
                      <FormGroup>
                        <Label for="currentDesignation">
                          Current Designation
                        </Label>
                        <Input
                          type="text"
                          name="currentDesignation"
                          id="currentDesignation"
                          value={currentCompanyDesignation}
                          onChange={(e) =>
                            setCurrentCompanyDesignation(e.target.value)
                          }
                        />
                      </FormGroup>
                    )}

                    <div className="p-1">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="year">Joining Date (in year)</Label>
                            <Input
                              type="select"
                              name="year"
                              id="year"
                              value={joiningYear}
                              onChange={(e) => setJoiningYear(e.target.value)}
                            >
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="month">Joining Date(in month)</Label>
                            <Input
                              type="select"
                              name="month"
                              id="month"
                              value={joiningMonth}
                              onChange={(e) => setJoiningMonth(e.target.value)}
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <FormGroup>
                      <Label for="currentSalary">Current Salary</Label>
                      <Input
                        type="number"
                        name="currentSalary"
                        id="currentSalary"
                        value={currentSalary}
                        onChange={(e) => {
                          const enteredValue = parseInt(e.target.value, 10);
                          if (enteredValue >= 0) {
                            setCurrentSalary(enteredValue);
                          }
                        }}
                      />
                    </FormGroup>
                  </>

                  {!isCurrentEmployment && (
                    <div className="p-1">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="year">Worked Till (in year)</Label>
                            <Input
                              type="select"
                              name="year"
                              id="year"
                              value={workedTillYear}
                              onChange={handleEndYearChange}
                            >
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="month">Worked Till(in month)</Label>
                            <Input
                              type="select"
                              name="month"
                              id="month"
                              value={workedTillMonth}
                              onChange={(e) =>
                                setWorkedTillMonth(e.target.value)
                              }
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  )}

                  <>
                    <FormGroup>
                      <Label for="jobProfile">Job Profile</Label>
                      <Input
                        rows={5}
                        type="textarea"
                        name="jobProfile"
                        id="jobProfile"
                        value={jobProfile}
                        onChange={handleJobProfile}
                      />
                      <small>{characterCount} Character(s) Left</small>
                    </FormGroup>
                    <FormGroup>
                      <Label for="skillsUsed">Skills Used</Label>
                      <Input
                        type="text"
                        name="skillsUsed"
                        id="skillsUsed"
                        value={skillsUsed}
                        onChange={(e) => setSkillsUsed(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="noticePeriod">Notice Period</Label>
                      <Input
                        type="text"
                        name="noticePeriod"
                        id="noticePeriod"
                        value={noticePeriod}
                        onChange={(e) => setNoticePeriod(e.target.value)}
                      />
                    </FormGroup>
                  </>
                </div>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditEmploymentModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteEmploymentModal}
            toggle={openDeleteEmploymentModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteEmploymentModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteEmploymentModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItem}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Employment;
