import React from "react";

const AdminLogo = () => {
  return (
    <a className="home-link" href="/admin" title="Hireco" rel="home">
      <h6 className="title">wokwantaim.com</h6>
    </a>
  );
};

export default AdminLogo;
