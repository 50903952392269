import React from "react";

const Navigation = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageClick = (page) => {
    onPageChange(page);
  };

  // const renderPageNumbers = () => {
  //   const pageNumbers = [];

  //   // Render page number 1
  //   pageNumbers.push(
  //     <a
  //       className={`page-nav-link${currentPage === 1 ? " current" : ""}`}
  //       key={1}
  //       href="#"
  //       onClick={() => handlePageClick(1)}
  //     >
  //       1
  //     </a>
  //   );

  //   // Render remaining page numbers starting from 2
  //   for (let i = 2; i <= totalPages; i++) {
  //     pageNumbers.push(
  //       <a
  //         className={`page-nav-link${currentPage === i ? " current" : ""}`}
  //         key={i}
  //         href="#"
  //         onClick={() => handlePageClick(i)}
  //       >
  //         {i}
  //       </a>
  //     );
  //   }

  //   return pageNumbers;
  // };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbers = 10; // Maximum number of page numbers to display

    // Calculate the range of page numbers to display
    let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages);

    // Adjust the startPage if the endPage is at the maximum limit
    if (endPage === totalPages) {
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    // Render page numbers within the range
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <a
          className={`page-nav-link${currentPage === i ? " current" : ""}`}
          key={i}
          href="#"
          onClick={() => handlePageClick(i)}
        >
          {i}
        </a>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="col-lg-12">
      <div className="job-pagination-block">
        <a
          className={`page-nav-link${currentPage === 1 ? " disabled" : ""}`}
          onClick={() => handlePageClick(currentPage - 1)}
          style={{ cursor: "pointer" }}
        >
          prev
        </a>
        {renderPageNumbers()}
        <a
          className={`page-nav-link${
            currentPage === totalPages ? " disabled" : ""
          }`}
          onClick={() => handlePageClick(currentPage + 1)}
          style={{ cursor: "pointer" }}
        >
          next
        </a>
      </div>
    </div>
  );
};

export default Navigation;
