import React, { useEffect } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import axios from "axios";
import { subURL } from "../../../utils/URL's";
import { useState } from "react";

import Navigation from "../../../components/layout/Navigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import EmployerHeader from "../../../components/layout/Employer/EmployerHeader";
import EmployerPageHeader from "../../../components/layout/Employer/EmployerPageHeader";
import EmployerFooter from "../../../components/layout/Employer/EmployerFooter";
var USERID, skillID;
const EmployerAddJobCategory = () => {
  const [skillName, setSkillName] = useState("");
  const [skillSetOptions, setSkillSetOptions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [userId, setUserId] = useState("");

  const [isEditSkills, setIsEditSkills] = useState(false);
  const [editSkillsModal, setIsEditSkillsModal] = useState(false);
  const openEditSkillsModal = () => setIsEditSkillsModal(!editSkillsModal);

  const [modal, setModal] = useState(false);
  const [delId, setDelId] = useState("");

  const openModal = () => setModal(!modal);

  async function getUserID() {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  }
  getUserID();

  useEffect(() => {
    getUserID();
  }, []);

  const [filteredData, setFilteredData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10; // Change this to the number of items to display per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length > 0) {
      if (startIndex < filteredData.length) {
        const slicedData = filteredData.slice(startIndex, endIndex);
        setFilteredJobs(slicedData);
      } else {
        // Handle the case where the start index is beyond the filtered data length
        setFilteredJobs([]);
      }
    } else {
      const slicedData = skillSetOptions.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    if (filteredData.length === 0 && skillSetOptions.length > 0) {
      const slicedData = skillSetOptions.slice(startIndex, endIndex);
      setFilteredJobs(slicedData);
    }
  }, [currentPage, filteredData, skillSetOptions, itemsPerPage]);

  useEffect(() => {
    axios
      .get(`${subURL}/jobcategory/`)
      .then((response) => {
        setSkillSetOptions(response.data);
        const initialTotalPages = Math.ceil(
          response.data.length / itemsPerPage
        );
        setTotalPages(initialTotalPages);
        setFilteredJobs(response.data.slice(0, itemsPerPage));
      })
      .catch((error) => {
        console.error("Error fetching skills:", error);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    async function storeData() {
      const formData = {
        job_category: skillName,
        user_account_id: userId,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/jobcategory/`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success!");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/jobcategory/`);

              setSkillSetOptions(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Category already Exists!");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
        }
      }
    }

    storeData();
    setSkillName("");
  };

  function handleEditSkills(id) {
    skillID = id;

    setIsEditSkills(true);
    setIsEditSkillsModal(true);

    const filteredDummuyData = skillSetOptions.find((data) => data.id === id);
    setSkillName(filteredDummuyData.job_category);
  }

  async function updateSkills() {
    const formData = {
      user_account_id: userId,
      job_category: skillName,
    };

    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(
        `${subURL}/jobcategory/${skillID}/`,
        formData,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        setModalTitle("Success");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setIsEditSkillsModal(false);

        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/jobcategory/${userId}/`);

            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setSkillSetOptions(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setIsEditSkillsModal(false);
      console.log(e);
    }
    setIsEditSkills(false);
    setSkillName("");
  }

  function handleDeleteSkills(id) {
    setDelId(id);
    setModal(true);
  }

  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/jobcategory/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/jobcategory/`);

            // setFilteredJobs(res.data.slice(0, 3));
            const updatedData = res.data;

            // Update the jobs state with the fetched data
            setSkillSetOptions(updatedData);

            // Update the filteredJobs state with the fetched data
            const initialTotalPages = Math.ceil(
              updatedData.length / itemsPerPage
            );
            setTotalPages(initialTotalPages);
            const slicedData = updatedData.slice(startIndex, endIndex);
            setFilteredJobs(slicedData);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed!");
      setSuccessMessage("Oops !Something went wrong,Please try again");
      setShowModal(true);
      console.log(error);
    }
  }
  return (
    <>
      <div className="site-main">
        <EmployerHeader />
        <EmployerPageHeader
          title="Add Job Category"
          breadcrumb="Job Category"
          className="pb-65 pb-lg-0"
        />

        <section className="ttm-row form-section clearfix">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title title-style-center_text">
                  <div className="title-header">
                    <h3>
                      fill the{" "}
                      <span className="text-theme-SkinColor"> details</span>
                    </h3>
                    <h2 className="title">Post Category</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Container>
            <Row>
              <Col lg={12}>
                <div className="bg-soft-primary p-3">
                  <h5 className="mb-0 fs-17">Add Category name!</h5>
                </div>
              </Col>
            </Row>
            {!isEditSkills && (
              <form
                action="#"
                className="job-post-form shadow mt-4"
                onSubmit={handleSubmit}
              >
                <div className="job-post-content box-shadow-md rounded-3 p-4">
                  <Row className="row">
                    <Col lg={12}>
                      <div className="mb-4">
                        <Label htmlFor="jobtitle" className="form-label">
                          Category Name
                        </Label>
                        <Input
                          type="text"
                          name="companyName"
                          id="companyName"
                          value={skillName}
                          onChange={(e) => setSkillName(e.target.value)}
                          required
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-square ttm-btn-style-fill ttm-btn-color-skincolor h-auto"
                          type="submit"
                          style={{
                            marginTop: "1em",
                          }}
                        >
                          Add
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </form>
            )}
            {/* {filteredData.length > 0 ? (
              <div className="mt-4">
                <Table bordered>
                  <thead>
                    <tr>
                      <th style={{fontWeight:'500'}}>Sl No</th>
                      <th style={{fontWeight:'500'}}>Skill Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredData.map((skill, key) => (
                      <tr key={skill.id}>
                        <td>{key + 1}</td>
                        <td>{skill.skill_set_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <h3 className="fs-15 mt-3 mb-5 text-center">No skills found.</h3>
            )} */}
            <div className="desc align-items-center">
              <ListGroup>
                <ListGroupItem className="d-flex justify-content-between align-items-center mt-35">
                  <h6 className="text-center">Categories</h6>
                </ListGroupItem>
                {filteredJobs.length > 0 ? (
                  <>
                    {filteredJobs.map((skill) => (
                      <ListGroupItem
                        key={skill.id}
                        className="d-flex justify-content-between align-items-center text-center pt-3"
                      >
                        <p>{skill.job_category}</p>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",

                            width: "7em",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            color="green"
                            onClick={() => handleEditSkills(skill.id)}
                            style={{ cursor: "pointer" }}
                          />

                          <FontAwesomeIcon
                            icon={faTrash}
                            color="red"
                            onClick={() => handleDeleteSkills(skill.id)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </ListGroupItem>
                    ))}
                  </>
                ) : (
                  <ListGroupItem>
                    <p> No categories found...</p>
                  </ListGroupItem>
                )}
              </ListGroup>
            </div>

            <div className="sidebar job-sidebar clearfix">
              {filteredJobs.length > 0 && (
                <Navigation
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              )}
            </div>

            {isEditSkills && (
              <div
                className="modal fade"
                id="applyNow"
                tabIndex="-1"
                aria-labelledby="applyNow"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <Modal
                    isOpen={editSkillsModal}
                    toggle={openEditSkillsModal}
                    centered
                  >
                    <ModalBody className="modal-body">
                      <div className="text-center mb-4">
                        <h5 className="modal-title" id="staticBackdropLabel">
                          Update Category
                        </h5>
                      </div>
                      <div className="position-absolute end-0 top-0 p-3">
                        <button
                          type="button"
                          onClick={openEditSkillsModal}
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <Form>
                        <Col lg={12}>
                          <div className="mb-4">
                            <Label htmlFor="jobtitle" className="form-label">
                              Category Name
                            </Label>
                            <Input
                              type="text"
                              name="companyName"
                              id="companyName"
                              value={skillName}
                              onChange={(e) => setSkillName(e.target.value)}
                              required
                            />
                          </div>
                        </Col>
                      </Form>
                      <div className="p-3 d-flex justify-content-between">
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                          type="submit"
                          onClick={(e) => setIsEditSkillsModal(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                          type="button"
                          onClick={updateSkills}
                        >
                          Update
                        </button>
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            )}

            <div
              className="modal fade"
              id="deleteModal"
              tabIndex="-1"
              aria-labelledby="deleteModal"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Delete Category ?
                    </h5>
                  </div>
                  <ModalBody>
                    <div>
                      <h6 className="text-danger">
                        Are you sure you want to delete ?
                      </h6>
                    </div>
                  </ModalBody>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={openModal}
                      className="btn btn-primary btn-sm"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={deleteItem}
                    >
                      Yes, delete
                    </button>
                  </div>
                </Modal>
              </div>
            </div>

            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
              <ModalHeader toggle={() => setShowModal(false)}>
                {modalTitle}
              </ModalHeader>
              <ModalBody>{successMessage}</ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={() => setShowModal(false)}>
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          </Container>
        </section>

        <EmployerFooter />
      </div>
    </>
  );
};

export default EmployerAddJobCategory;
