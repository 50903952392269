import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

const AdminNavBar = () => {
  const isActive = (route) => {
    return window.location.pathname === route;
  };
  return (
    <Router>
      <nav className="main-menu">
        <ul className="menu">
          <li
            className={`mega-menu-item ${isActive("/admin") ? "active" : ""}`}
          >
            <a href="/admin" className="mega-menu-link">
              Home
            </a>
          </li>

          <li
            className={`mega-menu-item with-submenu ${
              isActive("/postjob") || isActive("/viewpostedjobs")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Jobs
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/postjob">Post a Job</a>
              </li>
              <li>
                <a href="/viewpostedjobs">Job List</a>
              </li>
            </ul>
          </li>
          <li
            className={`mega-menu-item with-submenu ${
              isActive("/addcompany") || isActive("/companydetails")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Company
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/addcompany">Add Company </a>
              </li>
              <li>
                <a href="/companydetails">Company Details</a>
              </li>
            </ul>
          </li>

          <li
            className={`mega-menu-item ${
              isActive("/candidate_list") ? "active" : ""
            }`}
          >
            <a href="/candidate_list" className="mega-menu-link">
              Candidate List
            </a>
          </li>

          <li
            className={`mega-menu-item with-submenu ${
              isActive("/addskills") ||
              isActive("/addbusinesstream") ||
              isActive("/addjobcategory")
                ? "active"
                : ""
            }`}
          >
            <a href="#" className="mega-menu-link">
              Add Data
            </a>
            <ul className="mega-submenu">
              <li>
                <a href="/addskills">Add Skills</a>
              </li>
              <li>
                <a href="/addbusinesstream">Add Business Stream</a>
              </li>
              <li>
                <a href="/addjobcategory">Add Job Category</a>
              </li>
            </ul>
          </li>
        </ul>
        <style>
          {`
            .main-menu .mega-menu-item > .mega-menu-link::after {
              display: none;
            }
            
            .main-menu .mega-menu-item > .mega-menu-link + .mega-submenu {
              display: none;
            }
            
            .main-menu .mega-menu-item.with-submenu > .mega-menu-link::after {
              display: inline-block;
              content: "▼" 
              content: "\u2304"/* Dropdown arrow Unicode character */
            }
            
            .main-menu .mega-menu-item.with-submenu > .mega-menu-link + .mega-submenu {
              display: block;
            }
            .main-menu .mega-menu-item.active a {
              color: orange;
            }
            
            `}
        </style>
      </nav>
    </Router>
  );
};

export default AdminNavBar;
