import axios from "axios";
import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
var ID, USERID;
const maxLength = 1000; // Maximum number of characters allowed
const currentYear = new Date().getFullYear();
const years = [];
for (let year = currentYear; year >= 1900; year--) {
  years.push(year);
}

const months = [
  { value: "0", label: "Jan" },
  { value: "1", label: "Feb" },
  { value: "2", label: "Mar" },
  { value: "3", label: "Apr" },
  { value: "4", label: "May" },
  { value: "5", label: "Jun" },
  { value: "6", label: "Jul" },
  { value: "7", label: "Aug" },
  { value: "8", label: "Sep" },
  { value: "9", label: "Oct" },
  { value: "10", label: "Nov" },
  { value: "11", label: "Dec" },
];

const Projects = () => {
  const [userId, setUserId] = useState("");
  const [projectModal, setProjectModal] = useState(false);

  const openProjectModal = () => {
    setCharacterCount(maxLength);
    setProjectModal(!projectModal);
    setTitle("");
    setTag("");
    setClient("");
    setStatus("");
    setWorkFromYear("");
    setWorkFromMonth("");
    setYear("");
    setMonth("");
    setDetails("");
  };

  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [client, setClient] = useState("");
  const [showSection, setShowSection] = useState(true);
  const [workFromYear, setWorkFromYear] = useState("");

  const [workFromMonth, setWorkFromMonth] = useState("");
  const [details, setDetails] = useState("");

  const [status, setStatus] = useState("Inprogress");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [projectData, setProjectData] = useState([]);

  const [editProjectsModal, setEditProjectsModal] = useState(false);
  const openEditProjectsModal = () => setEditProjectsModal(!editProjectsModal);

  const [delId, setDelId] = useState("");
  const [deleteProjectsModal, setDeleteProjectsModal] = useState(false);
  const openDeleteProjectsModal = () =>
    setDeleteProjectsModal(!deleteProjectsModal);
  const [characterCount, setCharacterCount] = useState(maxLength);
  const [isEdit, setIsEdit] = useState(false);

  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/userprojects/${userId}/`)
      .then((res) => {
        setProjectData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleYearChange = (event) => {
    const selectedEndYear = event.target.value;
    setYear(selectedEndYear);

    if (
      selectedEndYear !== "" &&
      workFromYear !== "" &&
      parseInt(selectedEndYear) < parseInt(workFromYear)
    ) {
      alert("Ending year cannot be lesser than the starting year!");
      setYear("");
    }
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  const handleFromYearChange = (event) => {
    setWorkFromYear(event.target.value);
  };

  const handleFromMonthChange = (event) => {
    setWorkFromMonth(event.target.value);
  };
  const handleDetailsChange = (e) => {
    const inputValue = e.target.value;
    const remainingCharacters = maxLength - inputValue.length;

    if (remainingCharacters >= 0) {
      setDetails(inputValue);
      setCharacterCount(remainingCharacters);
    }
  };
  async function handleSubmit(event) {
    event.preventDefault();

    async function storeData() {
      const formData = {
        user_account_id: userId,
        project_title: title,
        tag_this_project_with_your_Education: tag,
        client_name: client,
        project_staus: status,
        worked_from_year: workFromYear,
        worked_from_month: workFromMonth,
        worked_till_month: month ? month : null,
        worked_till_year: year ? year : null,
        project_details: details,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/userprojects`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success!");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setProjectModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(`${subURL}/userprojects/${userId}/`);

              setProjectData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
              setTitle("");
              setTag("");
              setClient("");
              setStatus("");
              setWorkFromYear("");
              setWorkFromMonth("");
              setYear("");
              setMonth("");
              setDetails("");
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response.status === 400) {
          setModalTitle("Failed !");
          setSuccessMessage("Please enter all the fields");
          setShowModal(true);
        } else {
          setModalTitle("Failed !");
          setSuccessMessage("Oops !Something went wrong ,please try again");
          setShowModal(true);
        }
      }
    }

    storeData();
  }

  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditProjectsModal(true);

    const filteredDummuyData = projectData.find((data) => data.id === id);
    setTitle(filteredDummuyData.project_title);
    setTag(filteredDummuyData.tag_this_project_with_your_Education);
    setClient(filteredDummuyData.client_name);
    setStatus(filteredDummuyData.project_staus);
    setWorkFromYear(filteredDummuyData.worked_from_year);
    setWorkFromMonth(filteredDummuyData.worked_from_month);
    setYear(filteredDummuyData.worked_till_year);
    setMonth(filteredDummuyData.worked_till_month);
    setDetails(filteredDummuyData.project_details);
  }

  async function updateHandler() {
    const formData = {
      user_account_id: userId,
      project_title: title,
      tag_this_project_with_your_Education: tag,
      client_name: client,
      project_staus: status,
      worked_from_year: workFromYear,
      worked_from_month: workFromMonth,
      worked_till_month: month,
      worked_till_year: year,
      project_details: details,
    };
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(`${subURL}/userprojects/${ID}/`, formData, {
        headers: headers,
      });

      if (res.status === 200) {
        setModalTitle("Success!");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setEditProjectsModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/userprojects/${userId}/`);

            setProjectData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        setModalTitle("Failed!");
        setSuccessMessage("Oops !Something went wrong");
        setShowModal(true);
        setEditProjectsModal(false);
        console.log("error");
      }
    } catch (e) {
      console.log(e);
    }
  }
  function handleDelete(id) {
    setDeleteProjectsModal(true);
    setDelId(id);
  }
  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/userprojects/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Sucess!");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeleteProjectsModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(`${subURL}/userprojects/${userId}/`);

            setProjectData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed !");
      setSuccessMessage("Oops !Something went wrong ,please try again");
      setShowModal(true);
      console.log(error);
    }
  }
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Project Details</h5>
        <div className="button-container">
          <button
            className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
            type="submit"
            onClick={openProjectModal}
            style={{ marginRight: "25px" }}
          >
            Add
          </button>
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <>
          {projectData.length > 0 ? (
            <div className="desc">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Project Title</th>
                    <th style={{ fontWeight: "500" }}>Project Status</th>
                    <th style={{ fontWeight: "500" }}>Project Details</th>
                    <th style={{ fontWeight: "500" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData.map((projectData, key) => (
                    <tr key={projectData.id}>
                      <td>{key + 1}</td>
                      <td>{projectData.project_title}</td>
                      <td>{projectData.project_staus}</td>
                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {projectData.project_details}
                      </td>

                      <td
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "2em",
                          width: "auto",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          color="green"
                          onClick={() => handleEdit(projectData.id)}
                          style={{ cursor: "pointer" }}
                        />

                        <FontAwesomeIcon
                          icon={faTrash}
                          color="red"
                          onClick={() => handleDelete(projectData.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <ListGroupItem>
              <p> No project details found...</p>
            </ListGroupItem>
          )}
        </>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={projectModal} toggle={openProjectModal} centered>
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Project
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openProjectModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>

              <div className="p-1">
                <FormGroup>
                  <Label for="title">Project Title</Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Enter project title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="tag">
                    Tag this project with your Employment/Education
                  </Label>
                  <Input
                    type="text"
                    name="tag"
                    id="tag"
                    placeholder="Enter employment/education tag"
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="client">Client Name</Label>
                  <Input
                    type="text"
                    name="client"
                    id="client"
                    placeholder="Enter client name"
                    value={client}
                    onChange={(e) => setClient(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup tag="fieldset">
                  <Label for="status">Project Status</Label>
                  <Row>
                    <Col sm={10}>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="status"
                            value="Inprogress"
                            checked={status === "Inprogress"}
                            onChange={handleStatusChange}
                          />{" "}
                          Inprogress
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="status"
                            value="Finished"
                            checked={status === "Finished"}
                            onChange={handleStatusChange}
                          />{" "}
                          Finished
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="p-1">
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="year">Worked from year</Label>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            value={workFromYear}
                            onChange={handleFromYearChange}
                          >
                            <option value="">Select Year</option>
                            {years.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="month">Worked from month</Label>
                          <Input
                            type="select"
                            name="month"
                            id="month"
                            value={workFromMonth}
                            onChange={handleFromMonthChange}
                          >
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {status === "Finished" && (
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="year">Worked till year</Label>
                          <Input
                            type="select"
                            name="year"
                            id="year"
                            value={year}
                            onChange={handleYearChange}
                          >
                            <option value="">Select Year</option>
                            {years.map((year, index) => (
                              <option key={index} value={year}>
                                {year}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label for="month">Worked till month</Label>
                          <Input
                            type="select"
                            name="month"
                            id="month"
                            value={month}
                            onChange={handleMonthChange}
                          >
                            <option value="">Select Month</option>
                            {months.map((month, index) => (
                              <option key={index} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}
                </FormGroup>
              </div>

              <div className="p-1">
                <FormGroup>
                  <Label for="details">Project Details</Label>
                  <Input
                    rows={5}
                    type="textarea"
                    name="details"
                    id="details"
                    placeholder="Enter project details"
                    value={details}
                    onChange={handleDetailsChange}
                  />
                  <small>{characterCount} Character(s) Left</small>
                </FormGroup>
              </div>

              <div className="p-1">
                <button
                  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editProjectsModal}
              toggle={openEditProjectsModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Projects
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditProjectsModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="title">Project Title</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      placeholder="Enter project title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="tag">
                      Tag this project with your Employment/Education
                    </Label>
                    <Input
                      type="text"
                      name="tag"
                      id="tag"
                      placeholder="Enter employment/education tag"
                      value={tag}
                      onChange={(e) => setTag(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="client">Client Name</Label>
                    <Input
                      type="text"
                      name="client"
                      id="client"
                      placeholder="Enter client name"
                      value={client}
                      onChange={(e) => setClient(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup tag="fieldset">
                    <Label for="status">Project Status</Label>
                    <Row>
                      <Col sm={10}>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="radio"
                              name="status"
                              value="Inprogress"
                              checked={status === "Inprogress"}
                              onChange={handleStatusChange}
                            />{" "}
                            Inprogress
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="status"
                              value="Finished"
                              checked={status === "Finished"}
                              onChange={handleStatusChange}
                            />{" "}
                            Finished
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="p-1">
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="year">Worked from year</Label>
                            <Input
                              type="select"
                              name="year"
                              id="year"
                              value={workFromYear}
                              onChange={handleFromYearChange}
                            >
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="month">Worked from month</Label>
                            <Input
                              type="select"
                              name="month"
                              id="month"
                              value={workFromMonth}
                              onChange={handleFromMonthChange}
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {status === "Finished" && (
                      <Row>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="year">Worked till year</Label>
                            <Input
                              type="select"
                              name="year"
                              id="year"
                              value={year}
                              onChange={handleYearChange}
                            >
                              {years.map((year, index) => (
                                <option key={index} value={year}>
                                  {year}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Label for="month">Worked till month</Label>
                            <Input
                              type="select"
                              name="month"
                              id="month"
                              value={month}
                              onChange={handleMonthChange}
                            >
                              {months.map((month, index) => (
                                <option key={index} value={month.value}>
                                  {month.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </FormGroup>
                </div>

                <div className="p-1">
                  <FormGroup>
                    <Label for="details">Project Details</Label>
                    <Input
                      rows={5}
                      type="textarea"
                      name="details"
                      id="details"
                      placeholder="Enter project details"
                      value={details}
                      onChange={handleDetailsChange}
                    />
                    <small>{characterCount} Character(s) Left</small>
                  </FormGroup>
                </div>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditProjectsModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deleteProjectsModal}
            toggle={openDeleteProjectsModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeleteProjectsModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeleteProjectsModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItem}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {modalTitle}
        </ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Projects;
