import axios from "axios";
import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { ListGroupItem, Table } from "reactstrap";
import { subURL } from "../../../../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const currentYear = 30;
const yearOptions = [];

for (let year = 0; year <= currentYear; year++) {
  yearOptions.push(year.toString());
}

const currentYear1 = new Date().getFullYear();
const yearOptions1 = [];

for (let year = 1950; year <= currentYear1; year++) {
  yearOptions1.push(year.toString());
}

const Skills = () => {
  const { id } = useParams();

  const [skills, setSkills] = useState([]);

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  useEffect(() => {
    axios
      .get(`${subURL}/userskills/${id}/`)
      .then((res) => {
        setSkills(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>IT Skills</h5>
        <FontAwesomeIcon
          icon={showSection ? faEye : faEyeSlash}
          color="black"
          onClick={toggleSection}
          style={{ cursor: "pointer" }}
        />
      </div>

      {showSection && (
        <div className="desc">
          {skills.length > 0 ? (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ fontWeight: "500" }}>#</th>
                  <th style={{ fontWeight: "500" }}>Skill Name</th>
                  <th style={{ fontWeight: "500" }}>Software Version</th>
                  <th style={{ fontWeight: "500" }}>Last used</th>
                </tr>
              </thead>
              <tbody>
                {skills.map((skill, key) => (
                  <tr key={skill.id}>
                    <td>{key + 1}</td>
                    <td>{skill.skill_name}</td>
                    <td>{skill.software_version}</td>
                    <td>{skill.last_used}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ListGroupItem>
              <p> No skills found.</p>
            </ListGroupItem>
          )}
        </div>
      )}
    </div>
  );
};

export default Skills;
