import React, { Component } from "react";
import Slider from "react-slick";
import Logo1 from "../../images/logo1.png";
import Logo2 from "../../images/logo2.png";
import Logo3 from "../../images/logo3.png";
import Logo4 from "../../images/logo4.png";
import Logo5 from "../../images/logo5.png";
import BannerImage from "../../newimages/adminbanner.jpg"

export class Banner extends Component {
  state = {
    show: false,
  };
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 680,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    return (
      <Slider
        className="slick_slider banner_slider banner_slider_3"
        {...slick_slider}
        slidesToShow={1}
        autoplay={false}
        fade="true"
      >
        <div className="slide bg-layer">
          <div
            className="slide_img"
            style={{
              backgroundImage: `url(${BannerImage})`,
            }}
            // style={{
            //   backgroundImage:
            //     "url(https://themetechmount.com/react/hireco/images/slides/slider-mainbg-005.jpg)",
            // }}
          ></div>
          <div className="slide__content pb-50 pb-xl-0 pt-130 pt-xl-0">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-8">
                  <div className="slide__content--headings ttm-textcolor-white">
                    <h2 className="animated animate__fadeInDown">
                      A new{" "}
                      <span className="text-theme-SkinColor fw-normal">
                        {" "}
                        Experience{" "}
                      </span>
                    </h2>
                    <h2 className="animated animate__fadeInDown">
                      {" "}
                      of Human Resource{" "}
                    </h2>
                    <div className="mt-20 mb-30 mb-md-15">
                      <p
                        className="animated animate__fadeInDown"
                        // className="d-none d-md-block"
                      >
                        As an admin, you'll have access to powerful tools and
                        features to manage job postings, track applications, and
                        connect with top talent. Let's work together to build a
                        better workforce for the future!
                      </p>
                    </div>
                    <div
                      className="animated animate__fadeInUp"
                      data-delay="1.4"
                    >
                      <a
                        className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
                        href="/viewcandidates"
                      >
                        Find Right Candidates!
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="d-none d-xl-block text-center position-relative">
                    <div className="z-index-1 position-relative">
                      <img
                        className="rounded-circle box-shadow m-auto img-fuild"
                        src="https://themetechmount.com/react/hireco/images/slides/single-01.jpg"
                      ></img>
                    </div>
                    <div className="cmp-logo one">
                      <img className="img-fluid" src={Logo1} />
                    </div>
                    <div className="cmp-logo two">
                      <img className="img-fluid" src={Logo2} />
                    </div>
                    <div className="cmp-logo three">
                      <img className="img-fluid" src={Logo3} />
                    </div>
                    <div className="cmp-logo four">
                      <img className="img-fluid" src={Logo4} />
                    </div>
                    <div className="cmp-logo five">
                      <img className="img-fluid" src={Logo5} />
                    </div>
                    <div className="arrow-one">
                      <img src="images/slides/arrow-1.png" />
                    </div>
                    <div className="arrow-two">
                      <img src="images/slides/arrow-2.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default Banner;
