import React from "react";
import Footer from "../../components/layout/Footer";
import Header from "../../components/layout/Header";
import PageHeader from "../../components/layout/PageHeader";
import { imageURL, mainURL, subURL } from "../../utils/URL's";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Aboutme from "./Aboutme";
import Educationdetails from "./Educationdetails";
import Projects from "./Projects";
import Employment from "./Employment";
import Accomplishments from "./Accomplishments";
import PersonalProfile from "./PersonalProfile";
import Skills from "./Skills";
var USERID;
const MyProfile = () => {
  const [userId, setUserId] = useState("");
  const [singleData, setSingleData] = useState([]);

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(`${subURL}/user_save_account/${userId}`);

        const {
          id,
          last_name,
          first_name,
          experience_level,
          isactive,
          resume,
          join_type,
          profile_photo,
          phone_number,
          email_address,
          password,
          subscribed,
          subscribed_email_id,
          createdDate,
          modifiedDate,
        } = res.data[0];

        const singleData = {
          id,
          first_name,
          last_name,
          experience_level: experience_level.experince_type,
          isactive,
          resume,
          join_type: join_type.job_type,
          profile_photo,
          phone_number,
          email_address,
          password,
          subscribed,
          subscribed_email_id,
          createdDate,
          modifiedDate,
        };

        setSingleData(singleData);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [userId]);

  const fetchContentType = (url) => {
    return axios
      .head(url)
      .then((response) => {
        return response.headers["content-type"];
      })
      .catch((error) => console.log(error));
  };

  const forceDownload = (response, title, contentType) => {
    if (!response || !response.data) {
      console.error("Response or response data is undefined");
      return;
    }
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  };

  const downloadWithAxios = (url, title, contentType) => {
    axios({
      method: "get",
      url,
      responseType: "blob",
      headers: {
        Accept: contentType,
      },
    })
      .then((response) => {
        forceDownload(response, title, contentType);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="site-main">
      <Header />

      <PageHeader title="Profile" breadcrumb="My Profile" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget candidate-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>My Profile
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">First Name :</b>
                      <span>
                        {singleData.first_name?.charAt(0).toUpperCase() +
                          singleData.first_name?.slice(1)}
                      </span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Last Name:</b>
                      <span>
                        {singleData.last_name?.charAt(0).toUpperCase() +
                          singleData.last_name?.slice(1)}
                      </span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Experience Level:</b>
                      <span>{singleData.experience_level}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Phone Number:</b>
                      <span>{singleData.phone_number}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Email:</b>
                      <span>{singleData.email_address}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Join Type:</b>
                      <span>{singleData.join_type}</span>
                    </li>
                  </ul>
                </aside>
              </div>
              <aside className="widget widget-download">
                <ul className="download">
                  <li>
                    <button
                      onClick={() => {
                        const fileUrl = `${imageURL}${singleData.resume}`;
                        fetchContentType(fileUrl)
                          .then((contentType) => {
                            downloadWithAxios(
                              fileUrl,
                              singleData.resume.split("/").pop(),
                              contentType
                            );
                          })
                          .catch((error) => {
                            console.error(
                              "Error fetching content type:",
                              error
                            );
                          });
                      }}
                    >
                      Download Cv
                    </button>
                    <i className="ti ti-files"></i>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="candidate-data">
                    <div className="candidate-img">
                      <img
                        src={`${imageURL}${singleData.profile_photo}`}
                        alt="user"
                      />
                    </div>
                    <div className="candidate-caption">
                      <h5>
                        {singleData.first_name} {singleData.last_name}
                      </h5>

                      <div className="meta-line">
                        <span>
                          <i className="ti ti-email"></i>{" "}
                          {singleData.email_address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <Aboutme />
                  <PersonalProfile />
                  <Educationdetails />

                  <Projects />
                  <Employment />
                  <Accomplishments />
                  <Skills />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MyProfile;
