import React from "react";
import Header from "../components/layout/Header";
import PageHeader from "../components/layout/PageHeader";
import Footer from "../components/layout/Footer";

import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import flipkart from "../newimages/flipkart-ot.png";
import blogimgflipkart from "../newimages/flipkart-generic-poster_ED.jpg";
import cts from "../newimages/cognizant-ot.png";
import tcs from "../newimages/tcs-ot.png";
import byjus from "../newimages/byjus-ot.png";
import amazon from "../newimages/amazon-ot.png";
import aacenture from "../newimages/accenture-ot.png";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { mainURL, subURL } from "../utils/URL's";

const Blog_details = () => {
  const { company } = useParams();

  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobsByCompany, setJobsByCompany] = useState([]);

  // Define the interview data with questions and answers
  const interviewData = {
    flipkart: {
      name: "Flipkart",
      image: flipkart,
      blogimage: blogimgflipkart,
      interviews: "488 Interviews",
      questions: [
        "Why do you want to work for Flipkart and what excites you about the e-commerce industry?",
        "Describe your experience with data analysis and how you've used it to drive business decisions",
        "How do you handle change and ambiguity in a fast-paced work environment?",
      ],
      answers: [
        "I am thrilled about the opportunity to work at Flipkart because it is one of the leading e-commerce companies with a strong reputation for innovation and customer-centric approach. The e-commerce industry excites me as it continuously evolves, and I am eager to contribute my skills to an organization that drives such dynamic change in the retail space.",
        "In my previous role, I regularly analyzed customer data to identify trends and patterns. By analyzing purchase behavior, I helped optimize the product offerings and recommended personalized marketing strategies. These data-driven insights resulted in improved customer engagement and higher sales, showcasing the power of data analysis in driving informed business decisions.",
        "I am adaptable and thrive in fast-paced environments. I understand that change is inevitable in a dynamic industry like e-commerce. I stay proactive by keeping an open mind and embracing challenges as opportunities for growth. I prioritize tasks, communicate effectively with stakeholders, and remain agile in my approach to ensure successful outcomes, even in ambiguous situations.",
      ],
    },

    cognizant: {
      name: "Cognizant",
      image: cts,
      interviews: "14 Open Positions",
      questions: [
        "What interests you about working with Cognizant specifically?",
        "How do you handle tight deadlines and multiple projects simultaneously?",
        "Describe a situation where you demonstrated leadership qualities.",
      ],
      answers: [
        "Cognizant's reputation for being a global leader in technology services and consulting is what initially attracted me. I am particularly interested in the diverse range of projects that Cognizant undertakes, which would offer me valuable opportunities to work on cutting-edge technologies and gain exposure to various industries. Moreover, I believe Cognizant's commitment to employee growth and development aligns perfectly with my own career aspirations.",
        "To manage tight deadlines and multiple projects, I prioritize tasks based on their importance and urgency. I break down larger projects into smaller milestones and set realistic timelines. I am also proactive in communicating with stakeholders to manage expectations and seek assistance when necessary. Time management, organization, and effective communication are the keys to ensuring all projects are delivered on time.",
        "In my previous role, I was appointed as the team lead for a critical project. I took on the responsibility of coordinating team efforts, setting project timelines, and delegating tasks based on individual strengths. I encouraged open communication and supported team members in overcoming challenges. As a result, we completed the project ahead of schedule, and the team felt motivated and appreciated for their contributions.",
      ],
    },

    tcs: {
      name: "TCS",
      image: tcs,
      interviews: "58 Open Positions",
      questions: [
        "Tell us about yourself and your background.",
        "What do you know about TCS and why do you want to work here?",
        "Explain your experience with a challenging project and how you handled it.",
      ],
      answers: [
        "I have a bachelor's degree in Computer Science and five years of experience as a software developer. During my career, I've worked on various projects, ranging from web application development to data analysis. I am passionate about solving complex problems through programming and always eager to learn new technologies.",
        "TCS is one of the leading IT services companies globally, known for its commitment to excellence and innovation. I am impressed by the company's diverse projects and opportunities for professional growth. I believe TCS's work culture aligns perfectly with my values, and I am excited to contribute my skills to such a renowned organization.",
        "In my previous role, I was part of a team responsible for revamping a critical financial application. We faced tight deadlines and complex requirements. To handle the challenges, I organized regular meetings, established clear communication channels, and encouraged collaboration among team members. By breaking down the project into smaller milestones, we managed to deliver the project successfully within the given timeframe.",
      ],
    },

    amazon: {
      name: "Amazon",
      image: amazon,
      interviews: "20 Open Positions",
      questions: [
        "Tell us about yourself and why you want to work for Amazon.",
        "What do you know about Amazon and its core principles?",
        "How do you handle tight deadlines and prioritize tasks effectively?",
      ],
      answers: [
        "I am a results-driven professional with a strong background in supply chain management and logistics. Amazon's commitment to innovation, customer-centricity, and its fast-paced environment greatly appeals to me. I believe that Amazon's culture of continuous learning and its dedication to providing exceptional customer experiences align perfectly with my own values, and I am eager to contribute my skills to such a renowned organization.",
        "Amazon is a multinational technology company known for its e-commerce, cloud computing, and artificial intelligence initiatives. One of Amazon's core principles is customer obsession, where it aims to exceed customer expectations and focus relentlessly on their needs. The company also emphasizes innovation, operational excellence, and long-term thinking in its business strategies. ",
        "To handle tight deadlines, I first assess the tasks at hand and their respective deadlines. I prioritize tasks based on their urgency and impact on project milestones. By breaking down larger projects into smaller achievable goals, I can better manage my time and ensure that I meet deadlines. Additionally, I am proactive in communicating with stakeholders to manage expectations and allocate resources effectively to deliver high-quality results on time.",
      ],
    },

    byjus: {
      name: "Byjus",
      image: byjus,
      interviews: "20 Open Positions",
      questions: [
        "What do you know about Byju's and its products/services?",
        "How would you contribute to Byju's mission of transforming education through technology?",
        "How do you handle feedback and constructive criticism on your work?",
      ],
      answers: [
        "Byju's is a leading ed-tech company that offers a comprehensive learning platform for students. It provides engaging and interactive content, including video lessons and adaptive learning modules, to help students understand complex concepts effectively. Byju's learning app covers various subjects and competencies, making it a valuable resource for students at all academic levels.",
        "As a lifelong learner, I am passionate about leveraging technology to enhance education. I believe my experience in instructional design and content development can contribute to creating engaging and impactful learning experiences for students. Additionally, I am excited about exploring innovative ways to improve learning outcomes and make education accessible to a broader audience.",
        "I value feedback and see it as an opportunity for growth. When receiving feedback or constructive criticism, I remain open-minded and receptive. I use the feedback to reflect on my work, identify areas of improvement, and make necessary adjustments. Constructive criticism helps me become a better educator and content creator, and I appreciate any insights that contribute to enhancing the learning experience.",
      ],
    },

    accenture: {
      name: "Accenture",
      image: aacenture,
      interviews: "28 Open Positions",
      questions: [
        "What do you know about Accenture's services and areas of expertise?",
        "Tell us about yourself and your relevant experience for the position at Accenture.",
        "Accenture values innovation. How do you contribute to innovation in your work?",
      ],
      answers: [
        "Accenture offers a wide range of services, including consulting, strategy, digital, technology, and operations, among others. The company is renowned for its expertise in areas such as cloud computing, artificial intelligence, analytics, and digital transformation. Accenture's industry-focused approach enables them to provide customized solutions that address clients' specific challenges and drive sustainable growth.",
        "I am a dedicated professional with a strong background in IT consulting. I have worked on diverse projects, ranging from digital transformation initiatives to process optimization. My experience in collaborating with cross-functional teams and my ability to understand client needs make me well-suited for a position at Accenture, where innovation and client satisfaction are paramount.",
        "I embrace a curious and forward-thinking approach to my work. I continuously seek opportunities for process improvement and look for creative solutions to challenges. I am not afraid to challenge conventional methods and encourage my team to think outside the box. Additionally, I actively participate in innovation initiatives and share ideas that can bring value to our clients and drive meaningful change.",
      ],
    },
  };

  // Get the interview data for the selected company
  const selectedCompany = interviewData[company.toLowerCase()];

  useEffect(() => {
    const fetchJobCategories = async () => {
      const response = await axios.get(
        `${subURL}/jobcategorybycompanyname/${selectedCompany.name}`
      );

      setJobCategories(response.data.slice(0, 5));
    };

    fetchJobCategories();
  }, [selectedCompany.name]);

  useEffect(() => {
    const fetchJobsByCompany = async () => {
      const response = await axios.get(
        `${subURL}/filtereJobByCompany/${selectedCompany.name}`
      );

      setJobsByCompany(response.data.slice(0, 5));
    };

    fetchJobsByCompany();
  }, [selectedCompany.name]);

  // Function to toggle the expanded state for a specific question
  const toggleExpand = (index) => {
    setExpandedQuestions((prev) => {
      const updatedExpanded = [...prev];
      updatedExpanded[index] = !updatedExpanded[index];
      return updatedExpanded;
    });
  };

  return (
    <div className="site-main">
      <Header />

      <PageHeader title="Interview Questions" breadcrumb="interviews" />

      <div className="ttm-row sidebar ttm-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 content-area">
              <article className="post ttm-blog-single clearfix">
                <div className="ttm-blog-single-content">
                  <div className="ttm-post-entry-header">
                    <div className="post-meta">
                      <span className="ttm-meta-line author">
                        <img
                          src={selectedCompany.image}
                          className="img-fluid"
                          height="80"
                          width="80"
                          alt="author"
                        />
                        {selectedCompany.name}
                      </span>
                    </div>
                  </div>
                  <div className="entry-content">
                    <div className="ttm-box-desc-text">
                      <p>
                        Lorem Ipsum is simply dummy text of the printting and
                        typesetting industry. Lorem Ipsum contary io on hasbeen
                        the industry’s standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>

                      <p>
                        Contary typesetting industry. Lorem Ipsum on hasbeen the
                        industry’s standard live youmgdummy text ever the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled.
                      </p>

                      <blockquote>
                        <div className="qoute-text">
                          Mpsum is simply dummy text of the printing and
                          conatary typesg industy. Lorem Ipsum on hasbeen the
                          industry’s standard dummy te
                        </div>
                        <cite>Alex Sam Martin</cite>
                      </blockquote>

                      {selectedCompany.questions.map((question, index) => (
                        <div key={index}>
                          <h6>{question}</h6>
                          {/* Toggle full answer visibility based on state */}
                          {expandedQuestions[index] ? (
                            <p>{selectedCompany.answers[index]}</p>
                          ) : (
                            <p>
                              {selectedCompany.answers[index].substring(0, 100)}
                              ...
                              <div className="timeline-body">
                                <Link
                                  className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-darkgrey"
                                  href="#"
                                  onClick={() => toggleExpand(index)}
                                >
                                  Read More
                                  <i className="ti ti-angle-double-right"></i>
                                </Link>
                              </div>
                            </p>
                          )}
                        </div>
                      ))}

                      <div className="social-media-block">
                        <div className="d-sm-flex justify-content-between">
                          <div className="ttm-social-share-wrapper mt-15">
                            <h6 className="pr-10">Share : </h6>
                            <ul className="social-icons">
                              <li>
                                <a
                                  href="#"
                                  rel="noopener"
                                  aria-label="facebook"
                                >
                                  <i className="ti ti-facebook"></i>
                                </a>
                              </li>
                              <li>
                                <a href="#" rel="noopener" aria-label="twitter">
                                  <i className="ti ti-twitter-alt"></i>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  rel="noopener"
                                  aria-label="linkedin"
                                >
                                  <i className="ti ti-linkedin"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div>
                  <h1>{selectedCompany.name}</h1>
                  <h3>{selectedCompany.interviews}</h3>
                  <ul>
                    {selectedCompany.questions.map((question, index) => (
                      <li key={index}>{question}</li>
                    ))}
                  </ul>
                </div> */}
                {/* ttm-blog-classic-content end */}
              </article>
              {/* post end */}
            </div>

            <div className="col-lg-4 widget-area">
              <div className="sidebar-right">
                {jobCategories.length > 0 && (
                  <aside className="widget widget-Categories with-title">
                    <h3 className="widget-title">Categories</h3>

                    <ul>
                      {jobCategories.map((category) => (
                        <li>
                          <a
                            href={`/filtercategorycompanywise/${selectedCompany.name}/${category.job_category}`}
                          >
                            {category.job_category}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </aside>
                )}
                <aside className="widget widget-recent-post with-title">
                  <h3 className="widget-title">
                    Jobs at {selectedCompany.name}
                  </h3>
                  <ul>
                    {jobsByCompany.map((job) => (
                      <li>
                        <a href={`/company_details/${job.company_id.id}`}>
                          <img
                            className="img-fluid"
                            src={`${mainURL}${job?.company_id?.company_image}`}
                            alt="post-img"
                          />
                        </a>
                        <div className="post-detail">
                          <a href={`/jobdetails/${job.id}`}>{job.job_title}</a>
                          <span className="post-date">
                            {" "}
                            {job?.createdDate?.substring(0, 10)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {jobsByCompany.length === 0 && (
                    <p className="text-center fs-16 mt-13">No jobs found.</p>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Blog_details;
