import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListGroupItem, Table } from "reactstrap";
import { subURL } from "../../../../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Projects = () => {
  const { id } = useParams();

  const [projectData, setProjectData] = useState([]);

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  useEffect(() => {
    axios
      .get(`${subURL}/userprojects/${id}/`)
      .then((res) => {
        setProjectData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Project Details</h5>
        <FontAwesomeIcon
          icon={showSection ? faEye : faEyeSlash}
          color="black"
          onClick={toggleSection}
          style={{ cursor: "pointer" }}
        />
      </div>

      {showSection && (
        <>
          {projectData.length > 0 ? (
            <div className="desc">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th style={{ fontWeight: "500" }}>#</th>
                    <th style={{ fontWeight: "500" }}>Project Title</th>
                    <th style={{ fontWeight: "500" }}>Project Status</th>
                    <th style={{ fontWeight: "500" }}>Project Details</th>
                  </tr>
                </thead>
                <tbody>
                  {projectData.map((projectData) => (
                    <tr key={projectData.id}>
                      <td>{projectData.id}</td>
                      <td>{projectData.project_title}</td>
                      <td>{projectData.project_staus}</td>
                      <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                        {projectData.project_details}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <ListGroupItem>
              <p> No project details found...</p>
            </ListGroupItem>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;
