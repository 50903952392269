import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ListGroupItem, Table } from "reactstrap";

import axios from "axios";
import { subURL } from "../../../../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const PersonalProfile = () => {
  const { id } = useParams();

  const [personalInfoData, setPersonalInfoData] = useState([]);

  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  useEffect(() => {
    axios
      .get(`${subURL}/userpersonalinfo/${id}/`)
      .then((res) => {
        setPersonalInfoData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>Personal Details</h5>
        <FontAwesomeIcon
          icon={showSection ? faEye : faEyeSlash}
          color="black"
          onClick={toggleSection}
          style={{ cursor: "pointer" }}
        />
      </div>

      {showSection && (
        <div className="desc">
          {personalInfoData.length > 0 ? (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ fontWeight: "500" }}>#</th>
                  <th style={{ fontWeight: "500" }}>Current Industry</th>
                  <th style={{ fontWeight: "500" }}>Preferred Shift</th>
                  <th style={{ fontWeight: "500" }}>Gender</th>
                  <th style={{ fontWeight: "500" }}>Address</th>
                </tr>
              </thead>
              <tbody>
                {personalInfoData.map((personalInfoData, key) => (
                  <tr key={personalInfoData.id}>
                    <td>{key + 1}</td>
                    <td>{personalInfoData.current_industry}</td>
                    <td>{personalInfoData.preferred_shift}</td>
                    <td>{personalInfoData.gender}</td>
                    <td style={{ wordWrap: "break-word", maxWidth: "300px" }}>
                      {personalInfoData.permanent_address}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ListGroupItem>
              <p> No data found.</p>
            </ListGroupItem>
          )}
        </div>
      )}
    </div>
  );
};

export default PersonalProfile;
