import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEffect } from "react";

function Mobile_menu() {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const [collapse1, setCollapse1] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("userid") !== null
  );
  useEffect(() => {
    setIsLoggedIn(localStorage.getItem("userid") !== null);
  }, []);

  const toggleSingleCollapse = () => {
    setCollapse1(!collapse1);
  };
  const logoutHandler = () => {
    (async () => {
      try {
        await localStorage.removeItem("userid");
        await localStorage.removeItem("username");
        await localStorage.removeItem("isAdmin");
        await localStorage.removeItem("usertypeid");
        // window.location.reload();
        setTimeout(() => {
          window.location.reload();
        }, 100);
        history.push("/");
      } catch (error) {
        console.log(error);
      }
    })();
  };
  return (
    <Router>
      <MDBNavbar>
        <MDBHamburgerToggler id="hamburger1" onClick={toggleSingleCollapse} />
        <MDBCollapse isOpen={collapse1} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownItem href="/" nav caret>
                  Home
                </MDBDropdownItem>
              </MDBDropdown>
            </MDBNavItem>

            {isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem href="/contactus" nav caret>
                    Contact us
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}

            {isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem href="/job_list" nav caret>
                    Job List
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}

            {!isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem href="/alljobs" nav caret>
                    Job List
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}

            {isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem href="/myjobs" nav caret>
                    My Jobs
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}

            {isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem href="/myprofile" nav caret>
                    My Profile
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}
            {isLoggedIn && (
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownItem onClick={() => setShowModal(true)}>
                    Logout
                  </MDBDropdownItem>
                </MDBDropdown>
              </MDBNavItem>
            )}
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBNavbar>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Logout !</ModalHeader>
        <ModalBody>
          <h6>Are you sure you want to logout ?</h6>
        </ModalBody>
        <ModalFooter>
          <Button color="grey" onClick={() => setShowModal(false)}>
            No
          </Button>
          <Button color="primary" onClick={logoutHandler}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </Router>
  );
}

export default Mobile_menu;
