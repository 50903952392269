import React from "react";

import { imageURL, mainURL, subURL } from "../../../../../utils/URL's";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Educationdetails from "../MyProfile/Educationdetails";
import Employment from "../MyProfile/Employment";
import Skills from "../MyProfile/Skills";
import Accomplishments from "../MyProfile/Accomplishments";
import PersonalProfile from "../MyProfile/PersonalProfile";

import AdminHeader from "../../../../../components/layout/Admin/AdminHeader";
import AdminPageHeader from "../../../../../components/layout/Admin/AdminPageHeader";
import AdminFooter from "../../../../../components/layout/Admin/AdminFooter";
import Projects from "./Projects";
import { useParams } from "react-router-dom";

const CandidateProfile = () => {


  

  const [userImage, setUserImage] = useState("");
  const [userData, setUserData] = useState("");
  const [userCV, setUserCV] = useState("");
  const { id } = useParams();



  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get(`${subURL}/user_save_account/${id}`);

        const {
          last_name,
          first_name,
          experience_level,
          isactive,
          resume,
          join_type,
          profile_photo,
          phone_number,
          email_address,
          password,
          subscribed,
          subscribed_email_id,
          createdDate,
          modifiedDate,
        } = res.data[0];

        const singleData = {
          first_name,
          last_name,
          experience_level: experience_level.experince_type,
          isactive,
          resume,
          join_type: join_type.job_type,
          profile_photo,
          phone_number,
          email_address,
          password,
          subscribed,
          subscribed_email_id,
          createdDate,
          modifiedDate,
        };

        setUserData(singleData);
        setUserImage(singleData.profile_photo);
      } catch (error) {
        console.log(error);
      }
    }

    fetchData();
  }, [id]);

  useEffect(() => {
    async function fetechUserDetails() {
      const res = await axios.get(`${subURL}/user_save_account/${id}`);
      setUserCV(res.data[0].resume);
    }
    fetechUserDetails();
  }, [id]);

  const fetchContentType = (url) => {
    return axios
      .head(url)
      .then((response) => {
        return response.headers["content-type"];
      })
      .catch((error) => console.log(error));
  };

  const forceDownload = (response, title, contentType) => {
    if (!response || !response.data) {
      console.error("Response or response data is undefined");
      return;
    }
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: contentType })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", title);
    document.body.appendChild(link);
    link.click();
  };

  const downloadWithAxios = (url, title, contentType) => {
    axios({
      method: "get",
      url,
      responseType: "blob",
      headers: {
        Accept: contentType,
      },
    })
      .then((response) => {
        forceDownload(response, title, contentType);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="site-main">
      <AdminHeader />

      <AdminPageHeader title="Profile" breadcrumb="My Profile" />

      <div className="ttm-row sidebar job-sidebar clearfix">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 widget-area sidebar-left job_list-widget-area">
              <div className="job_list-widget">
                <aside className="widget candidate-widget">
                  <h3 className="widget-title">
                    <i className="ti ti-files"></i>My Profile
                  </h3>
                  <ul>
                    <li className="d-flex">
                      <b className="mr-5">First Name :</b>
                      <span>
                        {" "}
                        {userData.first_name?.charAt(0).toUpperCase() +
                          userData.first_name?.slice(1)}
                      </span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Last Name:</b>
                      <span>
                        {" "}
                        {userData.last_name?.charAt(0).toUpperCase() +
                          userData.last_name?.slice(1)}
                      </span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Experience Level:</b>
                      <span>{userData.experience_level}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Phone Number:</b>
                      <span>{userData.phone_number}</span>
                    </li>
                    <li className="d-flex">
                      <b className="mr-5">Email:</b>
                      <span>{userData.email_address}</span>
                    </li>
                  </ul>
                </aside>
              </div>
              <aside className="widget widget-download">
                <ul className="download">
                  <li>
                    <button
                      onClick={() => {
                        const fileUrl = `${imageURL}${userCV}`;
                        fetchContentType(fileUrl)
                          .then((contentType) => {
                            downloadWithAxios(
                              fileUrl,
                              userData.resume.split("/").pop(),
                              contentType
                            );
                          })
                          .catch((error) => {
                            console.error(
                              "Error fetching content type:",
                              error
                            );
                          });
                      }}
                    >
                      Download Cv
                    </button>
                    <i className="ti ti-files"></i>
                  </li>
                </ul>
              </aside>
            </div>
            <div className="col-lg-8 content-area">
              <div className="row">
                <div className="col-md-12">
                  <div className="candidate-data">
                    <div className="candidate-img">
                      <img src={`${imageURL}${userImage}`} alt="user" />
                    </div>
                    <div className="candidate-caption">
                      <h5>
                        {userData.first_name} {userData.last_name}
                      </h5>

                      <div className="meta-line">
                        <span>
                          <i className="ti ti-email"></i>{" "}
                          {userData.email_address}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12">
               
                  <PersonalProfile />
                  <Educationdetails />

                  <Projects />
                  <Employment />
                  <Accomplishments />
                  <Skills />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <AdminFooter />
    </div>
  );
};

export default CandidateProfile;
