import React, { useEffect, useState } from "react";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { subURL } from "../../utils/URL's";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
var ID, USERID;
const PersonalProfile = () => {
  const [profileModal, setProfileModal] = useState(false);
  const [showSection, setShowSection] = useState(true);
  const toggleSection = () => {
    setShowSection(!showSection);
  };

  const openProfileModal = () => {
    setCurrentIndustry("");
    setSelectedShift("");
    setGender("");
    setSalary("");
    setDob("");
    setSelectedLocations("");
    setSelectedWorkPermitLocations("");
    setIsDifferentlyAbled("");
    setIsCareerBreak("");
    setCareerBreakReason("");
    setPermanentAddress("");
    setHometown("");
    setPincode("");
    setLanguage("");
    setProfileModal(!profileModal);
  };

  const [currentIndustry, setCurrentIndustry] = useState("");
  const [selectedShift, setSelectedShift] = useState("flexible");
  const [gender, setGender] = useState("");
  const [salary, setSalary] = useState(0);
  const [dob, setDob] = useState("");

  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [selectedWorkPermitLocations, setSelectedWorkPermitLocations] =
    useState([]);
  const [workPermitLocationOptions, setWorkPermitLocationOptions] = useState(
    []
  );

  const [isDifferentlyAbled, setIsDifferentlyAbled] = useState(false);
  const [reason, setReason] = useState("");

  const [iscareerBreak, setIsCareerBreak] = useState(false);
  const [careerBreakReason, setCareerBreakReason] = useState("");

  const [permanentAddress, setPermanentAddress] = useState("");
  const [hometown, setHometown] = useState("");
  const [pincode, setPincode] = useState("");
  const [language, setLanguage] = useState("");

  const [personalInfoData, setPersonalInfoData] = useState([]);

  const [editPersonalInfoModal, setEditPersonalInfoModal] = useState(false);
  const openEditPersonalInfoModal = () =>
    setEditPersonalInfoModal(!editPersonalInfoModal);

  const [delId, setDelId] = useState("");
  const [deletePersonalInfoModal, setDeletePersonalInfoModal] = useState(false);
  const openDeletePersonalInfoModal = () =>
    setDeletePersonalInfoModal(!deletePersonalInfoModal);

  const [isEdit, setIsEdit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const [selectedWorkPermitValues, setSelectedWorkPermitValues] = useState([]);
  const [selectedLocationValues, setSelectedLocationValues] = useState([]);

  const [userId, setUserId] = useState("");

  const getUserId = async () => {
    try {
      USERID = await localStorage.getItem("userid");

      if (USERID !== null) {
        setUserId(USERID);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    axios
      .get(`${subURL}/userpersonalinfo/${userId}/`)
      .then((res) => {
        setPersonalInfoData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userId]);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) =>
        setLocationOptions(
          data.map((country) => ({
            value: country.name.common.toLowerCase(),
            label: country.name.common,
          }))
        )
      )
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) =>
        setWorkPermitLocationOptions(
          data.map((country) => ({
            value: country.name.common.toLowerCase(),
            label: country.name.common,
          }))
        )
      )
      .catch((error) => console.error(error));
  }, []);

  const handleLocationChange = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.label);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedLocations(selectedLabels);
    setSelectedLocationValues(selectedValues);
  };

  const handleWorkPermitChange = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.label);
    const selectedValues = selectedOptions.map((option) => option.value);
    setSelectedWorkPermitLocations(selectedLabels);
    setSelectedWorkPermitValues(selectedValues);
  };

  const handleDifferentlyAbledChange = (event) => {
    setIsDifferentlyAbled(event.target.value === "yes");
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleCareerBreakChange = (event) => {
    setIsCareerBreak(event.target.value === "yes");
  };

  const handleCareerBreakReasonChange = (event) => {
    setCareerBreakReason(event.target.value);
  };

  const handleShiftChange = (e) => {
    setSelectedShift(e.target.value);
  };
  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const validateDOB = (enteredDOB) => {
    const minimumAge = 18;

    const today = new Date();
    const enteredDate = new Date(enteredDOB);
    const age = today.getFullYear() - enteredDate.getFullYear();

    const monthDiff = today.getMonth() - enteredDate.getMonth();
    const hasPassedBirthday =
      monthDiff > 0 ||
      (monthDiff === 0 && today.getDate() >= enteredDate.getDate());

    if (age < minimumAge) {
      return "You must be at least 18 years old to apply for a job.";
    }

    return "";
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const dobValidationMessage = validateDOB(dob);

    if (dobValidationMessage) {
      alert(dobValidationMessage);
      return;
    }
    const pincode1 = pincode.toString();
    if (pincode1.length < 6) {
      alert("Pincode has to be 6 digits long");
      return;
    }

    async function storeData() {
      const formData = {
        user_account_id: userId,
        current_industry: currentIndustry,
        preferred_shift: selectedShift,

        preferred_work_locations: selectedLocationValues.join(","),

        expected_salary: salary,
        gender: gender,

        date_of_birth: dob,
        differently_abled: isDifferentlyAbled,
        career_break: iscareerBreak,
        reason_for_career_break: careerBreakReason,
        work_permit_for_any_countries: selectedWorkPermitValues.join(","),

        permanent_address: permanentAddress,

        hometown: hometown,
        pincode: pincode,
        language: language,
      };

      try {
        let headers = {
          "Content-Type": "application/json; charset=utf-8",
        };
        const res = await axios.post(`${subURL}/userpersonalinfo`, formData, {
          headers: headers,
        });

        if (res.status === 201) {
          setModalTitle("Success");
          setSuccessMessage("Data saved successfully!");
          setShowModal(true);
          setProfileModal(false);
          async function fetchData() {
            try {
              const res = await axios.get(
                `${subURL}/userpersonalinfo/${userId}/`
              );

              setPersonalInfoData(res.data);
            } catch (error) {
              console.log(error);
            } finally {
            }
          }
          fetchData();
        } else {
          console.log("error");
        }
      } catch (e) {
        if (e.response?.status === 400) {
          setModalTitle("Failed");
          setSuccessMessage("Profile already exists,you can update");
          setShowModal(true);
          setProfileModal(false);
        } else {
          setModalTitle("Failed");
          setSuccessMessage("Oops !Something went wrong");
          setShowModal(true);
          setProfileModal(false);
        }

        console.log(e);
      }
    }

    storeData();
  }

  function handleEdit(id) {
    ID = id;

    setIsEdit(true);
    setEditPersonalInfoModal(true);

    const filteredDummuyData = personalInfoData.find((data) => data.id === id);

    setSelectedLocationValues(filteredDummuyData.preferred_work_locations);
    setCurrentIndustry(filteredDummuyData.current_industry);
    setSelectedShift(filteredDummuyData.preferred_shift);
    setSelectedLocations(filteredDummuyData.preferred_work_locations);
    setSelectedWorkPermitValues(
      filteredDummuyData.work_permit_for_any_countries
    );
    setSalary(filteredDummuyData.expected_salary);
    setGender(filteredDummuyData.gender);
    setDob(filteredDummuyData.date_of_birth);
    setIsDifferentlyAbled(filteredDummuyData.differently_abled);

    setIsCareerBreak(filteredDummuyData.career_break);
    setCareerBreakReason(filteredDummuyData.reason_for_career_break);
    setSelectedWorkPermitLocations(
      filteredDummuyData.work_permit_for_any_countries
    );
    setPermanentAddress(filteredDummuyData.permanent_address);
    setHometown(filteredDummuyData.hometown);
    setPincode(filteredDummuyData.pincode);
    setLanguage(filteredDummuyData.language);
  }

  async function updateHandler() {
    const dobValidationMessage = validateDOB(dob);

    if (dobValidationMessage) {
      alert(dobValidationMessage);
      return; // Stop the form submission
    }

    const formData = {
      user_account_id: userId,
      current_industry: currentIndustry,
      preferred_shift: selectedShift,
      preferred_work_locations: Array.isArray(selectedLocationValues)
        ? selectedLocationValues.join(",")
        : "",

      expected_salary: salary,
      gender: gender,

      date_of_birth: dob,
      differently_abled: isDifferentlyAbled,
      career_break: iscareerBreak,
      reason_for_career_break: careerBreakReason,
      work_permit_for_any_countries: Array.isArray(selectedWorkPermitValues)
        ? selectedWorkPermitValues.join(",")
        : "",

      permanent_address: permanentAddress,

      hometown: hometown,
      pincode: pincode,
      language: language,
    };
    const pincode1 = pincode.toString();
    if (pincode1.length < 6) {
      alert("Pincode has to be 6 digits long");
      return;
    }
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };
      const res = await axios.put(
        `${subURL}/userpersonalinfo/${ID}/`,
        formData,
        {
          headers: headers,
        }
      );

      if (res.status === 200) {
        setModalTitle("Success");
        setSuccessMessage("Updated successfully!");
        setShowModal(true);
        setEditPersonalInfoModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/userpersonalinfo/${userId}/`
            );

            setPersonalInfoData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      } else {
        console.log("error");
      }
    } catch (e) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setEditPersonalInfoModal(false);
      console.log(e);
    }
  }

  async function deleteItem() {
    try {
      let headers = {
        "Content-Type": "application/json; charset=utf-8",
      };

      const resLogin = await axios.delete(
        `${subURL}/userpersonalinfo/${delId}/`,

        {
          headers: headers,
        }
      );
      if (resLogin.status === 204) {
        setModalTitle("Success");
        setSuccessMessage("Deleted Successfully!");
        setShowModal(true);
        setDeletePersonalInfoModal(false);
        async function fetchData() {
          try {
            const res = await axios.get(
              `${subURL}/userpersonalinfo/${userId}/`
            );

            setPersonalInfoData(res.data);
          } catch (error) {
            console.log(error);
          } finally {
          }
        }
        fetchData();
      }
    } catch (error) {
      setModalTitle("Failed");
      setSuccessMessage("Oops !Something went wrong");
      setShowModal(true);
      setDeletePersonalInfoModal(false);
      console.log(error);
    }
  }

  function handleDelete(id) {
    setDeletePersonalInfoModal(true);
    setDelId(id);
  }
  return (
    <div
      className="overview-box"
      style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)" }}
    >
      <div className="title d-flex align-items-center justify-content-between">
        <h5>More Personal Info</h5>
        <div className="button-container">
          {personalInfoData.length === 0 && (
            <button
              className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor"
              type="submit"
              onClick={openProfileModal}
              style={{ marginRight: "25px" }}
            >
              Add
            </button>
          )}
          <FontAwesomeIcon
            icon={showSection ? faEye : faEyeSlash}
            color="black"
            onClick={toggleSection}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {showSection && (
        <div className="desc">
          {personalInfoData.length > 0 ? (
            <Table bordered responsive>
              <thead>
                <tr>
                  <th style={{ fontWeight: "500" }}>#</th>
                  <th style={{ fontWeight: "500" }}>Current Industry</th>
                  <th style={{ fontWeight: "500" }}>Preferred Shift</th>
                  <th style={{ fontWeight: "500" }}>Gender</th>
                  <th style={{ fontWeight: "500" }}>Address</th>
                  <th style={{ fontWeight: "500" }}>Actions</th>
                </tr>
              </thead>

              <tbody style={{ color: "black" }}>
                {personalInfoData.map((personalInfoData, key) => (
                  <tr key={personalInfoData.id}>
                    <td>{key + 1}</td>
                    <td>{personalInfoData.current_industry}</td>
                    <td>{personalInfoData.preferred_shift}</td>
                    <td>{personalInfoData.gender}</td>
                    <td>{personalInfoData.permanent_address}</td>
                    <td
                      style={{
                        //display: "flex",
                        //justifyContent: "space-between",
                        padding: "2em",
                        width:'auto'
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="green"
                        onClick={() => handleEdit(personalInfoData.id)}
                        style={{ cursor: "pointer" }}
                      />

                      <FontAwesomeIcon
                        icon={faTrash}
                        color="red"
                        onClick={() => handleDelete(personalInfoData.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <ListGroupItem>
              <p> No details found.</p>
            </ListGroupItem>
          )}
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={profileModal} toggle={openProfileModal} centered>
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Add Personal details
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openProfileModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="title">Current Industry</Label>
                  <Input
                    type="text"
                    name="industry"
                    id="industry"
                    placeholder="Enter Current Industry"
                    value={currentIndustry}
                    onChange={(e) => setCurrentIndustry(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="preferredShift">Preferred Shift</Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="shift"
                          value="day"
                          checked={selectedShift === "day"}
                          onChange={handleShiftChange}
                        />
                        Day
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="shift"
                          value="night"
                          checked={selectedShift === "night"}
                          onChange={handleShiftChange}
                        />
                        Night
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="shift"
                          value="flexible"
                          checked={selectedShift === "flexible"}
                          onChange={handleShiftChange}
                        />
                        Flexible
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="preferredShift">Gender</Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="gender"
                          value="male"
                          checked={gender === "male"}
                          onChange={handleGenderChange}
                        />
                        Male
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={gender === "female"}
                          onChange={handleGenderChange}
                        />
                        Female
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>
              <div className="p-1">
                <FormGroup>
                  <Label for="salary">Expected Salary</Label>
                  <Input
                    type="number"
                    name="salary"
                    id="salary"
                    value={salary}
                    onChange={(e) => {
                      const enteredValue = parseInt(e.target.value, 10);
                      if (enteredValue >= 0) {
                        setSalary(enteredValue);
                      }
                    }}
                  ></Input>
                </FormGroup>
              </div>

              <div className="p-1">
                <FormGroup>
                  <Label for="workFrom">Date of Birth</Label>
                  <Input
                    type="date"
                    name="dob"
                    id="dob"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </FormGroup>
              </div>

              <div className="p-1">
                <FormGroup>
                  <Label for="preferredLocation">Preferred Work Location</Label>
                  <InputGroup>
                    <div style={{ position: "relative" }}>
                      <Select
                        id="preferredLocation"
                        value={locationOptions.filter((option) =>
                          selectedLocations.includes(option.label)
                        )}
                        onChange={handleLocationChange}
                        options={locationOptions}
                        isMulti={true}
                        placeholder="Enter preferred work locations..."
                      />
                    </div>
                  </InputGroup>
                </FormGroup>
              </div>

              <div className="p-1">
                <FormGroup>
                  <Label for="differentlyAbled">
                    Are you Differently Abled?
                  </Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="differentlyAbledYes"
                          name="differentlyAbled"
                          value="yes"
                          checked={isDifferentlyAbled}
                          onChange={handleDifferentlyAbledChange}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="differentlyAbledNo"
                          name="differentlyAbled"
                          value="no"
                          checked={!isDifferentlyAbled}
                          onChange={handleDifferentlyAbledChange}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>

              {isDifferentlyAbled && (
                <FormGroup>
                  <Label for="differentlyAbledReason">Reason</Label>

                  <Input
                    type="text"
                    id="differentlyAbledReason"
                    name="differentlyAbledReason"
                    value={reason}
                    onChange={handleReasonChange}
                    placeholder="Enter your reason for being differently abled"
                  />
                </FormGroup>
              )}

              <div className="p-1">
                <FormGroup>
                  <Label for="careerbreak">
                    Have you taken a Career Break?
                  </Label>
                  <div className="radio-group">
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="careerbreakYes"
                          name="careerbreak"
                          value="yes"
                          checked={iscareerBreak}
                          onChange={handleCareerBreakChange}
                        />
                        Yes
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          id="careerbreakNo"
                          name="careerbreak"
                          value="no"
                          checked={!iscareerBreak}
                          onChange={handleCareerBreakChange}
                        />
                        No
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
              </div>

              {iscareerBreak && (
                <FormGroup>
                  <Label for="careerbreakreason">Reason</Label>

                  <Input
                    type="text"
                    id="careerbreakreason"
                    name="careerbreakreason"
                    value={careerBreakReason}
                    onChange={handleCareerBreakReasonChange}
                    placeholder="Enter your reason "
                  />
                </FormGroup>
              )}

              <div className="p-1">
                <FormGroup>
                  <Label for="workpermit">
                    Work Permit for Other Countries
                  </Label>
                  <InputGroup>
                    <div style={{ position: "relative" }}>
                      <Select
                        id="workpermit"
                        value={workPermitLocationOptions?.filter((option) =>
                          selectedWorkPermitLocations.includes(option.label)
                        )}
                        onChange={handleWorkPermitChange}
                        options={workPermitLocationOptions}
                        isMulti={true}
                        placeholder="Enter preferred work locations..."
                      />
                    </div>
                  </InputGroup>
                  {/* {selectedLocations.length > 0 && (
                    <Label style={{ color: "black" }}>
                      Selected locations: {selectedWorkPermitValues}
                     
                    </Label>
                  )} */}
                </FormGroup>
              </div>

              <FormGroup>
                <Label for="permanentAddress">Permanent Address</Label>
                <Input
                  type="text"
                  id="permanentAddress"
                  value={permanentAddress}
                  onChange={(event) => setPermanentAddress(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="hometown">Hometown</Label>
                <Input
                  type="text"
                  id="hometown"
                  value={hometown}
                  onChange={(event) => setHometown(event.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="pincode">Pincode</Label>
                <Input
                  type="number"
                  id="pincode"
                  maxLength={6}
                  value={pincode}
                  onChange={(e) => {
                    const enteredValue = parseInt(e.target.value, 10);
                    if (enteredValue >= 0 && e.target.value.length <= 6) {
                      setPincode(enteredValue);
                    }
                  }}
                />
              </FormGroup>

              <FormGroup>
                <Label for="language">Language</Label>
                <Input
                  type="text"
                  id="language"
                  value={language}
                  onChange={(event) => setLanguage(event.target.value)}
                />
              </FormGroup>

              <div className="p-1">
                <button
                  className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-100"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>

      {isEdit && (
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal
              isOpen={editPersonalInfoModal}
              toggle={openEditPersonalInfoModal}
              centered
            >
              <ModalBody className="modal-body">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Update Profile
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openEditPersonalInfoModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="title">Current Industry</Label>
                    <Input
                      type="text"
                      name="industry"
                      id="industry"
                      placeholder="Enter Current Industry"
                      value={currentIndustry}
                      onChange={(e) => setCurrentIndustry(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="preferredShift">Preferred Shift</Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="shift"
                            value="day"
                            checked={selectedShift === "day"}
                            onChange={handleShiftChange}
                          />
                          Day
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="shift"
                            value="night"
                            checked={selectedShift === "night"}
                            onChange={handleShiftChange}
                          />
                          Night
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="shift"
                            value="flexible"
                            checked={selectedShift === "flexible"}
                            onChange={handleShiftChange}
                          />
                          Flexible
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="preferredShift">Gender</Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="gender"
                            value="male"
                            checked={gender === "male"}
                            onChange={handleGenderChange}
                          />
                          Male
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            name="gender"
                            value="female"
                            checked={gender === "female"}
                            onChange={handleGenderChange}
                          />
                          Female
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>
                <div className="p-1">
                  <FormGroup>
                    <Label for="salary">Expected Salary</Label>
                    <Input
                      type="number"
                      name="salary"
                      id="salary"
                      value={salary}
                      onChange={(e) => {
                        const enteredValue = parseInt(e.target.value, 10);
                        if (enteredValue >= 0) {
                          setSalary(enteredValue);
                        }
                      }}
                    ></Input>
                  </FormGroup>
                </div>

                <div className="p-1">
                  <FormGroup>
                    <Label for="workFrom">Date of Birth</Label>
                    <Input
                      type="date"
                      name="dob"
                      id="dob"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </FormGroup>
                </div>

                <div className="p-1">
                  <FormGroup>
                    <Label for="preferredLocation">
                      Preferred Work Location
                    </Label>
                    <InputGroup>
                      <div style={{ position: "relative" }}>
                        <Select
                          id="preferredLocation"
                          value={locationOptions.filter((option) =>
                            selectedLocations.includes(option.label)
                          )}
                          onChange={handleLocationChange}
                          options={locationOptions}
                          isMulti={true}
                          placeholder="Enter preferred work locations..."
                        />
                      </div>
                    </InputGroup>
                    {selectedLocations.length > 0 && (
                      <Label style={{ color: "black" }}>
                        Selected locations: {selectedLocationValues}
                      </Label>
                    )}
                  </FormGroup>
                </div>

                <div className="p-1">
                  <FormGroup>
                    <Label for="differentlyAbled">
                      Are you Differently Abled?
                    </Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="differentlyAbledYes"
                            name="differentlyAbled"
                            value="yes"
                            checked={isDifferentlyAbled}
                            onChange={handleDifferentlyAbledChange}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="differentlyAbledNo"
                            name="differentlyAbled"
                            value="no"
                            checked={!isDifferentlyAbled}
                            onChange={handleDifferentlyAbledChange}
                          />
                          No
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>

                {isDifferentlyAbled && (
                  <FormGroup>
                    <Label for="differentlyAbledReason">Reason</Label>

                    <Input
                      type="text"
                      id="differentlyAbledReason"
                      name="differentlyAbledReason"
                      value={reason}
                      onChange={handleReasonChange}
                      placeholder="Enter your reason for being differently abled"
                    />
                  </FormGroup>
                )}

                <div className="p-1">
                  <FormGroup>
                    <Label for="careerbreak">
                      Have you taken a Career Break?
                    </Label>
                    <div className="radio-group">
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="careerbreakYes"
                            name="careerbreak"
                            value="yes"
                            checked={iscareerBreak}
                            onChange={handleCareerBreakChange}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                      <FormGroup check inline>
                        <Label check>
                          <Input
                            type="radio"
                            id="careerbreakNo"
                            name="careerbreak"
                            value="no"
                            checked={!iscareerBreak}
                            onChange={handleCareerBreakChange}
                          />
                          No
                        </Label>
                      </FormGroup>
                    </div>
                  </FormGroup>
                </div>

                {iscareerBreak && (
                  <FormGroup>
                    <Label for="careerbreakreason">Reason</Label>

                    <Input
                      type="text"
                      id="careerbreakreason"
                      name="careerbreakreason"
                      value={careerBreakReason}
                      onChange={handleCareerBreakReasonChange}
                      placeholder="Enter your reason "
                    />
                  </FormGroup>
                )}

                <div className="p-1">
                  <FormGroup>
                    <Label for="workpermitedit">
                      Work Permit for Other Countries
                    </Label>
                    <InputGroup>
                      <div style={{ position: "relative" }}>
                        <Select
                          id="workpermitedit"
                          //  value={selectedWorkPermit}
                          value={workPermitLocationOptions?.filter((option) =>
                            selectedWorkPermitLocations.includes(option.label)
                          )}
                          onChange={handleWorkPermitChange}
                          options={workPermitLocationOptions}
                          isMulti={true}
                          placeholder="Enter preferred work locations..."
                        />
                      </div>
                    </InputGroup>
                    {selectedLocations.length > 0 && (
                      <Label style={{ color: "black" }}>
                        Selected locations: {selectedWorkPermitValues}
                      </Label>
                    )}
                  </FormGroup>
                </div>

                <FormGroup>
                  <Label for="permanentAddress">Permanent Address</Label>
                  <Input
                    type="text"
                    id="permanentAddress"
                    value={permanentAddress}
                    onChange={(event) =>
                      setPermanentAddress(event.target.value)
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="hometown">Hometown</Label>
                  <Input
                    type="text"
                    id="hometown"
                    value={hometown}
                    onChange={(event) => setHometown(event.target.value)}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="pincode">Pincode</Label>
                  <Input
                    type="number"
                    id="pincode"
                    maxLength={6}
                    value={pincode}
                    onChange={(e) => {
                      const enteredValue = parseInt(e.target.value, 10);
                      if (enteredValue >= 0 && e.target.value.length <= 6) {
                        setPincode(enteredValue);
                      }
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="language">Language</Label>
                  <Input
                    type="text"
                    id="language"
                    value={language}
                    onChange={(event) => setLanguage(event.target.value)}
                  />
                </FormGroup>
                <div className="p-3 d-flex justify-content-between">
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-red w-40"
                    type="submit"
                    onClick={(e) => setEditPersonalInfoModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor w-42 "
                    type="button"
                    onClick={updateHandler}
                  >
                    Update
                  </button>
                </div>
              </ModalBody>
            </Modal>
          </div>
        </div>
      )}

      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal
            isOpen={deletePersonalInfoModal}
            toggle={openDeletePersonalInfoModal}
            centered
          >
            <ModalBody className="modal-body">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Are you sure you want to delete ?
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openDeletePersonalInfoModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => setDeletePersonalInfoModal(false)}
              >
                Cancel
              </Button>
              <Button color="primary" onClick={deleteItem}>
                OK
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>Success</ModalHeader>
        <ModalBody>{successMessage}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PersonalProfile;
